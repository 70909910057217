import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';

import { CelumAvatarModule } from '@celum/common-components';
import { CelumAvatarTestingAttributesDirective } from '@celum/work/app/shared/directives/celum-avatar-testing-attributes.directive';

import { WorkroomAvatarAdditionalAvatarClassPipe } from './pipes/additional-avatar-class.pipe';
import { WorkroomAvatarConfiguration } from './workroom-avatar-configuration';

@Component({
  selector: 'workroom-avatar',
  templateUrl: './workroom-avatar.component.html',
  styleUrls: ['./workroom-avatar.component.less'],
  imports: [
    CommonModule,
    CelumAvatarModule,
    WorkroomAvatarAdditionalAvatarClassPipe,
    CelumAvatarTestingAttributesDirective
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorkroomAvatar {
  @HostBinding('class.workroom-avatar') public hostCls = true;

  @Output() public readonly avatarClicked = new EventEmitter<Event>();

  @Input() public configuration: WorkroomAvatarConfiguration;

  @HostBinding('class') public get hostClsSizeState(): string {
    return `workroom-avatar--${this.configuration?.size}`;
  }

  @HostListener('click', ['$event'])
  public onHostClick(): void {
    this.avatarClicked.emit();
  }
}
