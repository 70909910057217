import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { QuotaType } from './contact-dialog.component';

@Pipe({
  name: 'contactDialogTranslationsPipe',
  standalone: false
})
export class ContactDialogTranslationsPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  public transform(quotaTypes: QuotaType[], ownerName: string): { headline: string; text: string } {
    if (quotaTypes.includes(QuotaType.STORAGE) && quotaTypes.includes(QuotaType.X3D_CONVERSION)) {
      return this.getStorageTranslations('STORAGE.MEMBER_DIALOG.SOURCE.STORAGE_AND_X3D_CONVERSIONS', ownerName);
    }

    // singular values are passed otherwise
    switch (quotaTypes[0]) {
      case QuotaType.STORAGE:
      case QuotaType.X3D_CONVERSION:
        return this.getStorageTranslations(`STORAGE.MEMBER_DIALOG.SOURCE.${quotaTypes[0]}`, ownerName);
      case QuotaType.WORKROOMS:
        return {
          headline: this.translateService.instant('TEMPLATE_CHOOSER.LICENSE_LIMIT.CONTACT.FORM.HEADLINE'),
          text: this.translateService.instant('TEMPLATE_CHOOSER.LICENSE_LIMIT.CONTACT.FORM.TEXT', { ownerName })
        };
      default:
        console.warn('[QuotaType] does not exist');
    }
  }

  private getStorageTranslations(storageKey: string, ownerName: string): { headline: string; text: string } {
    return {
      headline: this.translateService.instant('STORAGE.MEMBER_DIALOG.HEADLINE', {
        source: this.translateService.instant(storageKey)
      }),
      text: this.translateService.instant('STORAGE.MEMBER_DIALOG.TEXT', {
        ownerName,
        source: this.translateService.instant(storageKey)
      })
    };
  }
}
