import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { concat, forkJoin, of } from 'rxjs';
import { catchError, concatMap, map, mergeMap, switchMap } from 'rxjs/operators';

import { selectFileIdByFileVersionId } from '@celum/work/app/core/model/entities/file-version/file-version.selectors';
import { Marker, MarkerSubType, MarkerType } from '@celum/work/app/core/model/entities/marker/marker.model';
import { selectMarkerById, selectMarkersOfComment } from '@celum/work/app/core/model/entities/marker/marker.selectors';
import { selectCurrentWorkroomId } from '@celum/work/app/pages/workroom/store/workroom-wrapper.selectors';

import {
  MarkerCreateStamp,
  MarkerCreateStampFailed,
  MarkerCreateStampSucceeded,
  MarkerDeleteMarker,
  MarkerDeleteMarkerFailed,
  MarkerDeleteMarkerSucceeded,
  MarkerLoadAllMarkers,
  MarkerLoadMarkers,
  MarkerLoadMarkersFailed,
  MarkerLoadMarkersSucceeded,
  MarkerUpdateStamp,
  MarkerUpdateStampFailed,
  MarkerUpdateStampSucceeded
} from './marker.actions';
import { MarkerService } from './marker.service';
import { MarkerDeleteMany, MarkerDeleteOne, MarkerUpsertOne } from '../../model/entities/marker/marker.actions';
import { CommentDeleteCommentSucceeded } from '../comment/comment.actions';

@Injectable()
export class MarkerEffects {
  public loadMarkersByType = createEffect(() =>
    this.actions$.pipe(
      ofType(MarkerLoadMarkers),
      concatLatestFrom(action => [
        this.store.select(selectCurrentWorkroomId),
        this.store.select(selectFileIdByFileVersionId(action.fileVersionId))
      ]),
      switchMap(([action, workroomId, fileId]) =>
        this.markerService.getAllMarkersByType(workroomId, action.fileVersionId, fileId, action.markerType).pipe(
          map(markers => MarkerLoadMarkersSucceeded({ fileVersionId: action.fileVersionId, markers: markers })),
          catchError(() => of(MarkerLoadMarkersFailed({ fileVersionId: action.fileVersionId })))
        )
      )
    )
  );

  public loadAllMarkers = createEffect(() =>
    this.actions$.pipe(
      ofType(MarkerLoadAllMarkers),
      concatLatestFrom(action => [
        this.store.select(selectCurrentWorkroomId),
        this.store.select(selectFileIdByFileVersionId(action.fileVersionId))
      ]),
      switchMap(([action, workroomId, fileId]) =>
        forkJoin([
          this.markerService.getAllMarkersByType(workroomId, action.fileVersionId, fileId, MarkerSubType.COMMENT),
          this.markerService.getAllMarkersByType(workroomId, action.fileVersionId, fileId, MarkerSubType.STAMP)
        ]).pipe(
          map(([commentMarkers, stampMarkers]) =>
            MarkerLoadMarkersSucceeded({
              fileVersionId: action.fileVersionId,
              markers: [...commentMarkers, ...stampMarkers]
            })
          ),
          catchError(() => of(MarkerLoadMarkersFailed({ fileVersionId: action.fileVersionId })))
        )
      )
    )
  );

  public createStamp = createEffect(() =>
    this.actions$.pipe(
      ofType(MarkerCreateStamp),
      concatLatestFrom(() => this.store.select(selectCurrentWorkroomId)),
      concatMap(([action, workroomId]) =>
        concat(
          of(MarkerUpsertOne({ marker: action.tmpStamp })),
          this.markerService.createStamp(workroomId, action.fileId, action.fileVersionId, action.tmpStamp).pipe(
            map(stamp =>
              MarkerCreateStampSucceeded({
                fileVersionId: action.fileVersionId,
                tmpStamp: action.tmpStamp,
                stamp: stamp
              })
            ),
            catchError(() =>
              of(MarkerCreateStampFailed({ fileVersionId: action.fileVersionId, tmpStamp: action.tmpStamp }))
            )
          )
        )
      )
    )
  );

  public updateStamp = createEffect(() =>
    this.actions$.pipe(
      ofType(MarkerUpdateStamp),
      concatLatestFrom(action => [
        this.store.select(selectMarkerById(action.stamp.id)),
        this.store.select(selectFileIdByFileVersionId(action.fileVersionId))
      ]),
      concatMap(([action, oldStamp, fileId]) =>
        concat(
          of(MarkerUpsertOne({ marker: action.stamp })),
          this.markerService.updateStamp(action.stamp.serverId, action.stamp, action.fileVersionId, fileId).pipe(
            map(stamp =>
              MarkerUpdateStampSucceeded({ oldStamp, stamp: { ...stamp, entityType: MarkerType.instance() } })
            ),
            catchError(() => of(MarkerUpdateStampFailed({ oldStamp })))
          )
        )
      )
    )
  );

  public deleteStamp = createEffect(() =>
    this.actions$.pipe(
      ofType(MarkerDeleteMarker),
      concatLatestFrom(action => this.store.select(selectFileIdByFileVersionId(action.fileVersionId))),
      concatMap(([action, fileId]) =>
        concat(
          of(MarkerDeleteOne({ id: action.marker.id })),
          this.markerService.deleteStamp(action.marker.serverId, action.fileVersionId, fileId).pipe(
            map(() => MarkerDeleteMarkerSucceeded({ marker: action.marker })),
            catchError(() => of(MarkerDeleteMarkerFailed({ marker: action.marker })))
          )
        )
      )
    )
  );

  public deleteCommentMarkers = createEffect(() =>
    this.actions$.pipe(
      ofType(CommentDeleteCommentSucceeded),
      mergeMap(action =>
        this.store
          .select(selectMarkersOfComment(action.comment))
          .pipe(switchMap((markers: Marker[]) => of(MarkerDeleteMany({ ids: markers.map(marker => marker.id) }))))
      )
    )
  );

  public replaceStampOnCreateSucceeded = createEffect(() =>
    this.actions$.pipe(
      ofType(MarkerCreateStampSucceeded),
      concatMap(action =>
        concat(
          of(MarkerDeleteOne({ id: action.tmpStamp.id })),
          of(MarkerUpsertOne({ marker: { ...action.stamp, entityType: MarkerType.instance() } }))
        )
      )
    )
  );

  public rollbackCreateStampOnFailure = createEffect(() =>
    this.actions$.pipe(
      ofType(MarkerCreateStampFailed),
      map(action => MarkerDeleteOne({ id: action.tmpStamp.id }))
    )
  );

  public rollbackUpdateStampOnFailure = createEffect(() =>
    this.actions$.pipe(
      ofType(MarkerUpdateStampFailed),
      map(action => MarkerUpsertOne({ marker: action.oldStamp }))
    )
  );

  public rollbackDeleteStampOnFailure = createEffect(() =>
    this.actions$.pipe(
      ofType(MarkerDeleteMarkerFailed),
      map(action => MarkerUpsertOne({ marker: action.marker }))
    )
  );

  constructor(
    private store: Store,
    private actions$: Actions,
    private markerService: MarkerService
  ) {}
}
