import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { provideComponentStore } from '@ngrx/component-store';
import { Store } from '@ngrx/store';
import { combineLatest, map, Observable } from 'rxjs';

import { CelumEmptyPageModule, CelumIconModule, ColorConstants, IconConfiguration } from '@celum/common-components';
import { CustomForm } from '@celum/work/app/core/model/entities/custom-form/custom-form.model';
import { selectCustomFormEntities } from '@celum/work/app/core/model/entities/custom-form/custom-form.selectors';
import { AddTaskLinkIconTooltipPipe } from '@celum/work/app/pages/workroom/pages/tasks/pages/task-detail/components/task-link/components/task-link-input/pipes/add-task-link-icon-tooltip.pipe';
import { AddTaskLinkIconPipe } from '@celum/work/app/pages/workroom/pages/tasks/pages/task-detail/components/task-link/components/task-link-input/pipes/add-task-link-icon.pipe';
import { SharedModule } from '@celum/work/app/shared';

import { CustomFormsVirtualScrollTableComponent } from './components/custom-forms-virtual-scroll-table/custom-forms-virtual-scroll-table.component';
import { CustomFormsStore } from './forms-overview-tab.store';

@Component({
  selector: 'forms-overview-tab',
  imports: [
    CommonModule,
    CelumEmptyPageModule,
    AddTaskLinkIconPipe,
    AddTaskLinkIconTooltipPipe,
    CelumIconModule,
    SharedModule,
    CustomFormsVirtualScrollTableComponent
  ],
  templateUrl: './forms-overview-tab.component.html',
  styleUrls: ['./forms-overview-tab.component.less'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [provideComponentStore(CustomFormsStore)]
})
export class FormsOverviewTabComponent implements OnInit {
  @HostBinding('class') public componentCls = 'forms-overview-tab';

  @Input() public hasWorkroomsFinishPermission: boolean;

  public readonly formCreateIcon = IconConfiguration.small('form-create')
    .withIconSize(32)
    .withColor(ColorConstants.BLUE_GRAY_600)
    .withHoverColor(ColorConstants.BLUE_GRAY_700);
  public readonly formCreateDisabledIcon = IconConfiguration.small('form-strikethrough')
    .withColor(ColorConstants.BLUE_GRAY_500)
    .withIconSize(32);

  public readonly loading$: Observable<boolean> = this.customFormsStore.loading$;
  public readonly hasCustomForms$: Observable<boolean> = this.customFormsStore.hasCustomForms$;
  public readonly hasCustomFields$: Observable<boolean> = this.customFormsStore.hasCustomFields$;
  public customForms$: Observable<CustomForm[]>;

  constructor(
    public customFormsStore: CustomFormsStore,
    private store: Store<any>
  ) {}

  public ngOnInit(): void {
    this.customForms$ = combineLatest([
      this.store.select(selectCustomFormEntities),
      this.customFormsStore.customFormIds$
    ]).pipe(map(([customForms, customFormIds]) => customFormIds.map(id => customForms[id])));
  }

  public openDialogToAddForm() {
    this.customFormsStore.openCreateCustomFormDialog$();
  }

  public displayCreationLimitSnackbar(): void {
    this.customFormsStore.displayLimitationSnackbar$();
  }
}
