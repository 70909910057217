import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { isEmpty } from 'lodash';

import { Collection } from '../../model/content-hub.model';

@Component({
  selector: 'collection-selection',
  templateUrl: './collection-selection.component.html',
  styleUrls: ['./collection-selection.component.less'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class CollectionSelectionComponent implements OnInit, OnChanges {
  @HostBinding('class.collection-selection') public hostCls = true;

  @Input() public collections: Collection[];

  @Output() public readonly collectionSelected = new EventEmitter<Collection>();

  public collectionForm: UntypedFormGroup;
  public collectionsFormControlName = 'collections';

  constructor(private fb: UntypedFormBuilder) {}

  public ngOnInit(): void {
    this.collectionForm = this.fb.group({
      collections: new UntypedFormControl(null),
      name: new UntypedFormControl(name)
    });
  }

  public ngOnChanges({ collections }: SimpleChanges): void {
    if (isEmpty(collections?.previousValue) && !isEmpty(collections?.currentValue)) {
      this.collectionForm.get(this.collectionsFormControlName).setValue(this.collections[0]?.id);
    }
  }

  public trackByFn(index, item: Collection) {
    return item.id || index;
  }

  public changeCollection(collection: Collection): void {
    this.collectionSelected.emit(collection);
  }
}
