import {
  ChangeDetectionStrategy,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { isEqual } from 'lodash';

import { CelumDialog } from '@celum/common-components';
import { Entity } from '@celum/work/app/core/model';

import { RobotDialogConfiguration } from './robot-dialog-config';
import { BaseRobot } from '../robot-dialog-templates/base-robot/base-robot.component';

@Component({
  selector: 'robot-dialog',
  templateUrl: './robot-dialog.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class RobotDialogComponent<T extends Entity<any, any>> implements CelumDialog<RobotDialogConfiguration<T>> {
  @ViewChild('anchor', {
    static: true,
    read: ViewContainerRef
  })
  public anchor: ViewContainerRef;

  public color: string;
  public configuration: RobotDialogConfiguration<T>;
  public title: string;
  public component: ComponentRef<BaseRobot<T>>;

  constructor(
    private dialogRef: MatDialogRef<RobotDialogConfiguration<T>>,
    private resolver: ComponentFactoryResolver
  ) {}

  public get confirmationText(): string {
    return this.configuration.args.robot ? 'COMMON.SAVE' : 'COMMON.CREATE';
  }

  public get valid(): boolean {
    return (
      !isEqual(this.component.instance.getResult(), this.configuration.args.robot) && this.component.instance.isValid()
    );
  }

  public configure(configuration: RobotDialogConfiguration<T>): void {
    this.configuration = configuration;
    this.color = this.configuration.color;
    this.title = this.configuration.args.robot ? 'ROBOTS.DIALOG_EDIT' : 'ROBOTS.DIALOG_CREATE';

    const factory = this.resolver.resolveComponentFactory(this.configuration.args.component);
    this.component = this.anchor.createComponent(factory, 0, configuration.args.parentInjector);
    this.component.instance.configure(this.configuration);
  }

  public saveDialog() {
    const result = this.component.instance.getResult();
    this.dialogRef.close(result);
  }

  public closeDialog() {
    this.dialogRef.close();
  }
}
