import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';

import { Teamspace } from '@celum/work/app/core/model/entities/teamspace/teamspace.model';
import { VirtualScrollTableService } from '@celum/work/app/shared/components/virtual-scroll-table/virtual-scroll-table.base';
import { VirtualScrollTableComponent } from '@celum/work/app/shared/components/virtual-scroll-table/virtual-scroll-table.component';

import { WorkroomsVirtualScrollTableService } from './workooms-virtual-scroll-table.service';
import { WorkroomsManagementService } from '../workrooms-management.service';
import { WorkroomTableRowComponent } from './workroom-table-row/workroom-table-row.component';

@Component({
  selector: 'workrooms-virtual-scroll-table',
  imports: [CommonModule, VirtualScrollTableComponent, WorkroomTableRowComponent],
  templateUrl: './workrooms-virtual-scroll-table.component.html',
  styleUrls: ['./workrooms-virtual-scroll-table.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  viewProviders: [
    {
      provide: VirtualScrollTableService,
      useFactory: (store: Store<any>, wrManagementService: WorkroomsManagementService) =>
        new WorkroomsVirtualScrollTableService(store, wrManagementService),
      deps: [Store, WorkroomsManagementService]
    }
  ]
})
export class WorkroomsVirtualScrollTableComponent {
  @HostBinding('class.workrooms-virtual-scroll-table') public hostCls = true;

  @Input() public teamspace: Teamspace;
}
