import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnChanges, Output } from '@angular/core';

import { ColorConstants, IconConfiguration } from '@celum/common-components';

export type MessageBoxConfigType = {
  type: 'info' | 'warn' | 'error' | 'success';
  headline?: string;
  text?: string;
};

@Component({
  selector: 'message-box',
  templateUrl: './message-box.html',
  styleUrls: ['./message-box.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class MessageBox implements OnChanges {
  @Input() public type: 'info' | 'warn' | 'error' | 'success' = 'info';
  @Input() public headline: string;
  @Input() public text: string;
  @Input() public actionText: string;

  // todo Angular 9 Ivy -> remove this and the hostClasses getter and move the @HostBinding('class') to the @Input() type.
  // With the ViewEngine - the class hostBinding is destructive and overwrites all classes set by other entities (e.g. place where it is used)
  @Input() public class = '';

  @Output() public readonly invokeAction: EventEmitter<void> = new EventEmitter<void>();

  public internalIcon: IconConfiguration;

  @HostBinding('class')
  public get hostClasses(): string {
    return `${this.class} ${this.type}`;
  }

  public ngOnChanges(): void {
    this.internalIcon = this.evaluateIcon();
  }

  public evaluateIcon(): IconConfiguration {
    const color =
      this.type === 'warn'
        ? ColorConstants.WARNING
        : this.type === 'error'
          ? ColorConstants.ERROR
          : this.type === 'success'
            ? ColorConstants.SUCCESS
            : ColorConstants.PRIMARY;

    const icon =
      this.type === 'warn'
        ? 'attention-m'
        : this.type === 'error'
          ? 'error-m'
          : this.type === 'success'
            ? 'check-circle-m'
            : 'info-icon';

    return IconConfiguration.medium(icon).withColor(color);
  }
}
