import { OverlayRef } from '@angular/cdk/overlay';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { IconConfiguration } from '@celum/common-components';
import { ReactiveComponent } from '@celum/ng2base';
import { Entity } from '@celum/work/app/core/model';
import { Person } from '@celum/work/app/core/model/entities/person';
import { WorkroomConfiguration } from '@celum/work/app/core/model/entities/workroom';
import { RobotChipConfigFactory } from '@celum/work/app/robots/components/robot-chip/robot-chip-config.factory';
import { isTaskListOwnerRelatedAutomator } from '@celum/work/app/robots/services/robots-util';
import { ApplicationEventBus } from '@celum/work/app/shared/util/application-event-bus.service';
import { WorkroomWizardEvent } from '@celum/work/app/workroom-wizard/model/workroom-wizard-event';

import { AutomatorType, isAutomator, Robots } from '../../../core/model/entities/workroom/robot.model';
import { RobotChipConfig, RobotEditEvent, RobotsFactory } from '../../services/robots-factory';
import { RobotDialogConfiguration } from '../robot-dialog/robot-dialog-config';
import { BaseRobot } from '../robot-dialog-templates/base-robot/base-robot.component';

@Component({
  selector: 'robot-chip',
  templateUrl: './robot-chip.component.html',
  styleUrls: ['./robot-chip.component.less'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class RobotChipComponent<T extends Entity<any, any>> extends ReactiveComponent implements OnInit, OnChanges {
  @Input() public templatePeople: Person[];
  @Input() public robot: Robots;
  @Input() public sourceEntity: T;
  @Input() public deletable = true;
  @Input() public editable: boolean;
  @Input() public workroomConfig: WorkroomConfiguration;
  @Input() public repositoryId: string;
  @Input() public teamspaceId?: number;
  @Input() public workroomId?: number;

  @Output() public readonly editRobot: EventEmitter<RobotEditEvent> = new EventEmitter<RobotEditEvent>();
  @Output() public readonly deleteRobot: EventEmitter<void> = new EventEmitter<void>();

  public chipConfig$: Observable<RobotChipConfig>;
  public row2$: Observable<SafeHtml>;

  public cancelIcon: IconConfiguration = IconConfiguration.small('cancel-s');
  public warningIcon: IconConfiguration = IconConfiguration.small('wr2-warning');
  public editEvent: RobotEditEvent;
  public overlay: OverlayRef;

  constructor(
    public injector: Injector,
    private robotFactory: RobotsFactory,
    private eventBus: ApplicationEventBus,
    private cdRef: ChangeDetectorRef,
    private robotChipConfigFactory: RobotChipConfigFactory,
    private sanitizer: DomSanitizer
  ) {
    super();
  }

  public get notApplied$(): Observable<boolean> {
    return this.robotFactory.isRobotNotApplied(
      this.robot.type,
      this.sourceEntity,
      this.robot,
      false,
      this.templatePeople,
      this.teamspaceId,
      this.workroomId
    );
  }

  public ngOnInit(): void {
    if (isTaskListOwnerRelatedAutomator(this.robot)) {
      this.markForCheckOnEventBusEvents([WorkroomWizardEvent.TLO_ADDED, WorkroomWizardEvent.TLO_REMOVED]);
    }

    if (this.robot.type === AutomatorType.TASK_DUE_DATE) {
      this.markForCheckOnEventBusEvents([WorkroomWizardEvent.DUE_DATE_CHANGED]);
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.robot?.currentValue) {
      this.chipConfig$ = this.robotChipConfigFactory
        .getRobotChipConfig(this.robot, this.sourceEntity)
        .pipe(tap(config => (this.editEvent = config.editEvent)));

      this.row2$ = this.chipConfig$.pipe(map(chipConfig => this.sanitizer.bypassSecurityTrustHtml(chipConfig.row2)));
    }
  }

  public onConfigureTooltipComponent(baseRobot: BaseRobot<T>) {
    baseRobot.configure(
      new RobotDialogConfiguration<T>({
        robot: this.robot,
        sourceEntity: this.sourceEntity,
        readonly: true,
        workroomConfig: this.workroomConfig,
        templatePeople: this.templatePeople,
        teamspaceId: this.teamspaceId,
        workroomId: this.workroomId,
        repositoryId: this.repositoryId,
        robotSubType: isAutomator(this.robot) ? this.robot.subType : null
      })
    );
  }

  public onChipClicked(): void {
    if (this.editable) {
      this.editRobot.emit(this.editEvent);
    }
  }

  private markForCheckOnEventBusEvents(events: WorkroomWizardEvent[]): void {
    this.eventBus.observeEvents(this.unsubscribe$, ...events).subscribe(() => this.cdRef.markForCheck());
  }
}
