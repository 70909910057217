import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { combineLatest, map, mergeMap, Observable } from 'rxjs';

import { AVATAR_SIZE, ColorConstants, IconConfiguration } from '@celum/common-components';
import { selectContributorByUserIdAndWorkroomId } from '@celum/work/app/core/model/entities/contributor';
import { WorkroomAvatarConfiguration } from '@celum/work/app/shared/components/workroom-avatar/workroom-avatar-configuration';

import { mapToPersonWithRoles, Person } from '../../../core/model/entities/person';
import { selectCurrentWorkroomId } from '../../../pages/workroom/store/workroom-wrapper.selectors';
import { AvatarUtil } from '../../util/avatar-util';

@Component({
  selector: 'person-dialog-view',
  templateUrl: './person-dialog-view.component.html',
  styleUrls: ['./person-dialog-view.component.less'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class PersonDialogView implements AfterViewInit, OnInit {
  @HostBinding('class') public personDialog = 'person-dialog';

  @Input() public color: string;
  @Input() public persons: Person[] = [];
  @Input() public dialogTitle: string;
  @Input() public additionalInformation: string;
  @Input() public additionalHtmlInformation: string;
  @Input() public additionalParam: object = {};
  @Input() public confirmationButtonText: string;
  @Input() public disableOnConfirmClosing = false;
  @Input() public loading = false;
  @Input() public keepFileInformation: string;
  @Input() public hasDriveSubscription: boolean;

  @Output() public readonly goodByeClicked: EventEmitter<boolean> = new EventEmitter();

  public disableRipple = true;
  public infoIcon: IconConfiguration = IconConfiguration.large('info-icon', '').withColor(ColorConstants.PRIMARY);

  public keepContent = false;

  constructor(
    private sanitizer: DomSanitizer,
    private changeDetectorRef: ChangeDetectorRef,
    private avatarUtil: AvatarUtil,
    private store: Store<any>
  ) {}

  public ngOnInit() {
    // this is a ugly workaround to make the button text visible immediately -> not sure why this is actually necessary?!
    setTimeout(() => {
      this.changeDetectorRef.markForCheck();
    }, 100);
  }

  public ngAfterViewInit() {
    setTimeout(() => {
      // dirty fix to avoid "ripple circle" around checkbox on dialog opening. not sure why this happens.
      // => happens because of the autofocus created my the cdk focus trap of the dialog
      this.disableRipple = false;
      this.changeDetectorRef.markForCheck();
    }, 500);
  }

  public getAvatarConfigs(): Observable<WorkroomAvatarConfiguration[]> {
    return combineLatest(
      this.persons.map(person =>
        this.avatarUtil
          .getAvatarConfigWithImageForCurrentTeamspace({
            person,
            size: this.persons.length > 1 ? AVATAR_SIZE.l : AVATAR_SIZE.xxxl
          })
          .pipe(
            concatLatestFrom(() => this.store.select(selectCurrentWorkroomId)),
            mergeMap(([avatar, workroomId]) =>
              mapToPersonWithRoles([
                person,
                this.store.select(selectContributorByUserIdAndWorkroomId(person.id, workroomId))
              ]).pipe(map(personWithRoles => this.avatarUtil.handleRoleBadge(avatar, personWithRoles.roles)))
            )
          )
      )
    );
  }

  public okClicked() {
    this.goodByeClicked.emit(this.keepContent);
  }

  public sanitizeHtml(text: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(text);
  }
}
