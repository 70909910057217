import { Pipe, PipeTransform } from '@angular/core';

import { ProgressTask } from '@celum/work/app/progress-task/store/progress-task.model';

import { TranslationUtilService } from '../../core/translations/translation-util.service';

@Pipe({
  name: 'progressTaskError',
  standalone: false
})
export class ProgressTaskErrorPipe implements PipeTransform {
  private readonly errorKeyTranslationPrefix: string = 'PROGRESS_TASK.ERROR';
  private readonly commonErrorKey: string = 'COMMON';

  constructor(private translationUtilService: TranslationUtilService) {}

  public transform(progressTask: ProgressTask<any, any>): string | null {
    const progressTaskTypeErrorKey = `${this.errorKeyTranslationPrefix}.${
      progressTask.type
    }.${progressTask.errorKey?.toUpperCase()}`;
    const commonProgressTaskErrorKey = `${this.errorKeyTranslationPrefix}.${
      this.commonErrorKey
    }.${progressTask.errorKey?.toUpperCase()}`;
    return (
      this.translationUtilService.getTranslationIfExists(progressTaskTypeErrorKey) ||
      this.translationUtilService.getTranslationIfExists(commonProgressTaskErrorKey)
    );
  }
}
