import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, take } from 'rxjs/operators';

import { ColorConstants, IconConfiguration } from '@celum/common-components';
import { AssetDTO } from '@celum/work/app/content-hub/model/content-hub.model';
import { ContentHubImportActions } from '@celum/work/app/content-hub/store/import.actions';
import { hasAnyActiveContentHubConnections } from '@celum/work/app/core/model/entities/content-item/content-item-util';
import { ColorService } from '@celum/work/app/shared/util';

import { File } from '../../../core/model/entities/file/file.model';

@Component({
  selector: 'ch-import-menu',
  templateUrl: './import-menu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: false
})
export class ContentHubImportMenuComponent {
  @Input() public file: File;

  public readonly chImportAsNewVersionFromAnotherAssetIcon = IconConfiguration.small(
    'import-as-new-version-from-another-asset'
  ).withColor(ColorConstants.BLUE_GRAY_900);
  public readonly infoIcon = IconConfiguration.small('info').withColor(ColorConstants.BLUE_GRAY_900);

  constructor(private store: Store<any>) {}

  public get isEnabledImportCurrentVersion(): boolean {
    return hasAnyActiveContentHubConnections(this.file);
  }

  public get chImportAsNewVersionIcon(): IconConfiguration {
    const disabledColor = ColorService.rgbToRGBA(ColorService.hexToRGB(ColorConstants.BLUE_GRAY_900), 0.36);

    return this.isEnabledImportCurrentVersion
      ? IconConfiguration.small('import-as-new-version').withColor(ColorConstants.BLUE_GRAY_900)
      : IconConfiguration.small('import-as-new-version').withColor(disabledColor);
  }

  public importDifferentAssetAsCurrentVersion(): void {
    this.store.dispatch(ContentHubImportActions.RequestImportDifferentAssetAsANewVersion({ fileId: this.file.id }));
  }

  public importCurrentVersion() {
    if (!this.isEnabledImportCurrentVersion) {
      return;
    }

    this.file
      .activeVersion(this.store)
      .pipe(
        take(1),
        map(activeVersion => new AssetDTO(this.file.importId as any, this.file.name, activeVersion.originalFileSize))
      )
      .subscribe(asset =>
        this.store.dispatch(
          ContentHubImportActions.RequestImportAsNewVersion({
            fileId: this.file.id,
            asset
          })
        )
      );
  }
}
