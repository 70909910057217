import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Injector,
  Input,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { Store } from '@ngrx/store';
import { differenceBy, remove } from 'lodash';
import { BehaviorSubject, forkJoin, Observable, Subject } from 'rxjs';
import { map, switchMap, take, takeUntil, withLatestFrom } from 'rxjs/operators';

import { ColorConstants } from '@celum/common-components';
import { ReactiveComponent } from '@celum/ng2base';
import { MembershipStatus } from '@celum/work/app/core/model/entities/member';
import { selectMemberByUserIdAndTeamspaceId } from '@celum/work/app/core/model/entities/member/member.selectors';
import { filterBySearchString, Person } from '@celum/work/app/core/model/entities/person';
import { TaskList } from '@celum/work/app/core/model/entities/task';
import { Template } from '@celum/work/app/core/model/entities/template/template.model';
import { WorkroomConfiguration } from '@celum/work/app/core/model/entities/workroom';
import {
  isAutomator,
  RobotContext,
  Robots,
  RobotTypes,
  RuleType
} from '@celum/work/app/core/model/entities/workroom/robot.model';
import {
  selectCurrentWorkroomContributingPersons,
  selectCurrentWorkroomId
} from '@celum/work/app/pages/workroom/store/workroom-wrapper.selectors';
import { PermissionCheckStrategy } from '@celum/work/app/pages/workroom-creator/services/permission-check-strategy';
import { InvitedPersonDialogModel } from '@celum/work/app/person/invite/invited-person.model';
import { RobotDialogConfigurationArgs } from '@celum/work/app/robots/components/robot-dialog/robot-dialog-config';
import { RobotCreateEvent, RobotEditEvent } from '@celum/work/app/robots/services/robots-factory';
import { isTaskListRobot } from '@celum/work/app/robots/services/robots-util';
import { ApplicationEventBus } from '@celum/work/app/shared/util/application-event-bus.service';
import { AvatarDecoratorFn } from '@celum/work/app/shared/util/avatar-util';
import { WorkroomWizardEvent } from '@celum/work/app/workroom-wizard/model/workroom-wizard-event';
import { RobotVisibilityService } from '@celum/work/app/workroom-wizard/services/robot-visibility.service';
import {
  PersonWithStatuses,
  WorkroomWizardPeopleService
} from '@celum/work/app/workroom-wizard/services/workroom-wizard-people.service';

import { OpenRobotDialogService } from './../../services/open-robot-dialog.service';
import { Roles } from '../../../core/model';
import { PermissionUtil } from '../../../shared/util';

@Component({
  selector: 'taskboard-and-robots',
  templateUrl: './taskboard-and-robots.component.html',
  styleUrls: ['./taskboard-and-robots.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: false
})
export class TaskboardAndRobotsComponent extends ReactiveComponent implements OnInit {
  @HostBinding('class.taskboard-and-robots') public hostCls = true;

  @Input() public teamspaceId: number;
  @Input() public repositoryId: string;
  @Input() public template: Template;
  @Input() public targetEntity: 'WORKROOM' | 'TEMPLATE';
  @Input() public permissionCheckStrategy: PermissionCheckStrategy;

  public readonly dateBgColor: string = ColorConstants.BLUE_GRAY_100;
  public readonly robotContextType: typeof RobotContext = RobotContext;

  public templatePeople$: Observable<PersonWithStatuses[]>;
  public searchPeople: Subject<string> = new BehaviorSubject<string>('');
  public nonInactivePeople$: Observable<Person[]>;
  public allContributors$: Observable<Person[]>;
  public currentWorkroomId$: Observable<number>;
  public isModerator$: Observable<boolean>;
  public avatarDecorator$: AvatarDecoratorFn;

  constructor(
    public workroomWizardPeopleService: WorkroomWizardPeopleService,
    private cdRef: ChangeDetectorRef,
    private store: Store<any>,
    private robotVisibilityService: RobotVisibilityService,
    private openRobotDialogService: OpenRobotDialogService,
    private eventBus: ApplicationEventBus,
    private injector: Injector,
    private permissionUtil: PermissionUtil
  ) {
    super();
    this.templatePeople$ = this.workroomWizardPeopleService.people$.pipe(
      map(people =>
        people.map(person => ({
          ...person,
          status: this.workroomWizardPeopleService.peopleWithStatuses.find(
            personWithStatus => personWithStatus.id === person.id
          )?.status
        }))
      ),
      takeUntil(this.unsubscribe$)
    );
    this.currentWorkroomId$ = this.store.select(selectCurrentWorkroomId);
  }

  public ngOnInit(): void {
    this.nonInactivePeople$ = this.searchPeople.pipe(
      switchMap(searchQuery =>
        this.workroomWizardPeopleService.people$.pipe(
          switchMap(people =>
            forkJoin(
              people.map(person =>
                this.store.select(selectMemberByUserIdAndTeamspaceId(person.id, this.teamspaceId)).pipe(take(1))
              )
            ).pipe(
              map(memberships => {
                const nonInactivePeople = people.filter(
                  (_, idx) => memberships[idx]?.status !== MembershipStatus.INACTIVE
                );
                return filterBySearchString(searchQuery, nonInactivePeople);
              })
            )
          )
        )
      )
    );
    this.allContributors$ = this.store.select(selectCurrentWorkroomContributingPersons);

    this.avatarDecorator$ = this.workroomWizardPeopleService.getAvatarDecoratorFn();
    this.isModerator$ = this.permissionUtil.hasRoleForCurrentWorkroom(Roles.MODERATOR);
  }

  public handleDueDateUpdated(dueDate: number, taskList: TaskList): void {
    this.template.taskLists.filter(({ id }) => id === taskList.id).forEach(list => (list.dueDate = dueDate));
    this.eventBus.publishEvent({ type: WorkroomWizardEvent.DUE_DATE_CHANGED });
  }

  public addOwner(person: Person, taskList: TaskList) {
    taskList.ownerIds.push(person.id);
    this.eventBus.publishEvent({
      type: WorkroomWizardEvent.TLO_ADDED,
      data: {
        taskListId: taskList.id,
        personId: person.id
      }
    });
  }

  public inviteAndAddOwners(taskList: TaskList, people: InvitedPersonDialogModel[]): Observable<void> {
    return this.workroomWizardPeopleService.people$.pipe(
      take(1),
      map(templatePeople => {
        const newTemplatePeople = this.workroomWizardPeopleService.addNewPeople(people);
        const addedPeople = differenceBy(newTemplatePeople, templatePeople, 'id');
        addedPeople.forEach(person => this.addOwner(person, taskList));
      })
    );
  }

  public removeOwner(person: Person, taskList: TaskList) {
    taskList.ownerIds = taskList.ownerIds.filter(ownerId => ownerId !== person.id);
    this.eventBus.publishEvent({
      type: WorkroomWizardEvent.TLO_REMOVED,
      data: {
        taskListId: taskList.id,
        personId: person.id
      }
    });
  }

  public getOwners(taskList: TaskList): Observable<Person[]> {
    return this.workroomWizardPeopleService.people$.pipe(
      map(people => people.filter(person => taskList.ownerIds.includes(person.id)))
    );
  }

  public robotVisible(robotType: RobotTypes): Observable<boolean> {
    return this.templatePeople$.pipe(
      switchMap(templatePeople =>
        this.robotVisibilityService.robotVisible(
          robotType,
          RobotContext.TASK_LIST,
          templatePeople,
          this.template.workroomConfiguration,
          this.teamspaceId,
          this.repositoryId
        )
      )
    );
  }

  public async openCreateRobotDialog(event: RobotCreateEvent): Promise<void> {
    this.currentWorkroomId$
      .pipe(take(1), withLatestFrom(this.templatePeople$))
      .subscribe(async ([workroomId, templatePeople]) => {
        const robotDialogConfig: RobotDialogConfigurationArgs<any> = {
          component: event.type,
          parentInjector: this.injector,
          workroomConfig: this.template.workroomConfiguration,
          sourceEntity: event.source,
          teamspaceId: this.teamspaceId,
          templatePeople: templatePeople,
          repositoryId: this.repositoryId,
          robotSubType: event.robotSubType,
          workroomId
        };

        await this.openRobotDialogService.openCreateRobotDialog(robotDialogConfig, this.template);
        this.template.workroomConfiguration = { ...this.template.workroomConfiguration };
        this.cdRef.markForCheck();
      });
  }

  public async openEditRobotDialog(event: RobotEditEvent): Promise<void> {
    this.currentWorkroomId$
      .pipe(take(1), withLatestFrom(this.templatePeople$))
      .subscribe(async ([workroomId, templatePeople]) => {
        const taskList = this.template.taskLists.find(taskListItem => taskListItem.id === event.robot.sourceId);
        const robotDialogConfig = {
          robot: event.robot,
          component: event.type,
          parentInjector: this.injector,
          workroomConfig: this.template.workroomConfiguration,
          sourceEntity: taskList,
          teamspaceId: this.teamspaceId,
          templatePeople: templatePeople,
          repositoryId: this.repositoryId,
          robotSubType: isAutomator(event.robot) ? event.robot.subType : null,
          workroomId
        };

        await this.openRobotDialogService.openEditRobotDialog(robotDialogConfig, event, this.template);
        this.template.workroomConfiguration = { ...this.template.workroomConfiguration };
        this.cdRef.markForCheck();
      });
  }

  public deleteRobotFromTemplate(robot: Robots): void {
    if (isAutomator(robot)) {
      remove(this.template.workroomConfiguration.automators, robot);
    } else {
      remove(this.template.workroomConfiguration.rules, robot);
    }
    this.template.workroomConfiguration = { ...this.template.workroomConfiguration };
    this.cdRef.markForCheck();
  }

  public getRobots(workroomConfiguration: WorkroomConfiguration, taskList: TaskList): Robots[] {
    return [
      ...workroomConfiguration.rules.filter(rule => isTaskListRobot(taskList.id, rule)),
      ...workroomConfiguration.automators.filter(automator => isTaskListRobot(taskList.id, automator))
    ];
  }

  public trackByFn(_: number, template: Robots) {
    return template;
  }

  public editable(robot: Robots): boolean {
    const readOnlyRobotTypes: RobotTypes[] = [RuleType.TASK_CREATION_RESTRICTION, RuleType.TASK_MANDATORY_ASSIGNMENT];
    return !readOnlyRobotTypes.includes(robot.type);
  }
}
