import { CdkDrag, CdkDragDrop, CdkDropList, moveItemInArray } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  QueryList,
  ViewChildren,
  ViewEncapsulation
} from '@angular/core';
import { FormArray, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { Store } from '@ngrx/store';
import { catchError, EMPTY, take, takeUntil, throwError } from 'rxjs';

import {
  CelumDialog,
  CelumDialogConfiguration,
  CelumDialogModule,
  CelumIconModule,
  ColorConstants,
  EmptyPage,
  EmptyPageConfig,
  IconConfiguration
} from '@celum/common-components';
import { CelumValidators, ReactiveComponent } from '@celum/ng2base';
import { CustomField } from '@celum/work/app/core/model/entities/custom-field/custom-field.model';
import { CustomForm } from '@celum/work/app/core/model/entities/custom-form/custom-form.model';
import { SharedModule } from '@celum/work/app/shared';
import { SearchAndSelectCustomFields } from '@celum/work/app/shared/components/search-and-select/search-and-select-custom-fields/search-and-select-custom-fields.component';
import { StringUtil } from '@celum/work/app/shared/util/string.util';
import { CustomFormValidationErrorsEnum } from '@celum/work/app/teamspace-management/components/fields-overview-tab/components/create-custom-field-dialog/config/validation-errors.enum';
import {
  CustomFieldCardComponent,
  CustomFieldCardControlValue
} from '@celum/work/app/teamspace-management/components/fields-overview-tab/components/custom-field-card/custom-field-card.component';

import { CustomFormErrorKeyEnum, CustomFormHttpErrorResponse } from '../../model/custom-form-error-response';
import { CreateFormField, CustomFormsService } from '../../services/custom-forms.service';

type CreateFormCustomFieldCardControlValue = Pick<CustomField, 'name' | 'type' | 'id'>;

@Component({
  selector: 'create-custom-form-dialog',
  imports: [
    CommonModule,
    CelumDialogModule,
    CelumIconModule,
    MatFormFieldModule,
    MatButtonModule,
    ReactiveFormsModule,
    SharedModule,
    SearchAndSelectCustomFields,
    CustomFieldCardComponent,
    CdkDropList,
    CdkDrag,
    MatRippleModule
  ],
  templateUrl: './create-custom-form-dialog.component.html',
  styleUrls: ['./create-custom-form-dialog.component.less'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateCustomFormDialogComponent
  extends ReactiveComponent
  implements CelumDialog<CreateCustomFormDialogConfiguration>
{
  public static readonly DIALOG_ID = 'create-custom-form-dialog';

  @HostBinding('class') public componentCls = 'create-custom-form-dialog';
  @ViewChildren(CustomFieldCardComponent) public fieldViews: QueryList<CustomFieldCardComponent>;

  public readonly NAME_CONTROL_LIMIT = 50;

  public readonly customFormGroup = new FormGroup({
    name: new FormControl<string | null>(null, [CelumValidators.required]),
    fields: new FormArray<FormControl<CreateFormCustomFieldCardControlValue>>([])
  });
  public readonly controls: {
    [key in keyof { name: FormControl; fields: FormArray<FormControl<CustomFieldCardControlValue>> }];
  } = {
    name: this.customFormGroup.controls.name,
    fields: this.customFormGroup.controls.fields
  };

  public readonly cancelIcon = IconConfiguration.medium('cancel-s').withColor('white');
  public readonly confirmIcon = IconConfiguration.medium('check-m').withColor('white');
  public readonly fieldAddIcon = IconConfiguration.small('field-create')
    .withIconSize(32)
    .withColor(ColorConstants.BLUE_GRAY_600)
    .withHoverColor(ColorConstants.BLUE_GRAY_700);
  public readonly fieldAddDisabledIcon = IconConfiguration.small('field-strikethrough')
    .withColor(ColorConstants.BLUE_GRAY_500)
    .withIconSize(32);
  public readonly emptyPageConfig: EmptyPageConfig;

  public color: string;
  public fieldLimit: number;

  public validationEnum: typeof CustomFormValidationErrorsEnum = CustomFormValidationErrorsEnum;
  public isSearchAndSelectVisible = false;
  public loading = false;
  public readonlyMode = false;
  public selectedFieldIds: number[] = [];

  private customForm: CustomForm;
  private customFields: CustomField[];

  constructor(
    private dialogRef: MatDialogRef<CreateCustomFormDialogComponent, CreateCustomFormDialogResponse>,
    private customFormService: CustomFormsService,
    private cdr: ChangeDetectorRef,
    private stringUtil: StringUtil,
    private store: Store<any>
  ) {
    super();
    this.controls.fields.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.selectedFieldIds = this.controls.fields.controls.map(
        (fieldControl: FormControl<CreateFormCustomFieldCardControlValue>) => fieldControl.value.id
      );
    });
    this.emptyPageConfig = EmptyPage.noActionConfig(
      'no-fields',
      'no-fields',
      'TEAMSPACE_MANAGEMENT.FORMS.CREATE_FORM_DIALOG.EMPTY',
      'normal',
      232
    );
  }

  public configure(config: CreateCustomFormDialogConfiguration): void {
    this.color = config.color;
    this.fieldLimit = config.fieldLimit;
    this.readonlyMode = config.readonlyMode;
    this.customForm = config.customForm;
    this.customFields = config.customFields;

    if (this.customForm) {
      this.populateForm(this.customForm);
    }

    if (this.customFields) {
      this.populateCustomFields(this.customFields);
    }
  }

  public onSubmit(): void {
    const trimmedFormName = this.stringUtil.collapseWhitespacesBetweenWords(this.controls.name.value.trim());

    this.loading = true;

    this.customFormService
      .createCustomForm(
        trimmedFormName,
        this.controls.fields.controls.map(
          (field: FormControl<CreateFormCustomFieldCardControlValue>, index: number) =>
            ({
              fieldId: field.value.id,
              sort: index + 1
            }) as CreateFormField
        )
      )
      .pipe(
        catchError((err: CustomFormHttpErrorResponse) => {
          this.loading = false;

          if (err.error?.errorKey === CustomFormErrorKeyEnum.FORM_CONFLICTING_STATE) {
            this.controls.name.setValue(trimmedFormName);
            this.controls.name.setErrors({ [CustomFormValidationErrorsEnum.NAME_NOT_UNIQUE]: true });
            this.cdr.markForCheck();

            return EMPTY;
          }

          if (err.error?.errorKey === CustomFormErrorKeyEnum.FORM_LIMIT_EXCEEDED) {
            this.dialogRef.close({ errorKey: err.error.errorKey });

            return EMPTY;
          }

          return throwError(() => err);
        })
      )
      .subscribe(customForm => this.dialogRef.close({ customForm }));
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public openSearchAndSelectOverlay(): void {
    requestAnimationFrame(() => {
      this.isSearchAndSelectVisible = true;
      this.cdr.detectChanges();
    });
  }

  public closeSearchAndSelectOverlay(): void {
    this.isSearchAndSelectVisible = false;
  }

  public customFieldSelected(field: CustomField): void {
    (this.controls.fields as FormArray).push(
      new FormControl<CreateFormCustomFieldCardControlValue>({
        value: {
          type: field.type,
          name: field.name,
          id: field.id,
          ...(field.dropdownOptions && { chips: field.dropdownOptions.map(option => option.name) })
        },
        disabled: true
      })
    );
    this.closeSearchAndSelectOverlay();
  }

  public removeSelectedField(index: number) {
    this.controls.fields.removeAt(index);
  }

  public fieldDropped(event: CdkDragDrop<any>): void {
    const fieldControlsValue: CustomField[] = this.controls.fields.controls.map(
      (fieldControl: FormControl<CreateFormCustomFieldCardControlValue>) => fieldControl.value
    );

    moveItemInArray(fieldControlsValue, event.previousIndex, event.currentIndex);

    this.controls.fields.setValue(fieldControlsValue);
  }

  public setCustomFieldsExpansionState(isExpanded: boolean): void {
    this.fieldViews.forEach(fieldView => fieldView.toggleExpansion(isExpanded));
  }

  public trackByFn(idx: number, item: FormControl<CreateFormCustomFieldCardControlValue>): number {
    return item?.value?.id || idx;
  }

  private populateForm(customForm: CustomForm): void {
    this.controls.name.setValue(customForm.name);

    if (this.readonlyMode) {
      this.customFormGroup.disable();
    }

    customForm
      .customFields(this.store)
      .pipe(take(1))
      .subscribe(fields =>
        fields.forEach(field => this.controls.fields.push(this.generateFormControlFromCustomField(field, true)))
      );
  }

  private populateCustomFields(customFields: CustomField[]): void {
    customFields.forEach(field => this.controls.fields.push(this.generateFormControlFromCustomField(field)));
  }

  private generateFormControlFromCustomField(
    customField: CustomField,
    disabled = false
  ): FormControl<CreateFormCustomFieldCardControlValue> {
    return new FormControl<CreateFormCustomFieldCardControlValue>({
      value: {
        id: customField.id,
        name: customField.name,
        type: customField.type,
        ...(customField.dropdownOptions && {
          chips: customField.dropdownOptions.map(option => option.name)
        })
      },
      disabled
    });
  }
}

export class CreateCustomFormDialogConfiguration extends CelumDialogConfiguration {
  public color: string = ColorConstants.PRIMARY;
  public readonlyMode: boolean;
  public customForm?: CustomForm;
  public customFields?: CustomField[];
  public fieldLimit: number;

  constructor({
    color = ColorConstants.PRIMARY,
    fieldLimit = 100,
    readonlyMode = false,
    customForm,
    customFields
  }: {
    color?: string;
    fieldLimit?: number;
    readonlyMode?: boolean;
    customForm?: CustomForm;
    customFields?: CustomField[];
  } = {}) {
    super(CreateCustomFormDialogConfiguration.name);
    this.color = color;
    this.fieldLimit = fieldLimit;
    this.readonlyMode = readonlyMode;
    this.customForm = customForm;
    this.customFields = customFields;
  }
}

export interface CreateCustomFormDialogResponse {
  customForm?: CustomForm;
  errorKey?: CustomFormErrorKeyEnum;
}
