import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';

import { CelumIconModule, ColorConstants, IconConfiguration } from '@celum/common-components';
import { CustomFieldTypes } from '@celum/work/app/core/model/entities/custom-field/custom-field.model';
import { SharedModule } from '@celum/work/app/shared';

@Component({
  selector: 'field-create-button',
  imports: [CommonModule, CelumIconModule, MatTooltipModule, MatMenuModule, SharedModule],
  templateUrl: './field-create-button.component.html',
  styleUrls: ['./field-create-button.component.less'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FieldCreateButtonComponent {
  @HostBinding('class') public componentCls = 'custom-field-create-button';

  @Output() public fieldTypeSelected = new EventEmitter<CustomFieldTypes>();

  public readonly fieldCreateIcon = IconConfiguration.small('field-create')
    .withIconSize(32)
    .withColor(ColorConstants.BLUE_GRAY_600)
    .withHoverColor(ColorConstants.BLUE_GRAY_700);
  public customFieldType: typeof CustomFieldTypes = CustomFieldTypes;
}
