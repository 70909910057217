import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Inject,
  Injector,
  OnInit,
  Type,
  ViewEncapsulation
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep, isEqual } from 'lodash';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, switchMap, take, takeUntil } from 'rxjs/operators';

import { IconConfiguration } from '@celum/common-components';
import { ReactiveComponent } from '@celum/ng2base';
import { ContentHubBuildNumberStrategyResolverService } from '@celum/work/app/content-hub/services/content-hub-build-number-strategy-resolver.service';
import { selectImportIntentById } from '@celum/work/app/content-hub/store/import-intent.selector';
import { ApplicationQueryParamKey } from '@celum/work/app/core';
import { TenantGuard } from '@celum/work/app/core/auth/tenant.guard';
import { Template } from '@celum/work/app/core/model/entities/template/template.model';
import { templateUpdatableProperties } from '@celum/work/app/core/model/entities/template/template.reducer';
import { selectActiveCategoryId } from '@celum/work/app/core/model/entities/template-category/template-category.selectors';
import { FeatureType, WorkroomConfiguration } from '@celum/work/app/core/model/entities/workroom';
import {
  Automator,
  AutomatorSubType,
  AutomatorType,
  ContentHubAssetAddedTrigger,
  RobotActionType,
  RobotContext,
  RobotTriggerType
} from '@celum/work/app/core/model/entities/workroom/robot.model';
import { DeleteQueryParam, SetQueryParam } from '@celum/work/app/core/router/router.actions';
import { selectRouterQueryParam } from '@celum/work/app/core/router/router.selectors';
import { LicenseLimitReachedPipe } from '@celum/work/app/pages/workroom-creator/pipes/license-limit-reached.pipe';
import { PermissionCheckStrategy } from '@celum/work/app/pages/workroom-creator/services/permission-check-strategy';
import { PermissionNoCheckStrategy } from '@celum/work/app/pages/workroom-creator/services/permission-no-check-strategy.service';
import { SaveStrategy } from '@celum/work/app/pages/workroom-creator/services/save-strategy';
import { FeatureService } from '@celum/work/app/shared/components/features/feature.service';
import { StepConfiguration } from '@celum/work/app/shared/components/stepper/stepper.component';
import { ToggleDrawerButtonTooltipConfig } from '@celum/work/app/shared/components/toggle-drawer-button/toggle-drawer-button.component';
import { TemplateUtil } from '@celum/work/app/shared/util/template-util';

import { RobotEventListenerService } from '../services/robot-event-listener.service';
import { TaskListEventListenerService } from '../services/task-list-event-listener.service';
import { WorkroomWizardPeopleService } from '../services/workroom-wizard-people.service';
import { WorkroomWizardTranslations } from '../workroom-wizard-translations';

export interface WorkroomWizardData {
  title: string;
  type: WorkroomWizardType;
  template: Template;
  permissionStrategyType?: Type<PermissionCheckStrategy>;
  saveStrategyType: Type<SaveStrategy>;
}

export interface WorkroomWizardType {
  targetEntity: 'WORKROOM' | 'TEMPLATE';
  editMode: boolean;
}

@Component({
  selector: 'workroom-wizard',
  templateUrl: './workroom-wizard.component.html',
  styleUrls: ['./workroom-wizard.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [WorkroomWizardPeopleService, RobotEventListenerService, TaskListEventListenerService],
  standalone: false
})
export class WorkroomWizardComponent extends ReactiveComponent implements OnInit {
  @HostBinding('class') public readonly cls = 'workroom-wizard';

  public processViewHeight = '40vh';
  public loading: boolean;
  public readonly checkIcon: IconConfiguration = IconConfiguration.medium('check-m').withColor('white');
  public readonly previousIcon: IconConfiguration = IconConfiguration.medium('arrow-left');
  public readonly nextIcon: IconConfiguration = IconConfiguration.medium('arrow-right');
  public readonly workroomWizardForm: UntypedFormGroup = new UntypedFormGroup({});
  public initTemplate: Template;
  public readonly collapseButtonTooltipConfig: ToggleDrawerButtonTooltipConfig = {
    open: 'WORKROOM_WIZARD.DIALOG.COLLAPSE_BUTTON.SIDEBAR.OPEN',
    close: 'WORKROOM_WIZARD.DIALOG.COLLAPSE_BUTTON.SIDEBAR.CLOSE'
  };
  public stepsConfiguration: StepConfiguration[] = [
    {
      icon: IconConfiguration.small('description'),
      label: this.translations.details
    },
    {
      icon: IconConfiguration.small('task-list-settings'),
      label: 'WORKROOM_WIZARD.DIALOG.STEPS.TASKBOARD'
    },
    {
      icon: IconConfiguration.small('workflow'),
      label: 'WORKROOM_WIZARD.DIALOG.STEPS.WORKFLOW',
      featureType: FeatureType.WORKFLOW
    }
  ];

  public readonly subscriptionSynced$ = new BehaviorSubject(true);
  public readonly permissionStrategy: PermissionCheckStrategy;
  public readonly saveStrategy: SaveStrategy;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: WorkroomWizardData,
    private dialogRef: MatDialogRef<WorkroomWizardComponent>,
    private injector: Injector,
    private store: Store<any>,
    private licenseLimitReachedPipe: LicenseLimitReachedPipe,
    private translateService: TranslateService,
    private featureService: FeatureService,
    private chStrategy: ContentHubBuildNumberStrategyResolverService,
    private workroomWizardPeopleService: WorkroomWizardPeopleService,
    // If they are not injected, they are tree shaken -> no event listening
    public robotEventListener: RobotEventListenerService,
    public taskListEventListener: TaskListEventListenerService
  ) {
    super();
    this.checkStepsBasedOnFeatures();
    this.permissionStrategy = this.injector.get<PermissionCheckStrategy>(
      data.permissionStrategyType || PermissionNoCheckStrategy
    );
    this.saveStrategy = this.injector.get<SaveStrategy>(data.saveStrategyType);
    this.prepareTemplate();
  }

  private static getDummyGeneralRobotForImportIntent(): Automator {
    return {
      action: {
        type: RobotActionType.CONTENT_HUB_IMPORT_ASSET
      },
      sourceContext: RobotContext.CONTENT_ITEM,
      subType: AutomatorSubType.CONTENT_HUB_ASSETS,
      trigger: {
        type: RobotTriggerType.CONTENT_HUB_ASSET_ADDED,
        collectionFolderPairs: []
      } as ContentHubAssetAddedTrigger,
      type: AutomatorType.CONTENT_HUB
    };
  }

  public get translations(): WorkroomWizardTranslations {
    return new WorkroomWizardTranslations(this.data.type.targetEntity);
  }

  public get repositoryId$(): Observable<string> {
    return of(this.workroomWizardForm?.get('repositoryId')?.value);
  }

  public get workroomsLimitReached$(): Observable<boolean> {
    if (this.data.type.targetEntity === 'TEMPLATE' || this.data.type.editMode) {
      return of(false);
    }

    return this.store
      .select(selectActiveCategoryId)
      .pipe(switchMap(categoryId => this.licenseLimitReachedPipe.transform(categoryId)));
  }

  public ngOnInit() {
    this.handleQueryParams(this.data);
  }

  public saveTemplate(): void {
    this.loading = true;
    this.saveStrategy
      .save(this.data.template, this.workroomWizardForm.getRawValue(), this.workroomWizardPeopleService.invitedPeople)
      .subscribe(() => {
        this.closeDialog();
      });
  }

  public closeDialog(): void {
    this.loading = false;
    this.dialogRef.close();
  }

  public isPristineInEditMode(): boolean {
    return (
      this.data.type.editMode &&
      this.templateNotUpdated() &&
      this.workroomWizardForm.pristine &&
      this.workroomWizardPeopleService.peopleNotTouched()
    );
  }

  private checkStepsBasedOnFeatures(): void {
    this.stepsConfiguration = this.stepsConfiguration.filter(
      ({ featureType }) =>
        !featureType ||
        this.featureService.hasFeatureInWorkroomConfig(this.data.template.workroomConfiguration, featureType)
    );
  }

  private prepareTemplate(): void {
    this.chStrategy
      .resolve(this.data.template.contentHubRepositoryId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(strategy => {
        let workroomConfiguration: WorkroomConfiguration;
        this.data.template.taskLists.forEach(
          taskList => (taskList.name = this.translateService.instant(taskList.name))
        );

        if (strategy) {
          workroomConfiguration = strategy.filterInvalidCHAutomators(this.data.template.workroomConfiguration);
        } else {
          workroomConfiguration = {
            ...this.data.template.workroomConfiguration,
            automators: this.data.template.workroomConfiguration.automators.filter(
              ({ type }) => type !== AutomatorType.CONTENT_HUB
            )
          };
        }

        this.data.template = {
          ...this.data.template,
          workroomConfiguration
        };

        this.initTemplate = cloneDeep(this.data.template);
        TemplateUtil.updateTemplateTaskListsSort(this.initTemplate);
        this.addDummyGeneralRobotToWorkoomConfigForImportIntent();
      });
  }

  private templateNotUpdated(): boolean {
    return templateUpdatableProperties.every(property =>
      isEqual(this.initTemplate[property], this.data.template[property])
    );
  }

  private addDummyGeneralRobotToWorkoomConfigForImportIntent() {
    this.store
      .select(selectRouterQueryParam(TenantGuard.IMPORT_INTENT_PARAM))
      .pipe(
        take(1),
        switchMap(importIntentId => {
          return this.store.select(selectImportIntentById(importIntentId)).pipe(
            map(importIntent => {
              return importIntent?.collectionCounter > 0;
            })
          );
        })
      )
      .subscribe(importIntentSelected => {
        const generalRobotExists = this.data.template.workroomConfiguration.automators.some(
          automator => automator.action.type === RobotActionType.CONTENT_HUB_IMPORT_ASSET
        );
        if (importIntentSelected && !generalRobotExists) {
          this.data.template.workroomConfiguration.automators.push(
            WorkroomWizardComponent.getDummyGeneralRobotForImportIntent()
          );
        }
      });
  }

  private handleQueryParams(data: WorkroomWizardData): void {
    let queryParamKeyName: ApplicationQueryParamKey;

    if (data.type.targetEntity === 'TEMPLATE') {
      queryParamKeyName = data.type.editMode ? 'templateManager' : 'templateCreator';
    } else {
      queryParamKeyName = data.type.editMode ? 'wrManager' : 'wrCreator';
    }

    this.store.dispatch(SetQueryParam({ key: queryParamKeyName, value: true }));

    this.dialogRef.afterClosed().subscribe(() => {
      this.store.dispatch(DeleteQueryParam({ key: queryParamKeyName }));
    });
  }
}
