import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { produce } from 'immer';

import { DataUtil } from '@celum/core';
import { EntityUtil } from '@celum/work/app/core/model';
import { mergeEntities, mergeEntity } from '@celum/work/app/core/model/entities/entities-state-util';
import {
  FileVersionDeleteOne,
  FileVersionsDeleteMany,
  FileVersionsUpsertMany,
  FileVersionUpsertOne
} from '@celum/work/app/core/model/entities/file-version/file-version.actions';
import { RenditionUpsertMany } from '@celum/work/app/core/model/entities/rendition/rendition.actions';

import { FileVersion, FileVersionsState } from './file-version.model';

export const fileVersionAdapter: EntityAdapter<FileVersion> = createEntityAdapter<FileVersion>();
export const initialState: FileVersionsState = fileVersionAdapter.getInitialState();

const fileVersionBasicProperties = ['originalFileSize', 'versionNumber', 'renditionsIds', 'creatorName'];

const reducer = createReducer(
  initialState,
  on(FileVersionUpsertOne, (state: FileVersionsState, { fileVersion, propertiesToUpdate }) => {
    const fileVersions = EntityUtil.changedEntities(fileVersionBasicProperties, [fileVersion], state.entities);
    if (!DataUtil.isEmpty(fileVersions)) {
      return fileVersionAdapter.upsertOne(mergeEntity(fileVersions[0], state, propertiesToUpdate), state);
    } else {
      return state;
    }
  }),

  on(FileVersionsUpsertMany, (state: FileVersionsState, { fileVersions, propertiesToUpdate }) => {
    const changedFileVersions = EntityUtil.changedEntities(fileVersionBasicProperties, fileVersions, state.entities);
    return fileVersionAdapter.upsertMany(mergeEntities(changedFileVersions, state, propertiesToUpdate), state);
  }),

  on(FileVersionDeleteOne, (state: FileVersionsState, { fileVersion }) => {
    return fileVersionAdapter.removeOne(fileVersion.id, state);
  }),

  on(FileVersionsDeleteMany, (state: FileVersionsState, { ids }) => {
    return fileVersionAdapter.removeMany(ids, state);
  }),

  on(RenditionUpsertMany, (state: FileVersionsState, { renditions }) =>
    produce(state, draft => {
      renditions.forEach(rendition => {
        const fileVersion: FileVersion = draft.entities[rendition.fileVersionId];
        const renditionReferenceMissing = fileVersion && !fileVersion?.renditionsIds?.includes(rendition.id);

        if (renditionReferenceMissing) {
          fileVersion.renditionsIds = [...fileVersion.renditionsIds, rendition.id];
        }
      });
    })
  )
);

export function fileVersionReducer(state: FileVersionsState = initialState, action: Action): FileVersionsState {
  return reducer(state, action);
}
