import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Injector,
  Input,
  OnInit,
  TemplateRef,
  ViewEncapsulation
} from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { remove } from 'lodash';
import { combineLatest, firstValueFrom, Observable } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';

import { ColorConstants } from '@celum/common-components';
import { CelumDialogOpener } from '@celum/internal-components';
import { ReactiveComponent } from '@celum/ng2base';
import { Color } from '@celum/work/app/core/model';
import { Person } from '@celum/work/app/core/model/entities/person';
import { TaskList } from '@celum/work/app/core/model/entities/task';
import { Template } from '@celum/work/app/core/model/entities/template/template.model';
import {
  Automator,
  isAutomator,
  RobotContext,
  Robots,
  RobotTriggerType
} from '@celum/work/app/core/model/entities/workroom/robot.model';
import { Workroom } from '@celum/work/app/core/model/entities/workroom/workroom.model';
import { selectTaskCountForList } from '@celum/work/app/pages/workroom/pages/tasks/store/tasks-overview.selectors';
import { selectCurrentWorkroom } from '@celum/work/app/pages/workroom/store/workroom-wrapper.selectors';
import { PermissionCheckStrategy } from '@celum/work/app/pages/workroom-creator/services/permission-check-strategy';
import { RobotDialogConfigurationArgs } from '@celum/work/app/robots/components/robot-dialog/robot-dialog-config';
import { RobotCreateEvent, RobotEditEvent } from '@celum/work/app/robots/services/robots-factory';
import { isTaskListRobot } from '@celum/work/app/robots/services/robots-util';
import {
  ConfirmationDialog,
  ConfirmationDialogConfiguration
} from '@celum/work/app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { calculateNewSortValue, MAX_SORT, MIN_SORT, sortAscending } from '@celum/work/app/shared/util';
import { TaskListsBoardBaseComponent } from '@celum/work/app/task-list/components/task-lists-board-base/task-lists-board-base.component';
import { WorkroomWizardPeopleService } from '@celum/work/app/workroom-wizard/services/workroom-wizard-people.service';

import { OpenRobotDialogService } from '../../services/open-robot-dialog.service';

@Component({
  selector: 'task-lists-configuration-board',
  templateUrl: './task-lists-configuration-board.component.html',
  styleUrls: ['./task-lists-configuration-board.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: false
})
export class TaskListsConfigurationBoardComponent extends ReactiveComponent implements OnInit {
  @HostBinding('class.task-lists-configuration-board') public hostCls = true;

  @Input() public repositoryId: string;
  @Input() public template: Template;
  @Input() public permissionCheckStrategy: PermissionCheckStrategy;
  @Input() public taskListBodyTemplate: TemplateRef<any>;
  @Input() public showWorkroomRobots = true;

  public templatePeople$: Observable<Person[]>;
  public currentWorkroom: Workroom;

  public readonly robotContextType: typeof RobotContext = RobotContext;

  public readonly dragNDropListBag: string = 'list-bag-taskboard';

  constructor(
    private store: Store<any>,
    private dialogOpener: CelumDialogOpener,
    private cdRef: ChangeDetectorRef,
    private translate: TranslateService,
    private workroomWizardPeopleService: WorkroomWizardPeopleService,
    private openRobotDialogService: OpenRobotDialogService,
    private injector: Injector
  ) {
    super();
  }

  public get generalCollectionRobot(): Automator | undefined {
    const automators: Automator[] = this.template.workroomConfiguration.automators;
    return automators.find(
      (automator: Automator) =>
        automator.sourceContext === RobotContext.CONTENT_ITEM &&
        automator.trigger.type === RobotTriggerType.CONTENT_HUB_ASSET_ADDED
    );
  }

  public ngOnInit() {
    this.templatePeople$ = this.workroomWizardPeopleService.people$.pipe(takeUntil(this.unsubscribe$));
    this.store
      .select(selectCurrentWorkroom)
      .pipe(take(1))
      .subscribe(workroom => (this.currentWorkroom = workroom));
  }

  public async openCreateRobotDialog(event: RobotCreateEvent): Promise<void> {
    const robotDialogConfig: RobotDialogConfigurationArgs<any> = {
      component: event.type,
      parentInjector: this.injector,
      workroomConfig: this.template.workroomConfiguration,
      robotSubType: event.robotSubType,
      workroomId: this.currentWorkroom?.id,
      sourceEntity: event.source
    };

    await this.openRobotDialogService.openCreateRobotDialog(robotDialogConfig, this.template);

    this.cdRef.markForCheck();
  }

  public async openEditRobotDialog(event: RobotEditEvent): Promise<void> {
    const robotDialogConfig = {
      robot: event.robot,
      component: event.type,
      parentInjector: this.injector,
      workroomConfig: this.template.workroomConfiguration,
      workroomId: this.currentWorkroom?.id,
      robotSubType: isAutomator(event.robot) ? event.robot.subType : null
    };

    await this.openRobotDialogService.openEditRobotDialog(robotDialogConfig, event, this.template);

    this.cdRef.markForCheck();
  }

  public deleteRobotFromTemplate(robot: Robots): void {
    if (isAutomator(robot)) {
      remove(this.template.workroomConfiguration.automators, robot);
    } else {
      remove(this.template.workroomConfiguration.rules, robot);
    }
  }

  public onCreateNewTaskList(taskList: TaskList) {
    const maxId: number = Math.max.apply(
      null,
      this.template.taskLists.map(taskListItem => +taskListItem.id)
    );
    taskList.id = maxId + 1;
    this.template.taskLists.push(taskList);
    this.adaptSortForDroppedTaskLists();
    this.refreshTaskLists();
  }

  public trackByFn(_, item: TaskList): number {
    return item.sort;
  }

  public taskListDeletable(taskList: TaskList): Observable<boolean> {
    return combineLatest([this.permissionCheckStrategy.canEditWorkroom()]).pipe(
      map(
        ([canEditWorkroom]) =>
          canEditWorkroom && this.template.taskLists.length > TaskListsBoardBaseComponent.MIN_TASK_LIST
      )
    );
  }

  public refreshTaskLists(): void {
    this.template.taskLists = [...this.template.taskLists.map(list => ({ ...list }))].sort((a, b) =>
      sortAscending(a.sort, b.sort)
    );
  }

  public async deleteTaskList(taskList: TaskList) {
    const taskCount = await firstValueFrom(this.store.select(selectTaskCountForList(taskList.id)).pipe(take(1)));
    if (taskCount > 0) {
      const confirmed = await this.showConfirmationDialog();
      if (!confirmed) {
        return;
      }
    }

    this.template.taskLists = this.template.taskLists.filter(list => list.id !== taskList.id);
    this.template.workroomConfiguration.transitions = this.template.workroomConfiguration.transitions.filter(
      transition => transition.from !== taskList.id && transition.to !== taskList.id
    );
    this.template.workroomConfiguration.automators = this.template.workroomConfiguration.automators.filter(
      automator => !isTaskListRobot(taskList.id, automator)
    );
    this.template.workroomConfiguration.rules = this.template.workroomConfiguration.rules.filter(
      rule => !isTaskListRobot(taskList.id, rule)
    );
    this.cdRef.markForCheck();
  }

  public droppedTaskList({ index, taskListId }: { index: number; taskListId: number }) {
    const taskList = this.template.taskLists.find(({ id }) => id === taskListId);
    taskList.sort = calculateNewSortValue(index, taskListId, this.template.taskLists);
    this.adaptSortForDroppedTaskLists();
    this.refreshTaskLists();
  }

  public updateColor(color: Color, taskList: TaskList): void {
    taskList.color = color;
    this.refreshTaskLists();
  }

  public updateTaskListName(name: string, taskList: TaskList) {
    taskList.name = name;
    this.template.taskLists = [...this.template.taskLists.map(list => ({ ...list }))];
  }

  private async showConfirmationDialog() {
    return this.dialogOpener.showDialog(
      'deleteTaskList',
      ConfirmationDialog,
      new ConfirmationDialogConfiguration(
        this.translate.instant('TASK_BOARD.DELETE_LIST.HEADER'),
        this.translate.instant('TASK_BOARD.DELETE_LIST.QUESTION'),
        ColorConstants.WARNING,
        this.translate.instant('COMMON.DELETE')
      )
    );
  }

  private adaptSortForDroppedTaskLists() {
    this.template.taskLists.forEach(list => {
      if (list.sort === MIN_SORT) {
        const minSort = this.template.taskLists
          .map(listItem => listItem.sort)
          .filter(sort => sort !== MIN_SORT)
          .reduce((prev, current) => (prev < current ? prev : current));
        list.sort = minSort / 2;
      }
      if (list.sort === MAX_SORT) {
        const maxSort = this.template.taskLists
          .map(listItem => listItem.sort)
          .filter(sort => sort !== MAX_SORT)
          .reduce((prev, current) => (prev > current ? prev : current));
        list.sort = maxSort + 500;
      }
    });
  }
}
