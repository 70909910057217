import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

export interface StorageBannerTranslationsPipeArgs {
  usedStoragePercentage: number;
  used3DConversionsPercentage: number;
}

enum Source {
  Storage = 'STORAGE.BANNER.COMMON.STORAGE',
  X3D_CONVERSIONS = 'STORAGE.BANNER.COMMON.X3D_CONVERSION',
  STORAGE_AND_X3D_CONVERSIONS = 'STORAGE.BANNER.COMMON.STORAGE_AND_X3D_CONVERSIONS'
}

@Pipe({
  name: 'storageBannerTranslations',
  standalone: false
})
export class StorageBannerTranslationsPipe implements PipeTransform {
  private readonly translations = {
    and: this.translateService.instant('STORAGE.BANNER.COMMON.AND'),
    or: this.translateService.instant('STORAGE.BANNER.COMMON.OR'),
    upgradePlan: this.translateService.instant('STORAGE.BANNER.TEXT.UPGRADE_PLAN'),
    willNotAbleToUpload: (
      this.translateService.instant('STORAGE.BANNER.TEXT.WILL_NOT_ABLE_TO_UPLOAD') as string
    ).toLowerCase(),
    cannotUploadAnymore: this.translateService.instant('STORAGE.BANNER.TEXT.CANNOT_UPLOAD_ANYMORE'),
    x3DDisabled: this.translateService.instant('STORAGE.BANNER.TEXT.3D_CONVERSION_IS_DISABLED'),
    x3DWillBeDisabled: this.translateService.instant('STORAGE.BANNER.TEXT.3D_CONVERSION_WILL_BE_DISABLED'),
    deleteFiles: this.translateService.instant('STORAGE.BANNER.TEXT.DELETE_FILES'),
    limitReached: (source: Source) =>
      this.translateService.instant('STORAGE.BANNER.HEADLINE.REACHED_LIMIT', {
        source: this.translateService.instant(source)
      }),
    limitReaches: (source: Source) =>
      this.translateService.instant('STORAGE.BANNER.HEADLINE.REACHES_LIMIT', {
        source: this.translateService.instant(source)
      })
  };

  constructor(private translateService: TranslateService) {}

  public transform({ usedStoragePercentage, used3DConversionsPercentage }: StorageBannerTranslationsPipeArgs): {
    headline: string;
    text: string;
  } {
    const storageInWarningThreshold = usedStoragePercentage >= 90 && usedStoragePercentage < 100;
    const x3DConversionsInWarningThreshold = used3DConversionsPercentage >= 90 && used3DConversionsPercentage < 100;
    const bothInWarningThreshold = storageInWarningThreshold && x3DConversionsInWarningThreshold;
    const storageLimitReached = usedStoragePercentage >= 100;
    const x3DConversionsLimitReached = used3DConversionsPercentage >= 100;
    const bothLimitReached = storageLimitReached && x3DConversionsLimitReached;

    const {
      and,
      or,
      upgradePlan,
      willNotAbleToUpload,
      cannotUploadAnymore,
      x3DDisabled,
      x3DWillBeDisabled,
      deleteFiles,
      limitReached,
      limitReaches
    } = this.translations;

    // One limit is almost reached, another is reached
    if (
      (storageLimitReached && x3DConversionsInWarningThreshold) ||
      (x3DConversionsLimitReached && storageInWarningThreshold)
    ) {
      const limitReachedSource = storageLimitReached ? Source.Storage : Source.X3D_CONVERSIONS;
      const limitReachesSource =
        limitReachedSource === Source.X3D_CONVERSIONS ? Source.Storage : Source.X3D_CONVERSIONS;
      const text =
        limitReachedSource === Source.Storage
          ? `${cannotUploadAnymore} ${and} ${x3DWillBeDisabled}. ${upgradePlan} ${or} ${deleteFiles}.`
          : `${x3DDisabled} ${and} ${willNotAbleToUpload}. ${upgradePlan}.`;

      return {
        headline: `${limitReached(limitReachedSource)} ${and} ${limitReaches(limitReachesSource)}.`,
        text
      };
    }

    // limit reaches
    if (storageInWarningThreshold || x3DConversionsInWarningThreshold) {
      if (bothInWarningThreshold || storageInWarningThreshold) {
        const source = bothInWarningThreshold ? Source.STORAGE_AND_X3D_CONVERSIONS : Source.Storage;

        return {
          headline: `${limitReaches(source)}.`,
          text: `${upgradePlan} ${or} ${deleteFiles}.`
        };
      } else {
        return {
          headline: `${limitReaches(Source.X3D_CONVERSIONS)}.`,
          text: `${upgradePlan}.`
        };
      }
    }

    // limit is reached
    if (storageLimitReached || x3DConversionsLimitReached) {
      if (bothLimitReached || storageLimitReached) {
        const source = bothLimitReached ? Source.STORAGE_AND_X3D_CONVERSIONS : Source.Storage;
        const additionalText =
          source === Source.STORAGE_AND_X3D_CONVERSIONS
            ? `${cannotUploadAnymore} ${and} ${x3DDisabled}`
            : `${cannotUploadAnymore}`;

        return {
          headline: `${limitReached(source)}.`,
          text: `${additionalText}. ${upgradePlan} ${or} ${deleteFiles}.`
        };
      } else {
        return {
          headline: `${limitReached(Source.X3D_CONVERSIONS)}.`,
          text: `${x3DDisabled}. ${upgradePlan}.`
        };
      }
    }
  }
}
