import { AfterContentInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[autoSelect]',
  standalone: false
})
export class AutoSelectDirective implements AfterContentInit {
  @Input() public selectionRangeFn: (val: string) => number;

  constructor(private el: ElementRef) {}

  public ngAfterContentInit() {
    if (this.selectionRangeFn) {
      const value = this.el.nativeElement.value;
      const end = this.selectionRangeFn(value);
      this.el.nativeElement.setSelectionRange(0, end);
    } else {
      this.el.nativeElement.select();
    }
  }
}
