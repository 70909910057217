import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { takeUntil } from 'rxjs/operators';

import { ColorConstants, IconConfiguration, PageContext } from '@celum/common-components';
import { ICON_SIZE, Operation } from '@celum/core';
import { ReactiveComponent } from '@celum/ng2base';

import { DisabledOperationService } from '../../../magic-button/button/services/disabled-operation.service';

@Component({
             selector: 'operation-menu-item',
             templateUrl: './operation-menu-item.html',
             styleUrls: ['./operation-menu-item.less'],
             providers: [DisabledOperationService],
             changeDetection: ChangeDetectionStrategy.OnPush,
             encapsulation: ViewEncapsulation.None,
             standalone: false
           })
export class OperationMenuItem extends ReactiveComponent implements OnChanges {

  @Input() public operation: Operation;
  @Input() public context: PageContext;

  @Output() public readonly itemClick = new EventEmitter<void>();

  @HostBinding('class.operation-menu-item--disabled') public disabledClass = false;

  public iconConfig: IconConfiguration;
  public infoIcon = IconConfiguration.small('warn-filled-s', '').withColor(ColorConstants.BLUE_GRAY_900);

  constructor(public service: DisabledOperationService) {
    super();

    this.service.disabled$.pipe(takeUntil(this.unsubscribe$)).subscribe(disabled => {
      this.disabledClass = disabled;
    });
  }

  public ngOnChanges({ operation }: SimpleChanges): void {
    if (operation?.currentValue) {
      this.iconConfig = IconConfiguration.medium(this.operation.getIcon(ICON_SIZE.m), '');
    }

    if (this.context && this.operation) {
      this.service.update(this.operation, this.context);
    }
  }

  public onClick(): void {
    if (this.service.isDisabled) {
      return;
    }

    this.itemClick.emit();
  }
}
