import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

import { ColorConstants, IconConfiguration } from '@celum/common-components';
import { ICON_SIZE } from '@celum/core';

@Component({
  selector: 'info-note',
  templateUrl: './info-note.component.html',
  styleUrls: ['./info-note.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: false
})
export class InfoNoteComponent implements OnInit {
  @Input() public text: string;
  @Input() public iconSize: number = ICON_SIZE.s;

  public iconConfig: IconConfiguration;

  public ngOnInit(): void {
    this.iconConfig = IconConfiguration.small('icon-16-info-circle')
      .withColor(ColorConstants.PRIMARY)
      .withIconSize(this.iconSize);
  }
}
