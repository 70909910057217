import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { Observable } from 'rxjs';

import { ColorConstants, IconConfiguration } from '@celum/common-components';
import { CelumDialogOpener } from '@celum/internal-components';
import { ReactiveComponent } from '@celum/ng2base';
import { InvitationStatus } from '@celum/work/app/core/model/entities/member/member.model';
import { Person } from '@celum/work/app/core/model/entities/person';
import { TaskList } from '@celum/work/app/core/model/entities/task';
import {
  InviteUserDialog,
  InviteUserDialogConfiguration,
  InviteUserDialogExecuteFn
} from '@celum/work/app/person/invite/components/invite-user-dialog/invite-user-dialog.component';
import { InteractiveAvatarsEvents } from '@celum/work/app/shared/components/interactive-avatar/interactive-avatars.events';
import { ApplicationEventBus } from '@celum/work/app/shared/util/application-event-bus.service';
import { AvatarDecoratorFn } from '@celum/work/app/shared/util/avatar-util';

@Component({
  selector: 'task-list-owners',
  templateUrl: './task-list-owners.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: false
})
export class TaskListOwners extends ReactiveComponent implements OnInit, OnChanges {
  @Input() public taskList: TaskList;
  @Input() public buttonColor: string = ColorConstants.BLUE_GRAY_500;
  @Input() public buttonHoverColor: string = ColorConstants.BLUE_GRAY_700;
  @Input() public maxDisplayedOwners = 2;
  @Input() public teamspaceId: number;
  @Input() public canAddTaskListOwners: boolean;
  @Input() public canRemoveTaskListOwners: boolean;
  @Input() public darkOverflowBackground: boolean;
  @Input() public canInviteAndAssign: boolean;
  @Input() public canInviteAndAssignOrganisationMembersOnly = false;
  @Input() public showMessageFieldOnInvitationDialog = true;

  @Input() public filteredPeople: Person[];
  @Input() public owners: Person[];
  @Input() public workroomContributors: Person[];
  @Input() public inviteAndAssignFn: InviteUserDialogExecuteFn;
  @Input() public avatarDecorator: AvatarDecoratorFn;

  @Output() public readonly addOwner: EventEmitter<Person> = new EventEmitter<Person>();
  @Output() public readonly removeOwner: EventEmitter<Person> = new EventEmitter<Person>();
  @Output() public readonly searchPeople: EventEmitter<string> = new EventEmitter<string>();

  @HostBinding('attr.data-cy') public attrDataCy = 'task-list-owners';

  public iconConfig: IconConfiguration;
  public currentSearchedValue = '';

  public showInviteAndAssign$: Observable<boolean>;

  constructor(
    private dialogOpener: CelumDialogOpener,
    private eventBus: ApplicationEventBus
  ) {
    super();
  }

  public ngOnInit(): void {
    this.updateIconConfiguration();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.taskList) {
      this.updateIconConfiguration();
    }
  }

  public searchedValueChanged(newValue: string): void {
    this.searchPeople.emit(newValue);
    this.currentSearchedValue = newValue;
  }

  public inviteAndAssign(): void {
    const statusesToDisplay = [InvitationStatus.ACCEPTED, InvitationStatus.APPROVED, InvitationStatus.PENDING_APPROVAL];
    const executeFn: InviteUserDialogExecuteFn = people => {
      this.eventBus.publishEvent({ type: InteractiveAvatarsEvents.SHOULD_CLOSE_SEARCH });
      return this.inviteAndAssignFn(people);
    };

    const dialogConfig = new InviteUserDialogConfiguration({
      headerText: 'PEOPLE.INVITE.INVITE_AND_ASSIGN_TITLE',
      confirmButtonText: 'PEOPLE.INVITE.INVITE_AND_ASSIGN_BTN',
      teamspaceId: this.teamspaceId,
      alreadyInvited: this.workroomContributors.map(({ email }) => email),
      statusesToDisplay: statusesToDisplay,
      organisationMembersOnly: this.canInviteAndAssignOrganisationMembersOnly,
      showMessage: this.showMessageFieldOnInvitationDialog,
      initialPeopleSearchString: this.currentSearchedValue,
      executeFn
    });

    this.dialogOpener.showDialog('personDialog', InviteUserDialog, dialogConfig);
  }

  private updateIconConfiguration() {
    this.iconConfig = IconConfiguration.small('task-assignee')
      .withIconSize(30)
      .withColor(this.buttonColor)
      .withHoverColor(this.buttonHoverColor);
  }
}
