import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { isEqual } from 'lodash';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, debounceTime, map, startWith, switchMap } from 'rxjs/operators';

import { PaginationResult, ValidatorUtil } from '@celum/core';
import { CelumAutocompleteArea } from '@celum/internal-components';
import { CelumValidators, ReactiveComponent } from '@celum/ng2base';
import { TeamspaceMemberService } from '@celum/work/app/core/api/teamspace-member';
import { InvitationStatus } from '@celum/work/app/core/model/entities/member';
import { Person } from '@celum/work/app/core/model/entities/person';

export interface LookupResult {
  type: 'person-suggestion' | 'hint';
  persons?: Person[];
  hint?: string;
}

@Component({
  selector: 'people-lookup',
  templateUrl: './people-lookup.component.html',
  styleUrls: ['./people-lookup.component.less'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class PeopleLookup extends ReactiveComponent implements OnInit, OnDestroy {
  @Input() public teamspaceId: number;

  // this text is displayed in the dropdown field
  @Input() public placeholderText = '';

  // if email selection is supported this message is displayed if no email or item is found
  @Input() public noElementFoundText = '';

  @Input() public minCharText = '';

  // additional note witch is displayed for single/multi line
  @Input() public additionalInfo = '';

  @Input() public selectedPeople = [] as Person[];

  // emails to exclude from lookup
  @Input() public alreadyInvited: string[] = [];

  // disable non members
  @Input() public organisationMembersOnly = false;

  @Input() public statusesToDisplay: InvitationStatus[];

  @Input() public initialPeopleSearchString: string;

  // the selected person entity is emitted
  @Output() public readonly itemSelected: EventEmitter<Person> = new EventEmitter<Person>();
  // the email address is emitted (string only)
  @Output() public readonly emailAdded: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild(CelumAutocompleteArea) public autoComplete: CelumAutocompleteArea;

  public lookupItem: UntypedFormControl = new UntypedFormControl('', [
    CelumValidators.minLength(1),
    CelumValidators.maxLength(100)
  ]);
  public lookupForm: UntypedFormGroup;
  public lookupResults$: Observable<LookupResult>;

  private minChars = 3;

  constructor(
    fb: UntypedFormBuilder,
    private memberService: TeamspaceMemberService
  ) {
    super();
    this.lookupForm = fb.group({
      lookupItem: this.lookupItem
    });
  }

  public ngOnInit() {
    this.lookupResults$ = this.lookupForm.controls.lookupItem.valueChanges.pipe(
      startWith(this.initialPeopleSearchString ?? ''),
      map(val => (typeof val !== 'string' ? '' : val.toLowerCase())),
      debounceTime(400),
      switchMap(searchValue => {
        // Manually trigger dropdown opening with suggestions if the dialog is opened with "Invite and assign"
        // which sets an initial search value, to get around autofocus blocking
        if (this.initialPeopleSearchString) {
          this.autoComplete.openPanel();
        }
        if (searchValue.length < this.minChars) {
          return of({ type: 'hint', hint: this.minCharText } as LookupResult);
        } else if (this.includesEmail(this.alreadyInvited, searchValue)) {
          return of({ type: 'hint', hint: 'PEOPLE.INVITE.ALREADY_INVITED' } as LookupResult);
        } else {
          return this.searchPeopleFromSacc(searchValue);
        }
      })
    );
  }

  public handleItemClicked(item: Person) {
    if (isEqual(Object.keys(item), ['email'])) {
      this.emailAdded.emit(item.email);
    } else {
      this.itemSelected.emit(item);
    }
    this.resetInput();
  }

  public isValidEmail(email: string) {
    return ValidatorUtil.isEmailValid(email);
  }

  public trackByFn(index: number, item: Person) {
    return item ? item.id : index;
  }

  private filterOutSelectedOrInvitedPersons(allContributors: Person[] = []): Person[] {
    if (allContributors.length === 0) {
      return [];
    }
    const selectedPeopleEmails = this.selectedPeople.map(({ email }) => email);
    const emailToExclude = [...this.alreadyInvited, ...selectedPeopleEmails];
    return allContributors.filter((person: Person) => !this.includesEmail(emailToExclude, person.email));
  }

  private resetInput() {
    requestAnimationFrame(() => {
      this.autoComplete.formControl.reset();
    });
  }

  private includesEmail(emails: string[], targetEmail: string): boolean {
    const targetEmailLowerCase = targetEmail.toLowerCase();
    return emails.map(email => email.toLowerCase()).includes(targetEmailLowerCase);
  }

  private searchPeopleFromSacc(searchValue: string): Observable<LookupResult> {
    return this.memberService.searchPeopleFromSacc(this.teamspaceId, searchValue, this.statusesToDisplay).pipe(
      catchError(error => {
        console.error(error);
        return EMPTY;
      }),
      map((result: { persons: Person[]; paginationResult: PaginationResult }) => result?.persons ?? []),
      map((persons: Person[]) => this.filterOutSelectedOrInvitedPersons(persons)),
      map((persons: Person[]) => this.mapPersonToList(searchValue, persons))
    );
  }

  private mapPersonToList(searchValue: string, persons: Person[]): LookupResult {
    if (persons.length > 0) {
      if (this.isValidEmail(searchValue)) {
        const person = persons.find(p => p.email === searchValue);
        if (!person) {
          persons.push({ email: searchValue } as Person);
        }
      }
      return { type: 'person-suggestion', persons };
    }

    if (!this.isValidEmail(searchValue) || this.organisationMembersOnly) {
      // The no elem text should be, no results found, please enter a valid email if you want to add somebody new
      return {
        type: 'hint',
        hint: this.organisationMembersOnly ? 'PEOPLE.INVITE.ORGANISATION_MEMBERS_ONLY' : this.noElementFoundText
      };
    } else {
      return { type: 'person-suggestion', persons: [{ email: searchValue } as Person] };
    }
  }
}
