import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

import { IconConfiguration } from '@celum/common-components';
import { SharedModule } from '@celum/work/app/shared';

@Component({
  selector: 'custom-forms-context-menu',
  templateUrl: './custom-forms-context-menu.component.html',
  styleUrls: ['./custom-forms-context-menu.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports: [SharedModule]
})
export class CustomFormsContextMenuComponent {
  @Input() public formId: number;

  @Output() public readonly copyAsNewDialogOpened: EventEmitter<void> = new EventEmitter<void>();
  @Output() public readonly deleteDialogOpened: EventEmitter<void> = new EventEmitter<void>();

  public readonly menuIcon = IconConfiguration.large('option-m');
  public readonly copyIcon = IconConfiguration.medium('duplicate-s');
  public readonly deleteIcon = IconConfiguration.medium('remove-m');
}
