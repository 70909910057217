import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';

import { ColorConstants, IconConfiguration } from '@celum/common-components';
import { ColorService } from '@celum/work/app/shared/util';

@Component({
  selector: 'content-hub-menu-item',
  templateUrl: './content-hub-menu-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: false
})
export class ContentHubMenuItemComponent {
  @Input() public title: string;
  @Input() public dataOperationId: string;
  @Input() public disabled = false;

  private _icon: IconConfiguration;

  public get icon(): IconConfiguration {
    const disabledColor = ColorService.rgbToRGBA(ColorService.hexToRGB(ColorConstants.BLUE_GRAY_900), 0.36);
    return this.disabled ? this._icon.withColor(disabledColor) : this._icon.withColor(ColorConstants.BLUE_GRAY_900);
  }

  @Input() public set icon(icon: IconConfiguration) {
    this._icon = icon;
  }
}
