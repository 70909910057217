import { Component, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { CelumDialog, CelumDialogConfiguration, ColorConstants, IconConfiguration } from '@celum/common-components';
import { BrowserUtil, ReactiveComponent } from '@celum/ng2base';

@Component({
  selector: 'download-drive-dialog',
  templateUrl: './download-drive-dialog.html',
  styleUrls: ['./download-drive-dialog.less'],
  encapsulation: ViewEncapsulation.None,
  standalone: false
})
export class DownloadDriveDialog extends ReactiveComponent implements CelumDialog<DownloadDriveDialogConfiguration> {
  public color: string = ColorConstants.PRIMARY;
  public syncingIcon = new IconConfiguration('drive-download').withIconSize(165);
  public configuration: DownloadDriveDialogConfiguration;

  constructor(public dialogRef: MatDialogRef<DownloadDriveDialog>) {
    super();
  }

  public configure(configuration: DownloadDriveDialogConfiguration): void {
    this.configuration = configuration;
  }

  public download(): void {
    BrowserUtil.openUrl(this.configuration.downloadUrl + this.configuration.channel, true);
    this.dialogRef.close();
  }
}

export class DownloadDriveDialogConfiguration extends CelumDialogConfiguration {
  constructor(
    public downloadUrl: string,
    public channel: string
  ) {
    super(DownloadDriveDialogConfiguration.name);
    this.color = ColorConstants.PRIMARY;
  }
}
