import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { fromEvent, Observable } from 'rxjs';
import { debounceTime, take, takeUntil } from 'rxjs/operators';

import { ReactiveComponent } from '@celum/ng2base';
import { LanguageInitializationService } from '@celum/shared/util';
import { LocalStorageService } from '@celum/work/app/core';
import { RouterNavigationHelperService } from '@celum/work/app/core/router-navigation-helper.service';
import { PortraitRestrictorComponent } from '@celum/work/app/shared/components/portrait-restrictor/portrait-restrictor.component';

import { SystembarService } from './core/systembar.service';

@Component({
  selector: 'workroom-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class AppComponent extends ReactiveComponent implements OnInit, AfterViewInit {
  public systemBarCollapsed = true;
  public pending$: Observable<boolean>;

  protected systembarConfiguration$ = this.systembar.getConfiguration();

  private overlayRef: OverlayRef;

  constructor(
    private storageService: LocalStorageService,
    private systembar: SystembarService,
    private overlay: Overlay,
    private deviceDetectorService: DeviceDetectorService,
    private routerNavigationHelperService: RouterNavigationHelperService,
    private languageInitializationService: LanguageInitializationService
  ) {
    super();
    this.overlayRef = this.overlay.create({
      hasBackdrop: true,
      backdropClass: 'portrait-restrictor_backdrop',
      panelClass: 'portrait-restrictor_panel'
    });
    this.overlayRef.backdropClick().pipe(take(1)).subscribe();
    // Needed for the shared systembar
    this.languageInitializationService.init();
  }

  public ngOnInit(): void {
    this.storageService.testLocalStorage();
    this.pending$ = this.routerNavigationHelperService.navigationPending$;
  }

  public ngAfterViewInit(): void {
    this.checkMobileTabletOrientation();
  }

  private checkMobileTabletOrientation() {
    if (!(this.deviceDetectorService.isMobile() || this.deviceDetectorService.isTablet())) {
      return;
    }

    const checkOrientation = () => {
      if (window.innerHeight > window.innerWidth) {
        if (!this.overlayRef.hasAttached()) {
          const componentPortal = new ComponentPortal(PortraitRestrictorComponent);
          this.overlayRef.attach(componentPortal);
        }
      } else {
        this.overlayRef.detach();
      }
    };

    checkOrientation();
    fromEvent(window, 'resize').pipe(takeUntil(this.unsubscribe$), debounceTime(500)).subscribe(checkOrientation);
  }
}
