import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { isEmpty } from 'lodash';
import { EMPTY, of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, take } from 'rxjs/operators';

import {
  CollaborationItemsContext,
  CollaborationPropertiesLoad
} from '@celum/work/app/collaboration-properties/store/collaboration-properties.actions';
import { ImportProgressTaskParams } from '@celum/work/app/content-hub/model/import.model';
import { ContentHubImportActions } from '@celum/work/app/content-hub/store/import.actions';
import { FailureHandler } from '@celum/work/app/core/error/failure-handler.service';
import { ItemContext } from '@celum/work/app/core/model/entities/activity/activity.model';
import { ContentItemStatus } from '@celum/work/app/core/model/entities/content-item/content-item.model';
import {
  FileFetchLinkedPortalAssetIds,
  FileFetchLinkedPortalAssetIdsSucceeded,
  FileFetchTaskCounts,
  FileFetchTaskCountsFailed,
  FileFetchTaskCountsSucceeded,
  FileUpdate,
  FileUpdateFailed,
  FileUpdateSucceeded
} from '@celum/work/app/core/model/entities/file/file.actions';
import { FileType } from '@celum/work/app/core/model/entities/file/file.model';
import { ContentItemContentItemsLoaded } from '@celum/work/app/files/store/content-item-list/content-item-list.actions';
import { UploadProgressTaskParams } from '@celum/work/app/files/upload/model/upload.model';
import { UploadActions } from '@celum/work/app/files/upload/store/upload.actions';
import { selectCurrentWorkroomId } from '@celum/work/app/pages/workroom/store/workroom-wrapper.selectors';
import { selectProgressTaskById } from '@celum/work/app/progress-task/store/progress-task.selectors';

import { FileService } from './file.service';
import { TaskService } from '../task/task.service';

@Injectable()
export class FileEffects {
  public updateFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FileUpdate),
      mergeMap(action =>
        this.fileService.updateFile(action.file, action.propertiesToUpdate).pipe(
          map(file =>
            FileUpdateSucceeded({
              file,
              updatedProperties: action.propertiesToUpdate
            })
          ),
          catchError(err => {
            this.failureHandler.handleError(err);
            return of(FileUpdateFailed({ file: action.file }));
          })
        )
      )
    )
  );

  public refreshFileOnNewVersionAdded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContentHubImportActions.ImportVersionSuccess, UploadActions.UploadConfirmed),
      mergeMap(({ progressTaskId }) =>
        this.store
          .select(selectProgressTaskById<UploadProgressTaskParams | ImportProgressTaskParams, any>(progressTaskId))
          .pipe(take(1))
      ),
      concatLatestFrom(() => this.store.select(selectCurrentWorkroomId)),
      filter(
        ([{ params }, workroomId]) =>
          params.workroom.id === workroomId && (this.isImportProgressTaskParams(params) || params.isNewVersion)
      ),
      mergeMap(([{ params }, _]) =>
        this.fileService.getFile(this.isImportProgressTaskParams(params) ? params.fileId : params.contentItemId).pipe(
          map(({ id }) =>
            CollaborationPropertiesLoad({
              offset: 0,
              parentId: id,
              objectIdsInContext: {
                include: [
                  {
                    objectIds: [id],
                    context: ItemContext.CONTENT_ITEM
                  }
                ]
              },
              collaborationItemsCtx: CollaborationItemsContext.FILE_DETAIL
            })
          ),
          catchError(error => {
            this.failureHandler.handleError(error);
            return EMPTY;
          })
        )
      )
    )
  );

  public onContentItemLoaded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContentItemContentItemsLoaded),
      filter(({ contentItems }) => !isEmpty(contentItems)),
      map(({ contentItems }) =>
        contentItems.filter(
          contentItem =>
            contentItem.entityType.id === FileType.TYPE_KEY && contentItem.status === ContentItemStatus.NOT_DELETED
        )
      ),
      mergeMap(notDeletedFiles => [
        FileFetchTaskCounts({ fileIds: notDeletedFiles.map(file => file.id) }),
        FileFetchLinkedPortalAssetIds({ fileIds: notDeletedFiles.map(file => file.id) })
      ])
    )
  );

  public fetchFileTaskCounts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FileFetchTaskCounts),
      filter(({ fileIds }) => !isEmpty(fileIds)),
      concatLatestFrom(() => this.store.select(selectCurrentWorkroomId)),
      switchMap(([{ fileIds }, workroomId]) =>
        this.taskService.getFileTaskCounts(workroomId, fileIds).pipe(
          map(fileTaskCounts =>
            FileFetchTaskCountsSucceeded({
              fileTaskCounts
            })
          ),
          catchError(() => {
            return of(FileFetchTaskCountsFailed({ fileIds }));
          })
        )
      )
    )
  );

  public fetchLinkedPortalAssetIds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FileFetchLinkedPortalAssetIds),
      filter(({ fileIds }) => !isEmpty(fileIds)),
      concatLatestFrom(() => this.store.select(selectCurrentWorkroomId)),
      switchMap(([{ fileIds }, workroomId]) =>
        this.taskService.getLinkedPortalAssetIds(workroomId, fileIds).pipe(
          map(linkedPortalAssetIds =>
            FileFetchLinkedPortalAssetIdsSucceeded({
              linkedPortalAssetIds
            })
          ),
          catchError(err => {
            this.failureHandler.handleError(err);
            return EMPTY;
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private fileService: FileService,
    private taskService: TaskService,
    private failureHandler: FailureHandler,
    private store: Store<any>
  ) {}

  public isImportProgressTaskParams(
    params: UploadProgressTaskParams | ImportProgressTaskParams
  ): params is ImportProgressTaskParams {
    return params instanceof ImportProgressTaskParams;
  }
}
