import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewEncapsulation } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogRef } from '@angular/material/dialog';
import { differenceBy } from 'lodash';
import { Observable } from 'rxjs';

import {
  CelumDialog,
  CelumDialogConfiguration,
  CelumDialogModule,
  CelumIconModule,
  ColorConstants
} from '@celum/common-components';
import {
  CustomField,
  CustomFieldDropdownOption,
  CustomFieldTypes
} from '@celum/work/app/core/model/entities/custom-field/custom-field.model';
import { SharedModule } from '@celum/work/app/shared';
import { StringUtil } from '@celum/work/app/shared/util/string.util';
import {
  BaseCustomFieldDialogComponent,
  BaseCustomFieldDialogResponse
} from '@celum/work/app/teamspace-management/components/fields-overview-tab/components/base-custom-field-dialog/base-custom-field-dialog.component';

import { CustomFieldErrorKeyEnum } from '../../model/custom-field-error-response';
import { CustomFieldsService } from '../../services/custom-fields.service';
import {
  CustomFieldCardComponent,
  CustomFieldCardControlValue
} from '../custom-field-card/custom-field-card.component';

@Component({
  selector: 'edit-custom-field-dialog',
  imports: [
    CommonModule,
    CelumDialogModule,
    CelumIconModule,
    MatButtonModule,
    CustomFieldCardComponent,
    ReactiveFormsModule,
    SharedModule
  ],
  templateUrl: './edit-custom-field-dialog.component.html',
  styleUrls: [
    '../base-custom-field-dialog/base-custom-field-dialog.component.less',
    './edit-custom-field-dialog.component.less'
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditCustomFieldDialogComponent
  extends BaseCustomFieldDialogComponent
  implements CelumDialog<EditCustomFieldDialogConfiguration>
{
  public static readonly DIALOG_ID = 'edit-custom-field-dialog';

  public editedField: CustomField;
  public fieldInUseWarning: string | null = null;

  constructor(
    dialogRef: MatDialogRef<BaseCustomFieldDialogComponent, BaseCustomFieldDialogResponse>,
    private customFieldService: CustomFieldsService,
    cdr: ChangeDetectorRef,
    private stringUtil: StringUtil
  ) {
    super(dialogRef, cdr);
  }

  public configure({ color, customField }: EditCustomFieldDialogConfiguration): void {
    this.color = color;
    this.editedField = customField;

    if (this.editedField.formsUsageCounter > 0) {
      this.fieldInUseWarning =
        this.editedField.formsUsageCounter > 1
          ? 'TEAMSPACE_MANAGEMENT.FIELDS.CREATE_FIELD_DIALOG.WARNIG_FIELD_IN_USE_MULTIPLE_FORMS'
          : 'TEAMSPACE_MANAGEMENT.FIELDS.CREATE_FIELD_DIALOG.WARNIG_FIELD_IN_USE_SINGLE_FORM';
    }

    this.customFieldCard = new FormControl<CustomFieldCardControlValue>({
      name: this.editedField.name,
      type: this.editedField.type,
      chips: this.editedField.dropdownOptions ?? []
    });
  }

  public onSubmit(): void {
    const { name, type, chips } = this.customFieldCard.value;
    const trimmedName = this.stringUtil.collapseWhitespacesBetweenWords(name.trim());

    this.loading = true;

    const submitEndpointCall = this.editCustomField(
      this.editedField.id,
      trimmedName,
      type,
      this.editedField.dropdownOptions,
      chips
    );
    this.submitHandler(submitEndpointCall);
  }

  private editCustomField(
    id: number,
    name: string,
    type: CustomFieldTypes,
    existingDropdownOptions: CustomFieldDropdownOption[],
    updatedChips: CustomFieldDropdownOption[]
  ): Observable<CustomField> {
    if (type === CustomFieldTypes.DROPDOWN) {
      const removedIds = differenceBy(existingDropdownOptions, updatedChips, 'id').map(option => option.id);
      const addedOptions = updatedChips.filter(option => option.id === -1).map(option => ({ name: option.name }));

      return this.customFieldService.editCustomField(id, name, type, removedIds, addedOptions);
    }

    return this.customFieldService.editCustomField(id, name, type);
  }
}

export class EditCustomFieldDialogConfiguration extends CelumDialogConfiguration {
  public color = ColorConstants.PRIMARY;

  constructor(public customField: CustomField) {
    super('main');
  }
}

export interface EditCustomFieldDialogResponse {
  customField?: CustomField;
  errorKey?: CustomFieldErrorKeyEnum;
}
