import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { NgxPermissionsService } from 'ngx-permissions';
import { EMPTY, firstValueFrom, forkJoin, of, pipe } from 'rxjs';
import {
  catchError,
  exhaustMap,
  first,
  map,
  mergeMap,
  startWith,
  switchMap,
  switchMapTo,
  take,
  tap
} from 'rxjs/operators';

import { PermissionService } from '@celum/work/app/core/api/permission';
import { FailureHandler } from '@celum/work/app/core/error/failure-handler.service';
import { ContentItemTypes } from '@celum/work/app/core/model/entities/content-item/content-item.model';
import { ContributorsDeleteOne } from '@celum/work/app/core/model/entities/contributor';
import {
  selectCurrentWorkroomIdParam,
  Workroom,
  WorkroomStatus,
  WorkroomsUpsertOne
} from '@celum/work/app/core/model/entities/workroom';
import { selectLoggedInPerson } from '@celum/work/app/core/ui-state/ui-state.selectors';
import {
  selectCurrentWorkroom,
  selectLoggedInPersonContributorForWorkroom
} from '@celum/work/app/pages/workroom/store/workroom-wrapper.selectors';
import { PermissionUtil } from '@celum/work/app/shared/util/permission-util';

import {
  WorkroomCreateWorkroom,
  WorkroomCreateWorkroomFailed,
  WorkroomCreateWorkroomSucceeded,
  WorkroomDeleteWorkroom,
  WorkroomDeleteWorkroomFailed,
  WorkroomDeleteWorkroomSucceeded,
  WorkroomFinishWorkroom,
  WorkroomFinishWorkroomFailed,
  WorkroomFinishWorkroomSucceeded,
  WorkroomLoadWorkroomDriveSubscription,
  WorkroomLoadWorkroomPermissions,
  WorkroomRefreshCurrentWorkroom,
  WorkroomReopenWorkroom,
  WorkroomReopenWorkroomFailed,
  WorkroomReopenWorkroomSucceeded,
  WorkroomsUpdateContentItemCount,
  WorkroomUpdateWorkroomName
} from './workroom.actions';
import { WorkroomService } from './workroom.service';
import { Roles } from '../../model';
import { FolderUpsertOne } from '../../model/entities/folder/folder.actions';
import { SubscriptionService } from '../subscription/subscription.service';

@Injectable()
export class WorkroomEffects {
  public createNewWorkroom$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkroomCreateWorkroom),
      exhaustMap(action =>
        this.workroomService.createWorkroomFromTemplate(action.data).pipe(this.handleWorkroomCreation())
      )
    )
  );

  public updateWorkroom = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkroomUpdateWorkroomName),
      mergeMap(action => {
        let stream$ = this.workroomService.updateWorkroom(action.newName, action.workroom).pipe(
          switchMap(() => EMPTY),
          catchError(err => {
            this.failureHandler.handleError(err);
            // reset name change...
            return of(
              WorkroomsUpsertOne({ workroom: action.workroom }),
              FolderUpsertOne({
                folder: action.folder
              })
            );
          })
        );

        const workroomsUpsertOne = WorkroomsUpsertOne({
          workroom: {
            ...action.workroom,
            name: action.newName
          }
        });
        stream$ = stream$.pipe(
          startWith(
            workroomsUpsertOne,
            FolderUpsertOne({
              folder: {
                ...action.folder,
                name: action.newName
              }
            })
          )
        );

        return stream$;
      })
    )
  );

  public finishWorkroom = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkroomFinishWorkroom),
      mergeMap(action => {
        let stream$ = this.workroomService.finishWorkroom(action.workroom.id, action.keepContent).pipe(
          concatLatestFrom(workroom => this.store.select(selectLoggedInPersonContributorForWorkroom(workroom.id))),
          switchMap(([workroom, contributor]) => {
            if (!!contributor && !contributor.roles.includes(Roles.MODERATOR)) {
              return of(ContributorsDeleteOne({ contributor }), WorkroomFinishWorkroomSucceeded({ workroom }));
            }

            return of(WorkroomFinishWorkroomSucceeded({ workroom }));
          }),
          catchError(err => {
            this.failureHandler.handleError(err);
            if (action.optimistic) {
              return of(
                WorkroomsUpsertOne({ workroom: action.workroom }),
                WorkroomFinishWorkroomFailed({ workroom: action.workroom })
              );
            } else {
              return of(WorkroomFinishWorkroomFailed({ workroom: action.workroom }));
            }
          })
        );
        if (action.optimistic) {
          stream$ = stream$.pipe(
            startWith(
              WorkroomsUpsertOne({
                workroom: {
                  ...action.workroom,
                  status: WorkroomStatus.INACTIVE,
                  lastActivity: Date.now()
                }
              })
            )
          );
        }
        return stream$;
      })
    )
  );

  public deleteWorkroom = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkroomDeleteWorkroom),
      mergeMap(action =>
        this.workroomService.deleteWorkroom(action.workroom.id).pipe(
          map(() => WorkroomDeleteWorkroomSucceeded({ workroom: action.workroom })),
          catchError(err => {
            this.failureHandler.handleError(err);
            // reset status change...
            return of(WorkroomDeleteWorkroomFailed({ workroom: action.workroom }));
          })
        )
      )
    )
  );

  public reopenWorkroom = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkroomReopenWorkroom),
      mergeMap(action =>
        this.workroomService.reopenWorkroom(action.workroom.id).pipe(
          switchMap(() => this.store.select(selectLoggedInPerson).pipe(take(1))),
          switchMap(loggedInPerson =>
            this.permissionUtil.hasWorkroomRole(Roles.CONTRIBUTOR, loggedInPerson, action.workroom).pipe(take(1))
          ),
          switchMap(isContributor => {
            if (isContributor && action.canRedirect) {
              this.router.navigate(['workroom', action.workroom.id]);
            }
            return of(WorkroomReopenWorkroomSucceeded({ workroom: action.workroom }));
          }),
          catchError(err => {
            this.failureHandler.handleError(err);
            // reset status change...
            return of(WorkroomReopenWorkroomFailed({ workroom: action.workroom }));
          })
        )
      )
    )
  );

  public loadWorkroomPermissions = createEffect(
    () =>
      this.actions$.pipe(
        ofType(WorkroomLoadWorkroomPermissions),
        mergeMap(action =>
          this.permissionService.loadWorkroomPermissions(action.workroomId).pipe(
            tap(permissions => {
              permissions.permissions.forEach(perm => {
                this.ngxPermissionService.addPermission(perm, permissionName => {
                  return firstValueFrom(
                    this.store.select(selectCurrentWorkroom).pipe(
                      take(1),
                      map(
                        workroom =>
                          workroom.id === action.workroomId && workroom.permissions.permissions.includes(permissionName)
                      )
                    )
                  );
                });
              });
            }),
            catchError(err => {
              this.failureHandler.handleError(err);
              return EMPTY;
            })
          )
        )
      ),
    { dispatch: false }
  );

  public loadWorkroomDriveSubscription = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkroomLoadWorkroomDriveSubscription),
      concatLatestFrom(() => this.store.select(selectLoggedInPerson)),
      mergeMap(([action, loggedInPerson]) =>
        forkJoin({
          subscription: this.subscriptionService.getLoggedInPersonDriveSubscription(action.workroom, [
            loggedInPerson.username
          ]),
          itemCount: this.subscriptionService.countContentItemsByLibrary(
            action.workroom.libraryId,
            ContentItemTypes.FILE
          )
        }).pipe(
          map(({ itemCount }) => WorkroomsUpdateContentItemCount({ workroomId: action.workroom.id, count: itemCount })),
          catchError(() => of(null))
        )
      )
    )
  );

  public refreshCurrentWorkroom$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(WorkroomRefreshCurrentWorkroom),
        switchMapTo(this.store.select(selectCurrentWorkroomIdParam).pipe(first(wr => !!wr))),
        switchMap(workroomId => this.workroomService.loadWorkroomById(workroomId))
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private workroomService: WorkroomService,
    private failureHandler: FailureHandler,
    private router: Router,
    private permissionService: PermissionService,
    private ngxPermissionService: NgxPermissionsService,
    private permissionUtil: PermissionUtil,
    private subscriptionService: SubscriptionService
  ) {}

  private handleWorkroomCreation() {
    return pipe(
      map((workroom: Workroom) => WorkroomCreateWorkroomSucceeded({ workroom })),
      catchError(_ => {
        return of(WorkroomCreateWorkroomFailed());
      })
    );
  }
}
