import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { CelumDialog, CelumDialogConfiguration, ColorConstants, IconConfiguration } from '@celum/common-components';

import { Folder } from '../../../core/model/entities/folder/folder.model';
import { ContentHubBrowserService } from '../../services/content-hub-browser.service';
import { BaseContentHubDialog } from '../base-content-hub-dialog';

@Component({
  selector: 'select-node-dialog',
  templateUrl: './select-node-dialog.component.html',
  styleUrls: ['./select-node-dialog.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [ContentHubBrowserService],
  standalone: false
})
export class SelectNodeDialog
  extends BaseContentHubDialog
  implements OnInit, CelumDialog<SelectNodeDialogConfiguration>
{
  public color: string;
  public title: string;
  public submitButtonText: string;
  public submitIcon: IconConfiguration;

  constructor(
    private dialogRef: MatDialogRef<SelectNodeDialog>,
    protected browseService: ContentHubBrowserService
  ) {
    super(browseService);
  }

  public ngOnInit(): void {
    this.paginationInformation$ = this.getPaginationInformation();
    this.children$ = this.browseService.allFolders$;
    this.collections$ = this.browseService.collections$;

    // Since we are not using content-item-list here but need assetPageSize for aggregation call, we are using just a dummy value
    this.browseService.assetPageSize = 1;

    this.initData();
  }

  public configure(configuration: SelectNodeDialogConfiguration): void {
    this.color = configuration.color;
    this.title = configuration.title;
    this.submitButtonText = configuration.submitButtonText;
    this.submitIcon = configuration.submitIcon.withColor('#FFF');
    this.repositoryId = configuration.repositoryId;
  }

  public onSubmit(): void {
    this.dialogRef.close(this.activeFolder$.getValue());
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public selectedFolderChanged(folder: Folder): void {
    if (folder.validationLevel === 'STRICT') {
      return;
    }
    this.activeFolder$.next(folder);
  }

  public isStrict(folder: Folder): boolean {
    return folder.validationLevel === 'STRICT';
  }
}

export class SelectNodeDialogConfiguration extends CelumDialogConfiguration {
  public color = ColorConstants.PRIMARY;

  constructor(
    public title: string,
    public submitButtonText: string,
    public submitIcon: IconConfiguration,
    public repositoryId?: string
  ) {
    super(SelectNodeDialogConfiguration.name);
  }
}
