import { Action, createReducer, on } from '@ngrx/store';
import { produce } from 'immer';
import { cloneDeep } from 'lodash';

import { ContentHubImportActions } from '@celum/work/app/content-hub/store/import.actions';
import { ConfigurationKey } from '@celum/work/app/core/model/configuration.model';
import { Subscription } from '@celum/work/app/core/model/entities/subscription/subscription.model';
import {
  initialPaginatedListState,
  initialSimpleListState,
  PaginatedListState,
  SimpleListState
} from '@celum/work/app/core/model/list.model';

import * as WorkroomCreatorActions from './workroom-creator.actions';

export interface WorkroomCreatorState extends SimpleListState, PaginatedListState {
  isWorkroomCreated: boolean;
  initiating: boolean;
  subscription: Subscription;
  importIntentRepositoryId: string;
  importIntentCollectionName: string;
  defaultConfigs: Partial<Record<ConfigurationKey.DEFAULT_WORKROOM | ConfigurationKey.DEFAULT_WORKROOM_TEMPLATE, any>>;
}

export const initialState: WorkroomCreatorState = {
  isWorkroomCreated: false,
  initiating: false,
  ...initialSimpleListState,
  ...initialPaginatedListState,
  subscription: null,
  importIntentRepositoryId: null,
  importIntentCollectionName: null,
  defaultConfigs: {}
};

const reducer = createReducer(
  initialState,
  on(WorkroomCreatorActions.WorkroomCreatorInit, state => ({ ...state, initiating: true, isWorkroomCreated: false })),
  on(WorkroomCreatorActions.WorkroomCreatorInited, state => ({ ...state, initiating: false })),
  on(WorkroomCreatorActions.WorkroomCreatorWorkroomCreated, state => ({
    ...state,
    isWorkroomCreated: true,
    subscription: null,
    importIntentRepositoryId: null
  })),
  on(WorkroomCreatorActions.WorkroomCreatorFetchNextTemplateBatch, state => ({ ...state, loading: true })),
  on(WorkroomCreatorActions.WorkroomCreatorFetchTemplateById, state => ({ ...state, loading: true })),
  on(WorkroomCreatorActions.WorkroomCreatorRefreshTemplates, state => ({ ...state, loading: true })),
  on(WorkroomCreatorActions.WorkroomCreatorTemplatesLoaded, (state, { templates, paginationResult }) => ({
    ...state,
    loading: false,
    paginationResult,
    entityIds: templates.map(({ id }) => id),
    lastOffset: templates.length
  })),
  on(WorkroomCreatorActions.WorkroomCreatorNextTemplateBatchFetched, (state, { templates, paginationResult }) => ({
    ...state,
    loading: false,
    paginationResult,
    entityIds: [...state.entityIds, ...templates.map(({ id }) => id)],
    lastOffset: [...state.entityIds, ...templates].length
  })),
  on(WorkroomCreatorActions.WorkroomCreatorTemplateLoaded, (state, { template }) => ({
    ...state,
    loading: false,
    entityIds: [...state.entityIds, template.id]
  })),
  on(WorkroomCreatorActions.WorkroomCreatorTemplateCreated, (state, action) => ({
    ...state,
    entityIds: [...state.entityIds, action.id],
    paginationResult: {
      ...state.paginationResult,
      totalElementCount: state.paginationResult.totalElementCount + 1
    }
  })),
  on(WorkroomCreatorActions.WorkroomCreatorTemplateDelete, state => ({
    ...state,
    paginationResult: {
      ...state.paginationResult,
      totalElementCount: state.paginationResult.totalElementCount - 1
    }
  })),
  on(WorkroomCreatorActions.WorkroomCreatorLoadDefaultConfigSuccess, (state, { configKey, config }) =>
    produce(state, draft => {
      draft.defaultConfigs[configKey] = config;
    })
  ),
  on(WorkroomCreatorActions.WorkroomCreatorResetState, ({ defaultConfigs }) =>
    cloneDeep({ ...initialState, defaultConfigs })
  ),
  on(WorkroomCreatorActions.WorkroomCreatorSetImportIntentRepositoryId, (state, { importIntentRepositoryId }) => ({
    ...state,
    importIntentRepositoryId
  })),
  on(WorkroomCreatorActions.WorkroomCreatorSetImportIntentCollectionName, (state, { importIntentCollectionName }) => ({
    ...state,
    importIntentCollectionName
  })),
  on(
    WorkroomCreatorActions.WorkroomCreatorResetImportIntentInformation,
    ContentHubImportActions.ImportIntentConsume,
    state => ({ ...state, importIntentRepositoryId: null, importIntentCollectionName: null })
  )
);

export function workroomCreatorReducer(state: WorkroomCreatorState | undefined, action: Action) {
  return reducer(state, action);
}
