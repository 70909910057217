import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';

import { ColorConstants, IconConfiguration } from '@celum/common-components';
import { ContentHubExportActions } from '@celum/work/app/content-hub/store/export.actions';
import { hasAnyActiveContentHubConnections } from '@celum/work/app/core/model/entities/content-item/content-item-util';
import { ColorService } from '@celum/work/app/shared/util';

import { File } from '../../../core/model/entities/file/file.model';

@Component({
  selector: 'ch-export-menu',
  templateUrl: './export-menu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: false
})
export class ContentHubExportMenuComponent {
  @Input() public files: File[];

  public readonly exportIcon = IconConfiguration.small('export').withColor(ColorConstants.BLUE_GRAY_900);
  public readonly exportAsNewVersionOfAnotherAssetIcon = IconConfiguration.small(
    'export-as-new-version-of-another-asset'
  ).withColor(ColorConstants.BLUE_GRAY_900);
  public readonly infoIcon = IconConfiguration.small('info').withColor(ColorConstants.BLUE_GRAY_900);

  constructor(private store: Store<any>) {}

  public get isVisibleExportAsNewVersionsOfCopiedAssets(): boolean {
    return !this.files.some(file => !file.importId);
  }

  public get isEnabledExportAsNewVersionsOfCopiedAssets(): boolean {
    return this.files.every(file => hasAnyActiveContentHubConnections(file));
  }

  public get exportAsNewVersionOfCopiedAssetIcon(): IconConfiguration {
    const disabledColor = ColorService.rgbToRGBA(ColorService.hexToRGB(ColorConstants.BLUE_GRAY_900), 0.36);

    return this.isEnabledExportAsNewVersionsOfCopiedAssets
      ? IconConfiguration.small('export-as-new-version-of-copied-asset').withColor(ColorConstants.BLUE_GRAY_900)
      : IconConfiguration.small('export-as-new-version-of-copied-asset').withColor(disabledColor);
  }

  public exportAsNewAssets(): void {
    this.store.dispatch(ContentHubExportActions.RequestExportOfFilesAsNewAssets({ files: this.files }));
  }

  public exportAsNewVersionOfAnotherAsset(): void {
    this.store.dispatch(ContentHubExportActions.RequestExportOfFileAsNewVersionOfAnotherAsset({ file: this.files[0] }));
  }

  public exportAsNewVersionsOfCopiedAssets(): void {
    if (!this.isEnabledExportAsNewVersionsOfCopiedAssets) {
      return;
    }
    this.store.dispatch(ContentHubExportActions.RequestExportOfFilesAsNewVersionsOfCopiedAssets({ files: this.files }));
  }
}
