import { Action, createReducer, on } from '@ngrx/store';
import { produce } from 'immer';

import { StackImpl } from '@celum/work/app/shared/util/stack';

import {
  UiStateAccountAccessesLoaded,
  UiStateChangeLanguage,
  UiStatePushViewContext,
  UiStateRemoveCurrentContext,
  UiStateRemoveSpecificContext,
  UiStateSetCurrentContextUpdateTimestamp,
  UiStateSetLanguage,
  UiStateSetLoggedInPerson,
  UiStateTenantSelected
} from './ui-state.actions';
import { Language, UiStateState } from './ui-state.model';

export const initialState: UiStateState = {
  tenant: null,
  language: Language.ENGLISH,
  loggedInPersonId: null,
  accountAccesses: null,
  viewContext: new StackImpl(),
  viewContextLastUpdateTimestamp: {}
};

const reducer = createReducer(
  initialState,
  on(UiStateTenantSelected, (state: UiStateState, { accountId }) =>
    produce(state, draft => {
      draft.tenant = accountId;
    })
  ),

  on(UiStateChangeLanguage, UiStateSetLanguage, (state: UiStateState, { language }) =>
    produce(state, draft => {
      draft.language = language;
    })
  ),

  on(UiStateSetLoggedInPerson, (state: UiStateState, { personId }) => ({
    ...state,
    loggedInPersonId: personId
  })),

  on(UiStateAccountAccessesLoaded, (state: UiStateState, { accountAccesses }) => ({
    ...state,
    accountAccesses: accountAccesses
  })),

  on(UiStatePushViewContext, (state: UiStateState, { context, clearExisting }) =>
    produce(state, draft => {
      if (clearExisting) {
        draft.viewContext = new StackImpl();
        draft.viewContextLastUpdateTimestamp = {};
      }

      if (draft.viewContext.peek()) {
        draft.viewContextLastUpdateTimestamp[draft.viewContext.peek()] = Date.now();
      }

      draft.viewContext.push(context);
    })
  ),

  on(UiStateRemoveCurrentContext, (state: UiStateState, _) =>
    produce(state, draft => {
      draft.viewContext.pop();
    })
  ),

  on(UiStateRemoveSpecificContext, (state: UiStateState, { specificContext }) =>
    produce(state, draft => {
      if (draft.viewContext.peek() === specificContext) {
        draft.viewContext.pop();
      }
    })
  ),

  on(UiStateSetCurrentContextUpdateTimestamp, (state: UiStateState, { timestamp }) =>
    produce(state, draft => {
      const currentContext = state.viewContext.peek();
      draft.viewContextLastUpdateTimestamp[currentContext] = timestamp;
    })
  )
);

export function uiStateReducer(state: UiStateState = initialState, action: Action): UiStateState {
  return reducer(state, action);
}
