import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';

import {
  CelumDialog,
  CelumDialogConfiguration,
  CelumDialogModule,
  CelumInputsModule,
  ColorConstants
} from '@celum/common-components';
import { TaskList } from '@celum/work/app/core/model/entities/task';

@Component({
  selector: 'convert-subtask-to-task-dialog',
  templateUrl: './convert-subtask-to-task-dialog.component.html',
  styleUrls: ['./convert-subtask-to-task-dialog.component.less'],
  imports: [CommonModule, CelumDialogModule, CelumInputsModule, MatSelectModule, ReactiveFormsModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ConvertSubtaskToTaskDialogComponent implements CelumDialog<ConvertSubtaskToTaskDialogConfiguration> {
  public color: string;
  public taskLists: TaskList[];

  public isLoading = false;
  public taskListFormControl = new FormControl<number>(null, [Validators.required]);

  constructor(private dialogRef: MatDialogRef<ConvertSubtaskToTaskDialogComponent, ConvertSubtaskToTaskResult>) {}

  public configure(configuration: ConvertSubtaskToTaskDialogConfiguration): void {
    this.color = configuration.color;
    this.taskLists = configuration.params.taskLists;

    this.taskListFormControl.setValue(this.taskLists[0]?.id ?? null);
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public trackByFn(index: number, item: TaskList) {
    return item.id || index;
  }

  public convertToTask(): void {
    this.dialogRef.close({ taskListId: this.taskListFormControl.value });
  }
}

export class ConvertSubtaskToTaskDialogConfiguration extends CelumDialogConfiguration {
  public color = ColorConstants.PRIMARY;

  constructor(public params: ConvertSubtaskToTaskDialogConfigurationParams) {
    super('main');
  }
}

export interface ConvertSubtaskToTaskDialogConfigurationParams {
  taskLists: TaskList[];
}

export interface ConvertSubtaskToTaskResult {
  taskListId: number;
}
