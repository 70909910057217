import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';

import { AVATAR_SIZE } from '@celum/common-components';
import { ReactiveComponent } from '@celum/ng2base';
import { Roles } from '@celum/work/app/core/model';
import { InvitationStatus, MembershipStatus } from '@celum/work/app/core/model/entities/member';
import { Person } from '@celum/work/app/core/model/entities/person';
import { Workroom } from '@celum/work/app/core/model/entities/workroom';
import { selectCurrentWorkroom } from '@celum/work/app/pages/workroom/store/workroom-wrapper.selectors';
import { SelectionTriggerWrapperComponent } from '@celum/work/app/shared/components/selection-trigger/selection-trigger-wrapper.component';
import { WorkroomAvatarConfiguration } from '@celum/work/app/shared/components/workroom-avatar/workroom-avatar-configuration';
import { AvatarUtil, Size } from '@celum/work/app/shared/util';

import { PeopleCardShowLabelPipe } from './people-card-show-label.pipe';
import { WorkroomAvatar } from '../../../../shared/components/workroom-avatar/workroom-avatar.component';
import { PersonLabelComponent } from '../person-label/person-label.component';

@Component({
  selector: 'people-card-item',
  templateUrl: './people-card.component.html',
  styleUrls: ['./people-card.component.less'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    WorkroomAvatar,
    PersonLabelComponent,
    PeopleCardShowLabelPipe,
    SelectionTriggerWrapperComponent
  ]
})
export class PeopleCardComponent extends ReactiveComponent implements OnInit, OnChanges {
  @Input() public entity: Person;
  @Input() public size: Extract<Size, 'medium' | 'large'>;

  @Input() public roles: Roles[];
  @Input() public status: MembershipStatus;
  @Input() public invitationStatus: InvitationStatus;

  @ViewChild('peopleRow', { static: true })
  public peopleRow: ElementRef;

  public workroom: Workroom;
  public isDeactivated: boolean;

  public avatar$: Observable<WorkroomAvatarConfiguration>;

  constructor(
    private store: Store<any>,
    private avatarUtil: AvatarUtil
  ) {
    super();
  }

  public get avatarSize(): AVATAR_SIZE {
    return this.size === 'medium' ? AVATAR_SIZE.xl : AVATAR_SIZE.xxxl;
  }

  public get isEmptyName(): boolean {
    return !this.entity.firstName && !this.entity.lastName;
  }

  public get getUserName(): string {
    if (this.isEmptyName) {
      return this.entity.email;
    }
    return `${this.entity.firstName} ${this.entity.lastName}`;
  }

  public ngOnInit() {
    this.store
      .pipe(select(selectCurrentWorkroom), distinctUntilChanged(), takeUntil(this.unsubscribe$))
      .subscribe((workroom: Workroom) => {
        this.workroom = workroom;

        setTimeout(() => {
          this.handleMembershipActivity();
        });
      });
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.roles || (changes.entity.currentValue.avatarUrl && !changes.entity.previousValue.avatarUrl)) {
      this.avatar$ = this.avatarConfiguration$();
    }

    this.handleMembershipActivity();
  }

  private handleMembershipActivity(): void {
    this.isDeactivated = this.status === MembershipStatus.INACTIVE;

    const element = this.peopleRow.nativeElement.closest('.people-item');

    if (!element) {
      return;
    }

    const active = this.status === MembershipStatus.ACTIVE;
    if (active) {
      element.classList.remove('people-item--inactive');
    } else {
      element.classList.add('people-item--inactive');
    }
  }

  private avatarConfiguration$(): Observable<WorkroomAvatarConfiguration> {
    return this.avatarUtil
      .getAvatarConfigWithImageForCurrentTeamspace({
        person: this.entity,
        size: this.avatarSize,
        interactive: this.status !== MembershipStatus.INACTIVE
      })
      .pipe(
        map(avatarConfig => {
          avatarConfig.email = this.entity.email;

          return this.avatarUtil.handleRoleBadge(avatarConfig, this.roles);
        })
      );
  }
}
