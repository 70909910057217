import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { map, tap } from 'rxjs/operators';

import {
  SubtaskConvertToTaskFailed,
  SubtaskCreateSubtask,
  SubtaskCreateSubtaskFailed,
  SubtaskDeleteSubtask,
  SubtaskDeleteSubtaskFailed,
  SubtaskLoadSubtasks
} from '@celum/work/app/core/api/subtask/subtask.actions';
import { selectTaskById } from '@celum/work/app/core/model/entities/task/task.selectors';
import { UserSettingsService } from '@celum/work/app/core/user-settings.service';
import { getDisplayedItemsCount } from '@celum/work/app/pages/workroom/pages/tasks/pages/task-detail/utils/get-displayed-items';
import { WorkroomConstants } from '@celum/work/app/shared/util';

import {
  SubtaskStateChangeDisplayedSubtasksCount,
  SubtaskStateChangeDisplayedSubtasksCountByOne,
  SubtaskStateSetSubtasksPanelExpandedState
} from './subtask-state.actions';
import { SUBTASKS_BATCH_SIZE } from './subtask-state.reducer';

@Injectable()
export class SubtaskStateEffects {
  public changeLoadingSubtasksCountOnSubtasksLoad = createEffect(() =>
    this.actions$.pipe(
      ofType(SubtaskLoadSubtasks),
      concatLatestFrom(action => this.store.select(selectTaskById(action.taskId))),
      map(([action, task]) =>
        SubtaskStateChangeDisplayedSubtasksCount({
          count: getDisplayedItemsCount(
            task.subtaskInformation?.numberOfTotalSubtasks ?? 0,
            action.offset,
            SUBTASKS_BATCH_SIZE
          )
        })
      )
    )
  );

  public changeLoadingSubtasksCountOnSubtaskAdd = createEffect(() =>
    this.actions$.pipe(
      ofType(SubtaskCreateSubtask, SubtaskDeleteSubtaskFailed, SubtaskConvertToTaskFailed),
      map(() => SubtaskStateChangeDisplayedSubtasksCountByOne({ delta: 1 }))
    )
  );

  public changeLoadingSubtasksCountOnSubtaskDeleteAndConvert = createEffect(() =>
    this.actions$.pipe(
      ofType(SubtaskDeleteSubtask, SubtaskCreateSubtaskFailed, SubtaskDeleteSubtask),
      map(() => SubtaskStateChangeDisplayedSubtasksCountByOne({ delta: -1 }))
    )
  );

  public setSubtasksPanelExpandedState = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SubtaskStateSetSubtasksPanelExpandedState),
        tap(({ expanded }) =>
          this.userSettingsService.setApplicationSettings(
            WorkroomConstants.LOCAL_STORAGE_IS_SUBTASK_SECTION_OPENED,
            expanded
          )
        )
      ),
    { dispatch: false }
  );

  constructor(
    private store: Store,
    private actions$: Actions,
    private userSettingsService: UserSettingsService
  ) {}
}
