import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { selectWorkroomById } from '@celum/work/app/core/model/entities/workroom';
import { selectCurrentWorkroom } from '@celum/work/app/pages/workroom/store/workroom-wrapper.selectors';
import { DownloadUrlUtil } from '@celum/work/app/shared/util/download-url-util';

@Pipe({
  name: 'slibResourceToken',
  standalone: false
})
export class SlibResourceTokenPipe implements PipeTransform {
  constructor(private store: Store<any>) {}

  public transform(downloadUrl: string, workroomId?: number, slibResourceToken?: string): Observable<string> {
    const workroom$ = workroomId
      ? this.store.select(selectWorkroomById(workroomId))
      : this.store.select(selectCurrentWorkroom);

    return workroom$.pipe(
      take(1),
      map(currentWorkroom => DownloadUrlUtil.resolveDownloadUrl(downloadUrl, currentWorkroom, slibResourceToken))
    );
  }
}
