import { ChangeDetectionStrategy, Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';

import { IconConfiguration } from '@celum/common-components';
import { ProgressTaskStatus } from '@celum/work/app/progress-task/store/progress-task.model';
import { SnackbarStateButtonConfig } from '@celum/work/app/shared/components/snackbar-group/config/snackbar-state-button.config';

@Component({
  selector: 'snackbar-group-item',
  templateUrl: './snackbar-group-item.component.html',
  styleUrls: ['./snackbar-group-item.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: false
})
export class SnackbarGroupItemComponent {
  @HostBinding('class.snackbar-group-item') public hostCls = true;

  @Input() public error: string;
  @Input() public icon: IconConfiguration;
  @Input() public title: string;
  @Input() public titleArgs: object;
  @Input() public iconBackgroundColor: string;
  @Input() public status: ProgressTaskStatus;
  @Input() public buttonStateConfigs: SnackbarStateButtonConfig[];

  public getFilteredButtonStateConfigs(): SnackbarStateButtonConfig[] {
    if (!this.status && !this.buttonStateConfigs) {
      return [];
    }

    return this.buttonStateConfigs.filter(config => config.applicableWhenState.includes(this.status));
  }

  public trackByFn(_: number, item: SnackbarStateButtonConfig): string {
    return item.button.identifier;
  }
}
