import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnInit,
  SecurityContext,
  ViewEncapsulation
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';

import { CelumDialog, CelumDialogConfiguration, ColorConstants, IconConfiguration } from '@celum/common-components';
import { DomHelper, ReactiveComponent } from '@celum/ng2base';
import {
  WorkroomFinishWorkroom,
  WorkroomFinishWorkroomFailed,
  WorkroomFinishWorkroomSucceeded
} from '@celum/work/app/core/api/workroom';

import { NotificationService } from '../../../core';
import { Workroom } from '../../../core/model/entities/workroom';
import { ColorService } from '../../util/color.service';

@Component({
  selector: 'finish-workroom-dialog',
  templateUrl: './finish-workroom-dialog.component.html',
  styleUrls: ['./finish-workroom-dialog.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: false
})
export class FinishWorkroomDialog
  extends ReactiveComponent
  implements CelumDialog<FinishWorkroomDialogConfiguration>, OnInit, AfterViewInit
{
  public color: string;

  public headerText: SafeHtml;
  public openTasksText: SafeHtml;
  public personHintText: SafeHtml;
  public accessHintText: SafeHtml;
  public unsavedHintText: SafeHtml;
  public reopenHintText: SafeHtml;
  public openTasks = 0;

  public disableRipple = true;
  public loading = false;
  public keepContent = false;

  public mailHint: IconConfiguration = IconConfiguration.xLarge('mail', '', 56);
  public accessHint: IconConfiguration = IconConfiguration.xLarge('wr-lock', '', 56);
  public fileHint: IconConfiguration = IconConfiguration.xLarge('save-icon', '', 56);
  public taskHint: IconConfiguration = IconConfiguration.large('attention-l', '').withColor(
    ColorConstants.SYSTEM_BLACK
  );
  public infoHint: IconConfiguration;

  public workroom: Workroom;
  private escapedName = '';
  private optimistic: boolean;

  constructor(
    private dialogRef: MatDialogRef<FinishWorkroomDialog>,
    private translate: TranslateService,
    private sanitizer: DomSanitizer,
    private notificationService: NotificationService,
    private actions$: Actions,
    private store: Store<any>
  ) {
    super();
  }

  public ngOnInit() {
    this.createDialogContent();
  }

  public ngAfterViewInit() {
    setTimeout(() => {
      // dirty fix to avoid "ripple circle" around checkbox on dialog opening
      // => happens because of the autofocus created my the cdk focus trap of the dialog
      this.disableRipple = false;
    }, 500);
  }

  public configure(config: FinishWorkroomDialogConfiguration): void {
    this.workroom = config.workroom;
    this.color = config.color;
    this.optimistic = config.optimistic;
  }

  public finishWorkroom() {
    if (!this.workroom) {
      this.notificationService.error(this.translate.instant('FINISH_WORKROOM.FAILED'));
      this.dialogRef.close();
      return;
    }

    this.loading = true;

    if (this.optimistic) {
      this.dialogRef.close();
    } else {
      this.actions$
        .pipe(ofType(WorkroomFinishWorkroomSucceeded, WorkroomFinishWorkroomFailed))
        .pipe(take(1))
        .subscribe(() => {
          this.loading = false;
          this.dialogRef.close();
        });
    }

    this.store.dispatch(
      WorkroomFinishWorkroom({
        workroom: this.workroom,
        keepContent: this.keepContent,
        optimistic: this.optimistic
      })
    );
  }

  public cancel() {
    this.dialogRef.close();
  }

  private createDialogContent() {
    this.escapedName = DomHelper.escapeHtml(this.workroom.name, false);

    this.headerText = this.sanitizer.sanitize(
      SecurityContext.HTML,
      this.translate.instant('FINISH_WORKROOM.TITLE', { title: this.escapedName })
    );
    this.personHintText = this.sanitizer.sanitize(
      SecurityContext.HTML,
      this.translate.instant('FINISH_WORKROOM.PERSON_HINT')
    );
    this.accessHintText = this.sanitizer.sanitize(
      SecurityContext.HTML,
      this.translate.instant('FINISH_WORKROOM.ACCESS_HINT')
    );
    this.unsavedHintText = this.sanitizer.sanitize(
      SecurityContext.HTML,
      this.translate.instant('FINISH_WORKROOM.UNSAVED_HINT')
    );
    this.reopenHintText = this.sanitizer.sanitize(
      SecurityContext.HTML,
      this.translate.instant('FINISH_WORKROOM.REOPEN_HINT')
    );
    this.infoHint = IconConfiguration.xLarge('key', '', 56).withColor(ColorConstants.SYSTEM_BLACK);
  }
}

export class FinishWorkroomDialogConfiguration extends CelumDialogConfiguration {
  constructor(
    public workroom: Workroom,
    public optimistic: boolean
  ) {
    super('main');

    this.color = ColorService.getColorAsRgbString(workroom.color);
  }
}
