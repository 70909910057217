import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';

import { CustomForm } from '@celum/work/app/core/model/entities/custom-form/custom-form.model';
import { VirtualScrollTableService } from '@celum/work/app/shared/components/virtual-scroll-table/virtual-scroll-table.base';
import { VirtualScrollTableComponent } from '@celum/work/app/shared/components/virtual-scroll-table/virtual-scroll-table.component';

import { CustomFormsTableRowComponent } from './custom-forms-table-row/custom-forms-table-row.component';
import { CustomFormsVirtualScrollTableService } from './custom-forms-virtual-scroll-table.service';
import { CustomFormsStore } from '../../forms-overview-tab.store';

@Component({
  selector: 'custom-forms-virtual-scroll-table',
  imports: [CommonModule, VirtualScrollTableComponent, CustomFormsTableRowComponent],
  templateUrl: './custom-forms-virtual-scroll-table.component.html',
  styleUrls: ['./custom-forms-virtual-scroll-table.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  viewProviders: [
    {
      provide: VirtualScrollTableService,
      useFactory: (customFormsStore: CustomFormsStore, store: Store<any>) =>
        new CustomFormsVirtualScrollTableService(customFormsStore, store),
      deps: [CustomFormsStore, Store<any>]
    }
  ]
})
export class CustomFormsVirtualScrollTableComponent {
  @HostBinding('class.custom-forms-virtual-scroll-table') public hostCls = true;

  @Input() public hasWorkroomsFinishPermission: boolean;

  constructor(private customFormsStore: CustomFormsStore) {}

  public openEditFormDialog(formId: number): void {
    this.customFormsStore.openViewCustomFormDialog$(formId);
  }

  public openCopyAsNewDialog(formId: number): void {
    this.customFormsStore.openCopyAsNewCustomFormDialog$(formId);
  }

  public openDeleteDialog(customForm: CustomForm): void {
    this.customFormsStore.openDeleteCustomFormDialog$(customForm);
  }
}
