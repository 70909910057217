import { Injectable } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import {
  addMinutes,
  addSeconds,
  addWeeks,
  differenceInSeconds,
  format,
  formatDistanceToNow,
  isBefore,
  isSameDay,
  isSameWeek,
  isTomorrow,
  startOfDay
} from 'date-fns';
import { de, enUS, fr } from 'date-fns/locale';

import { Language } from '@celum/work/app/core/ui-state/ui-state.model';

export enum DueDateStatus {
  NotSet = 'NotSet',
  IsPast = 'IsPast',
  IsToday = 'IsToday',
  IsFuture = 'IsFuture'
}

export enum DateFormats {
  SHORT = 'short',
  LONG = 'long',
  COMMENT = 'comment',
  VERSION = 'version',
  MONTH_YEAR = 'monthYear',
  SEARCH_RESULT = 'searchResult'
}

@Injectable({ providedIn: 'root' })
export class DateUtil {
  public static LANGUAGE_FORMATS: Record<Language, Record<DateFormats, string>> = {
    [Language.ENGLISH]: {
      [DateFormats.SHORT]: 'MMM dd',
      [DateFormats.LONG]: 'MMM dd, yyyy',
      [DateFormats.COMMENT]: 'MMMM d, yyyy h:mm aa',
      [DateFormats.VERSION]: 'MMMM d, yyyy',
      [DateFormats.MONTH_YEAR]: 'MMM yyyy',
      [DateFormats.SEARCH_RESULT]: 'MM-dd-yyyy'
    },
    [Language.GERMAN]: {
      [DateFormats.SHORT]: 'dd. MMM',
      [DateFormats.LONG]: 'dd. MMM yyyy',
      [DateFormats.COMMENT]: 'd. MMMM yyyy H:mm',
      [DateFormats.VERSION]: 'd. MMMM yyyy',
      [DateFormats.MONTH_YEAR]: 'MMM yyyy',
      [DateFormats.SEARCH_RESULT]: 'dd.MM.yyyy'
    },
    [Language.FRENCH]: {
      [DateFormats.SHORT]: 'dd MMM',
      [DateFormats.LONG]: 'dd MMM yyyy',
      [DateFormats.COMMENT]: 'd MMMM yyyy HH:mm',
      [DateFormats.VERSION]: 'd MMMM yyyy',
      [DateFormats.MONTH_YEAR]: 'MMM yyyy',
      [DateFormats.SEARCH_RESULT]: 'dd-MM-yyyy'
    }
  };

  private language: string;

  private shortDateFormat: string = DateUtil.LANGUAGE_FORMATS.en.short;
  private longDateFormat: string = DateUtil.LANGUAGE_FORMATS.en.long;

  constructor(private translateService: TranslateService) {
    this.language = this.translateService.currentLang;
    this.updateLanguage();
    translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.language = event.lang;
      this.updateLanguage();
    });
  }

  public static isToday(dueDate: number): boolean {
    return isSameDay(new Date(), new Date(dueDate));
  }

  public static isTomorrow(dueDate: number): boolean {
    return isTomorrow(new Date(dueDate));
  }

  public static isInPast(dueDate: number): boolean {
    return isBefore(new Date(dueDate), startOfDay(new Date()));
  }

  public static isThisWeek(dueDate: number, locale: Locale): boolean {
    return isSameWeek(new Date(), new Date(dueDate), { locale });
  }

  public static isNextWeek(dueDate: number, locale: Locale): boolean {
    return isSameWeek(addWeeks(new Date(), 1), new Date(dueDate), { locale });
  }

  public static addSecondsAndGetTime(date: Date, seconds: number): number {
    return addSeconds(date, seconds).getTime();
  }

  public static formatForFileName(date: Date, formatStr: string): string {
    return format(date, formatStr);
  }

  public static getDifferenceInSeconds(date1: number, date2: number): number {
    return differenceInSeconds(new Date(date1), new Date(date2));
  }

  public static adjustDateWithTimezone(date: Date, timezoneOffset: number): number {
    const adjustedDate = addMinutes(date, timezoneOffset - date.getTimezoneOffset());
    return adjustedDate.getTime();
  }

  public static toUtcStartOfDay(date: Date): number {
    if (!date) {
      return null;
    }
    const startOfDayLocal = startOfDay(date);
    const utcDate = new Date(
      Date.UTC(startOfDayLocal.getFullYear(), startOfDayLocal.getMonth(), startOfDayLocal.getDate())
    );
    return utcDate.getTime();
  }

  /**
   * Converts a UTC start of day timestamp to a local start of day date.
   * If the given timestamp in UTC is NOT a start of day timestamp (aka 00:00h), the result will be incorrect!
   */
  public static toLocalDateStartOfDay(utcStartOfDay: number): Date {
    const date = new Date(utcStartOfDay);
    return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
  }

  public format(date: number, formatStr: string): string {
    return format(new Date(date), formatStr, { locale: this.getLocale() });
  }

  public getFormatString(dateFormat: DateFormats): string {
    const languageFormats = DateUtil.LANGUAGE_FORMATS[this.language];
    return languageFormats[dateFormat];
  }

  public getFormattedDate(dateAsNumber: number): string {
    if (dateAsNumber) {
      return format(new Date(dateAsNumber), this.shortDateFormat, { locale: this.getLocale() });
    }
    return null;
  }

  public getFormattedFullDate(dateAsNumber: number): string {
    if (dateAsNumber) {
      return format(new Date(dateAsNumber), this.longDateFormat, { locale: this.getLocale() });
    }
    return null;
  }

  public fromNow(dateAsNumber: number): string {
    return formatDistanceToNow(new Date(dateAsNumber), {
      addSuffix: true,
      locale: this.getLocale()
    });
  }

  public getDueDateStatus(dueDate: number | undefined): DueDateStatus {
    if (!dueDate) {
      return DueDateStatus.NotSet;
    } else if (DateUtil.isToday(dueDate)) {
      return DueDateStatus.IsToday;
    } else if (DateUtil.isInPast(dueDate)) {
      return DueDateStatus.IsPast;
    } else {
      return DueDateStatus.IsFuture;
    }
  }

  public getLocale() {
    const localeMap = {
      en: enUS,
      de: de,
      fr: fr
    };

    return localeMap[this.language] || enUS;
  }

  private updateLanguage(toLanguage = this.language) {
    this.shortDateFormat = DateUtil.LANGUAGE_FORMATS[toLanguage].short;
    this.longDateFormat = DateUtil.LANGUAGE_FORMATS[toLanguage].long;
  }
}
