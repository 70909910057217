import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, pluck, switchMap } from 'rxjs/operators';

import { Permission } from '@celum/work/app/core/api/permission';
import { selectPermissionsForTeamspace } from '@celum/work/app/core/model/entities/teamspace';
import { PredefinedCategoryValues } from '@celum/work/app/core/model/entities/template-category/template-category.model';
import {
  selectCategoryById,
  selectCelumTemplateCategory,
  selectHasPermissionInAnyTeamspace
} from '@celum/work/app/core/model/entities/template-category/template-category.selectors';

@Pipe({
  name: 'hasTemplateCategoryPermission',
  pure: true,
  standalone: false
})
export class HasTemplateCategoryPermissionPipe implements PipeTransform {
  constructor(private store: Store<any>) {}

  public transform(templateCategoryId: any, permission: Permission): Observable<boolean> {
    // In person tab, enable creating WR from template only if user has WORKROOM_CREATE permission in some teamspace
    if (
      PredefinedCategoryValues.PERSONAL.toString() === templateCategoryId &&
      permission === Permission.WORKROOM_CREATE
    ) {
      return this.store.select(selectHasPermissionInAnyTeamspace(permission));
    }

    if (
      PredefinedCategoryValues.PERSONAL.toString() === templateCategoryId &&
      (permission === Permission.TEMPLATE_DELETE || permission === Permission.TEMPLATE_UPDATE)
    ) {
      return of(true);
    }

    return this.store.select(selectCelumTemplateCategory).pipe(
      switchMap(celumTemplateCategory => {
        // In celum tab, enable creating WR from template only if user has WORKROOM_CREATE permission in some teamspace
        if (
          celumTemplateCategory &&
          String(celumTemplateCategory.id) === String(templateCategoryId) &&
          permission === Permission.WORKROOM_CREATE
        ) {
          return this.store.select(selectHasPermissionInAnyTeamspace(permission));
        } else {
          // For others tab, check permission for specific teamspace
          return this.store.select(selectCategoryById(templateCategoryId)).pipe(
            filter(category => !!category),
            switchMap(({ teamspaceId }) =>
              this.store
                .select(selectPermissionsForTeamspace(teamspaceId))
                .pipe(filter(permissionItem => !!permissionItem))
            ),
            pluck('permissions'),
            map(perms => perms.includes(permission)),
            catchError(() => of(false))
          );
        }
      })
    );
  }
}
