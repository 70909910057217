import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { isEqual } from 'lodash';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { CelumDialog, CelumDialogConfiguration, ColorConstants } from '@celum/common-components';

import { Person } from '../../../../../../../../core/model/entities/person';
import { Task } from '../../../../../../../../core/model/entities/task';
import { selectCurrentWorkroomContributingPersons } from '../../../../../../store/workroom-wrapper.selectors';

@Component({
  selector: 'move-tasks-dialog',
  templateUrl: './move-tasks-dialog.component.html',
  styleUrls: ['./move-tasks-dialog.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class MoveTasksDialogComponent implements CelumDialog<MoveTasksDialogConfiguration> {
  public color: string;
  public assignees: Person[] = [];
  public headline = 'MOVE_TASK_DIALOG.HEADLINE.';
  public withInitialAssignments: boolean;

  constructor(
    private store: Store<any>,
    private dialogRef: MatDialogRef<MoveTasksDialogComponent>
  ) {}

  public get assignmentChanged$(): Observable<boolean> {
    return this.store.select(selectCurrentWorkroomContributingPersons).pipe(
      map(contributors => {
        const allContributorsAlreadyAssigned = isEqual(contributors, this.assignees);
        const hasOneAssignmentAtLeast = this.assignees.length > 0;
        return allContributorsAlreadyAssigned || hasOneAssignmentAtLeast;
      })
    );
  }

  public get description(): string {
    const prefix = this.withInitialAssignments ? 'WITH_INITIAL_ASSIGNMENTS' : 'NO_INITIAL_ASSIGNMENTS';
    return `MOVE_TASK_DIALOG.MANDATORY_ASSIGNMENT.DESCRIPTION.${prefix}`;
  }

  public configure(configuration: MoveTasksDialogConfiguration): void {
    this.color = configuration.color;
    this.headline += configuration.tasks.length > 1 ? 'PLURAL' : 'SINGULAR';
    this.setAssignees(configuration.tasks);
  }

  public unassignPerson(person: Person): void {
    this.assignees = this.assignees.filter(({ id }) => person.id !== id);
  }

  public assignPerson(person: Person): void {
    this.assignees = [...this.assignees, person];
  }

  public confirm(): void {
    this.dialogRef.close(this.assignees);
  }

  private setAssignees(tasks: Task[]): void {
    const movingMultipleTasks = tasks.length > 1;

    if (movingMultipleTasks) {
      return;
    }

    tasks[0]
      .assignees(this.store)
      .pipe(take(1))
      .subscribe(assignees => {
        this.assignees = assignees;
        this.withInitialAssignments = assignees.length > 0;
      });
  }
}

export class MoveTasksDialogConfiguration extends CelumDialogConfiguration {
  public color: string = ColorConstants.PRIMARY;

  constructor(public tasks: Task[]) {
    super('main');
  }
}
