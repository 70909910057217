import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  CelumListModule,
  CelumSimpleList,
  ContextMenuPosition,
  ListSelectionHandler,
  SelectionBehavior
} from '@celum/internal-components';
import { ReactiveComponent } from '@celum/ng2base';
import { Member } from '@celum/work/app/core/model/entities/member';
import { selectMemberByUserOidAndTeamspaceId } from '@celum/work/app/core/model/entities/member/member.selectors';
import { Person } from '@celum/work/app/core/model/entities/person';
import { PersonListItemService } from '@celum/work/app/person/people-list/services/person-list-item.service';
import { SharedModule } from '@celum/work/app/shared';
import { Size } from '@celum/work/app/shared/util';
import { PersonWithStatuses } from '@celum/work/app/workroom-wizard/services/workroom-wizard-people.service';

import { PeopleCardComponent } from './components/people-card/people-card.component';

export interface OpenPeopleContextMenuEvent {
  position: ContextMenuPosition;
  selection: any[];
}

@Component({
  selector: 'people-list',
  templateUrl: './people-list.component.html',
  styleUrls: ['./people-list.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports: [CommonModule, CelumListModule, PeopleCardComponent, SharedModule]
})
export class PeopleListComponent extends ReactiveComponent implements OnInit {
  @Input() public teamspaceId: number;
  @Input() public people: PersonWithStatuses[];
  @Input() public hasMoreBottom: boolean;
  @Input() public size: Extract<Size, 'medium' | 'large'> = 'large';
  @Input() public isMultiSelection: boolean;

  @Output() public readonly openContextMenu = new EventEmitter<OpenPeopleContextMenuEvent>();
  @Output() public readonly selectionChanged = new EventEmitter<Person[]>();
  @Output() public readonly itemClick = new EventEmitter<Person>();
  @Output() public readonly requestNextBatch = new EventEmitter<void>();

  @ViewChild(CelumSimpleList) public celumSimpleList: CelumSimpleList;
  public listSelectionHandler: ListSelectionHandler<Person> = new ListSelectionHandler<Person>();

  constructor(
    protected peopleListItemService: PersonListItemService<any>,
    private store: Store<any>
  ) {
    super();
  }

  public ngOnInit(): void {
    this.listSelectionHandler.setSelectionBehavior(SelectionBehavior.MULTI_TOGGLE);
    this.listSelectionHandler.selectionChanged$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(selection => this.selectionChanged.emit(selection));
  }

  public trackByFn(_: number, item: Person) {
    return item ? item.id : null;
  }

  public onOpenContextMenu(position: ContextMenuPosition) {
    this.openContextMenu.emit({
      position,
      selection: this.listSelectionHandler.getCurrentSelection()
    });
  }

  public onSelectionChanged({ selection }: { selection: Person[] }) {
    this.selectionChanged.emit(selection);
  }

  public getMembership(person: Person): Observable<Member> {
    return this.store.select(selectMemberByUserOidAndTeamspaceId(person.oid, this.teamspaceId));
  }

  public getRoles(person: Person) {
    return this.peopleListItemService.getRoles(person);
  }

  public onRequestNextPage() {
    this.requestNextBatch.emit();
  }
}
