import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';

import { CelumButtonModule } from '../button';
import { CelumIconModule } from '../icon';
import { InlineTextarea } from './component/inline-textarea';

@NgModule({
            imports: [
              CommonModule, MatButtonModule, MatTooltipModule, CelumButtonModule, CelumIconModule, FormsModule
            ],
            declarations: [
              InlineTextarea
            ],
            exports: [
              CelumIconModule, InlineTextarea
            ]
          })
export class InlineTextareaModule {
}
