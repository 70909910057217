import { ChangeDetectorRef, Directive, ElementRef, EventEmitter, HostBinding, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';

import { ResizeSlider } from './resize-slider';

@Directive({
             selector: '[resize]',
             standalone: false
           })
export class ResizeDirective implements OnChanges, OnDestroy {

  @Input() public slider: HTMLElement | ResizeSlider;
  @Input() public orientation: 'horizontal-left' | 'horizontal-right' | 'vertical-top' | 'vertical-down' = 'horizontal-left';

  @Output() public readonly sliderActive = new EventEmitter<boolean>();

  @HostBinding('style.width') public width: string;
  @HostBinding('style.height') public height: string;

  private slideEl: HTMLElement;
  private subscription: Subscription;

  constructor(private element: ElementRef, private changeDetector: ChangeDetectorRef) {
  }

  public ngOnChanges({ slider, orientation }: SimpleChanges): void {
    const sliderChanged = slider && slider.currentValue !== slider.previousValue;
    const orientationChanged = orientation && orientation.currentValue !== orientation.previousValue;
    if (sliderChanged || orientationChanged) {
      this.initializeEventListener();
    }
  }

  public ngOnDestroy(): void {
    this.subscription && this.subscription.unsubscribe();
  }

  private initializeEventListener(): void {
    this.subscription && this.subscription.unsubscribe();

    this.slideEl = ResizeDirective.transformInputSlider(this.slider);

    if (!this.slideEl) {
      console.error('ResizeDirective can only be used on HTMLElement and components which implement the ResizeSlider interface');
      return;
    }

    const clientXorY = this.orientation === 'horizontal-left' || this.orientation === 'horizontal-right' ? 'clientX' : 'clientY';
    const offsetXorY = this.orientation === 'horizontal-left' || this.orientation === 'horizontal-right' ? 'offsetWidth' : 'offsetHeight';

    const down$ = fromEvent<MouseEvent>(this.slideEl, 'mousedown').pipe(tap(() => this.sliderActive.next(true)));
    const up$ = fromEvent<MouseEvent>(document, 'mouseup').pipe(tap(() => this.sliderActive.next(false)));
    const move$ = fromEvent<MouseEvent>(document, 'mousemove');

    const drag$ = down$.pipe(
      switchMap((start: MouseEvent) => {
        const startSize = this.element.nativeElement[offsetXorY];

        return move$.pipe(
          map((move: MouseEvent) => {
            if (this.orientation === 'horizontal-left' || this.orientation === 'vertical-top') {
              return move[clientXorY] - start[clientXorY];
            } else {
              return -(move[clientXorY] - start[clientXorY]);
            }
          }),
          tap(distance => this.updateView(startSize + distance)),
          takeUntil(up$)
        );
      })
    );

    this.subscription = drag$.subscribe();
  }

  private updateView(size: number): void {
    const heightOrWidth = this.orientation === 'horizontal-left' || this.orientation === 'horizontal-right' ? 'width' : 'height';
    this[heightOrWidth] = `${size}px`;
    this.changeDetector.markForCheck();
  }

  private static transformInputSlider(slider: HTMLElement | ResizeSlider): HTMLElement {
    if (!slider) {
      return null;
    } else if (slider instanceof HTMLElement) {
      return slider;
    } else if ('getHtmlElement' in slider) {
      return slider.getHtmlElement();
    } else {
      return null;
    }
  }
}
