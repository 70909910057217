import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { isEmpty } from 'lodash';
import { DragulaService } from 'ng2-dragula';
import { merge, Observable } from 'rxjs';
import { mapTo, takeUntil } from 'rxjs/operators';

import { ColorConstants, IconConfiguration } from '@celum/common-components';
import { isSmartphoneScreen, isTabletScreen } from '@celum/core';
import autoScroll from '@celum/dom-autoscroller';
import { ReactiveComponent } from '@celum/ng2base';
import { getTaskListPlaceholder, TaskList } from '@celum/work/app/core/model/entities/task';
import { PermissionCheckStrategy } from '@celum/work/app/pages/workroom-creator/services/permission-check-strategy';

@Component({
  selector: 'task-lists-board-base',
  templateUrl: './task-lists-board-base.component.html',
  styleUrls: ['./task-lists-board-base.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: false
})
export class TaskListsBoardBaseComponent extends ReactiveComponent implements OnInit, AfterViewInit, OnDestroy {
  public static readonly MIN_TASK_LIST: number = 1;

  @HostBinding('class.task-list-board') public hostCls = true;

  @Input() public taskLists: TaskList[];
  @Input() public hasPermissionToMoveTaskList = true;
  @Input() public permissionCheckStrategy: PermissionCheckStrategy;
  @Input() public taskListClasses: string[] = [];
  @Input() public dragNDropListBag: string;
  @Input() public showWorkroomRobots = true;

  @Output() public readonly createNewTaskList: EventEmitter<TaskList> = new EventEmitter();

  @ViewChild('taskBoardContent') public taskBoardContent: ElementRef;

  public addListIcon = IconConfiguration.large('add-m').withColor(ColorConstants.SYSTEM_BLACK_75);
  public dummyTaskList: TaskList;
  public columnCreating = false;

  public dragging$: Observable<boolean>;

  private mutationObserver: MutationObserver;
  private scroll: any;

  constructor(protected dragulaService: DragulaService) {
    super();
  }

  public ngOnInit() {
    // scroll new list into view... and immediately disconnect mutation observer afterwards
    this.mutationObserver = new MutationObserver(() => {
      this.mutationObserver.disconnect();
      const boardContent: HTMLElement = this.taskBoardContent.nativeElement;

      if (boardContent.scrollTo) {
        boardContent.scrollTo({ left: boardContent.scrollWidth });
      }
    });

    this.dragging$ = merge(
      this.dragulaService.drag().pipe(mapTo(true)),
      this.dragulaService.dragend().pipe(mapTo(false))
    ).pipe(takeUntil(this.unsubscribe$));
    this.dragging$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(dragging =>
        dragging ? document.body.classList.add('on-drag') : document.body.classList.remove('on-drag')
      );

    if (this.dragNDropListBag) {
      this.dragulaService
        .cloned()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(({ name, original }: { name: string; original: Element }) => {
          if (name !== this.dragNDropListBag) {
            return;
          }
          const topPos = original.getBoundingClientRect().top + window.scrollY - 12 + `px`;
          document.documentElement.style.setProperty(`--gu-mirror-top`, topPos);
        });
      this.dragulaService.destroy(this.dragNDropListBag);
      this.dragulaService.createGroup(this.dragNDropListBag, {
        direction: 'horizontal', // dma Prevents list bag from moving when dragging task from on list bag to another
        moves(el: HTMLElement, source: HTMLElement, target: HTMLElement) {
          const isAddButtonElement = el?.classList.contains('task-list-board_add-button');

          if (isSmartphoneScreen() || isTabletScreen() || isAddButtonElement) {
            return false;
          }
          const header: HTMLElement = el.querySelector('.task-list-header');
          return header.contains(target) && el.classList.contains('task-list--draggable');
        },
        invalid: () => !this.hasPermissionToMoveTaskList
      });
    }
  }

  public ngAfterViewInit(): void {
    // enable autoscrolling
    if (!isSmartphoneScreen() && !isTabletScreen()) {
      this.scroll = autoScroll([this.taskBoardContent.nativeElement], {
        margin: 60,
        maxSpeed: 20,
        scrollWhenOutside: true,
        autoScroll: function autoScrollFun() {
          // only scroll when the pointer is down (aka dragging is potentially ongoing)
          return this.down;
        }
      });
    }
  }

  public ngOnDestroy(): void {
    if (this.dragNDropListBag) {
      this.dragulaService.destroy(this.dragNDropListBag);
    }
    super.ngOnDestroy();

    this.scroll?.destroy();
  }

  public addList($event: MouseEvent): void {
    $event?.stopPropagation();
    // observe content for new list dummy to appear...
    this.mutationObserver.observe(this.taskBoardContent.nativeElement, { childList: true });
    this.columnCreating = true;
    this.dummyTaskList = getTaskListPlaceholder(this.taskLists[this.taskLists.length - 1].color);

    // We need to run CD for whole component tree so task list header is fully rendered and scrollWidth correct
    setTimeout(() => {
      this.taskBoardContent.nativeElement.scrollLeft = this.taskBoardContent.nativeElement.scrollWidth;
    }, 0);
  }

  public onCreateNewTaskList(name: string): void {
    this.columnCreating = false;

    if (!isEmpty(name)) {
      const taskList = {
        ...this.dummyTaskList,
        isPlaceholder: false
      };
      taskList.name = name;

      this.createNewTaskList.emit(taskList);
    }
  }

  public trackByFn(index: number, item: TaskList): number {
    return item.sort;
  }
}
