import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { merge as observableMerge } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

import { IconConfiguration } from '@celum/common-components';
import { EventHelper, InOrExcluded } from '@celum/core';
import { ReactiveComponent } from '@celum/ng2base';

@Component({
             selector: 'navigation-buttons',
             templateUrl: './navigation-buttons.html',
             styleUrls: ['./navigation-buttons.less'],
             changeDetection: ChangeDetectionStrategy.OnPush,
             encapsulation: ViewEncapsulation.None,
             standalone: false
           })
export class NavigationButtons extends ReactiveComponent implements OnInit, OnDestroy {

  @Input() public hasNext: boolean;
  @Input() public hasPrevious: boolean;
  @Input() public enabled: boolean;
  @Input() public swipeEnabled = true;
  @Input() public keyboardEnabled = true;
  // should the event only be processed if triggered on specific elements (include) or if it should be always processed except on some elements (exclude)
  @Input() public eventScopeSelectorsModifier?: InOrExcluded<string>;

  @Output() public readonly next = new EventEmitter<void>();
  @Output() public readonly previous = new EventEmitter<void>();

  @HostBinding('class.navigation-buttons') public hostCls = true;

  public nextIcon = IconConfiguration.medium('next-m', '').withColor('#ffffff');
  public prevIcon = IconConfiguration.medium('previous-m', '').withColor('#ffffff');

  public ngOnInit(): void {
    this.initializeNavigationHandling();
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  private initializeNavigationHandling(): void {
    const swipe$ = EventHelper.swipe(document.body).pipe(filter(() => this.swipeEnabled), map((distance: number) => distance > 0 ? 'previous' : 'next'));
    const keyListener$ = EventHelper.keyboardEvent([37, 39], { allowedOrExcludedSelector: this.eventScopeSelectorsModifier, disableModifierKeys: true }).pipe(
      filter(() => this.keyboardEnabled),
      map(code => code === 37 ? 'previous' : 'next'));

    observableMerge(swipe$, keyListener$).pipe(filter(() => this.enabled), takeUntil(this.unsubscribe$))
                                         .subscribe((dir: string) => dir === 'next' ? this.next.emit() : this.previous.emit());
  }

}
