import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'search-and-select-entry',
  templateUrl: './search-and-select-entry.component.html',
  styleUrls: ['./search-and-select-entry.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: false
})
export class SearchAndSelectEntryComponent {
  @Input() public dataElementId: string;
  @Input() public elementId: string;
  @Input() public transitionName: string;
  @Input() public selected: boolean;
  @Input() public showCheckbox: boolean;
  @Output() public readonly selectionChange: EventEmitter<boolean> = new EventEmitter<boolean>();
}
