import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { produce } from 'immer';

import { DataUtil } from '@celum/core';
import { EntityUtil } from '@celum/work/app/core/model';

import {
  TaskListsDeleteMany,
  TaskListsDeleteOne,
  TaskListsHandleOwnerId,
  TaskListsUpsertMany,
  TaskListsUpsertOne
} from './task-list.actions';
import { TaskList, TaskListState } from './task-list.model';

export const taskListAdapter: EntityAdapter<TaskList> = createEntityAdapter<TaskList>();

const initialState: TaskListState = taskListAdapter.getInitialState();

const properties = ['name', 'color', 'sort', 'dueDate', 'ownerIds'];

const reducer = createReducer(
  initialState,
  on(TaskListsUpsertOne, (state: TaskListState, { taskList }) => {
    const taskLists = EntityUtil.changedEntities(properties, [taskList], state.entities);
    if (!DataUtil.isEmpty(taskLists)) {
      return taskListAdapter.upsertOne(taskLists[0], state);
    } else {
      return state;
    }
  }),

  on(TaskListsHandleOwnerId, (state: TaskListState, { taskListId, ownerId, operation }) => {
    return produce(state, draft => {
      const taskList = draft.entities[taskListId];
      if (operation === 'add') {
        taskList.ownerIds.push(ownerId);
      } else {
        taskList.ownerIds = taskList.ownerIds.filter(id => id !== ownerId);
      }
    });
  }),

  on(TaskListsUpsertMany, (state: TaskListState, { taskLists }) => {
    const changedTaskLists = EntityUtil.changedEntities(properties, taskLists, state.entities);
    return taskListAdapter.upsertMany(changedTaskLists, state);
  }),

  on(TaskListsDeleteOne, (state: TaskListState, { id }) => {
    return taskListAdapter.removeOne(id, state);
  }),

  on(TaskListsDeleteMany, (state: TaskListState, { ids }) => {
    return taskListAdapter.removeMany(ids, state);
  })
);

export function taskListReducer(state: TaskListState | undefined, action: Action) {
  return reducer(state, action);
}
