import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, map, Observable } from 'rxjs';

import { CelumDialog, CelumDialogConfiguration, ColorConstants } from '@celum/common-components';
import { InvitationStatus } from '@celum/work/app/core/model/entities/member/member.model';
import { Person } from '@celum/work/app/core/model/entities/person/person.model';
import { Roles } from '@celum/work/app/core/model/roles.model';
import { UserRoleTuple } from '@celum/work/app/core/model/user-role-tuple.model';
import { selectRouterQueryParam } from '@celum/work/app/core/router/router.selectors';
import { WorkroomConstants } from '@celum/work/app/shared/util';

@Component({
  selector: 'update-user-role-dialog',
  templateUrl: './update-user-role-dialog.component.html',
  styleUrls: ['./update-user-role-dialog.component.less'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class UpdateUserRoleDialogComponent implements CelumDialog<UpdateUsersRoleDialogConfiguration> {
  public readonly personDataArray$: Observable<PersonData[]>;
  public color?: string;
  public headerText = 'PEOPLE.OPERATIONS.UPDATE_ROLE';
  public confirmBtnText = 'PEOPLE.UPDATE_ROLE.CONFIRM';
  public loading = false;
  public isDirty = false;
  public personDataArraySubj$: BehaviorSubject<PersonData[]> = new BehaviorSubject<PersonData[]>([]);
  public showDriveWarnings$: Observable<boolean>;

  constructor(
    public dialogRef: MatDialogRef<UpdateUserRoleDialogComponent, UserRoleTuple[]>,
    private store: Store
  ) {
    this.personDataArray$ = this.personDataArraySubj$.asObservable();
    this.showDriveWarnings$ = combineLatest([
      this.store.select(selectRouterQueryParam(WorkroomConstants.QUERY_PARAMS.TEMPLATE_CREATOR)),
      this.store.select(selectRouterQueryParam(WorkroomConstants.QUERY_PARAMS.TEMPLATE_MANAGER))
    ]).pipe(map(([templateCreator, templateManager]) => !templateCreator && !templateManager));
  }

  public configure(configuration: UpdateUsersRoleDialogConfiguration): void {
    this.personDataArraySubj$.next(configuration.selectedPeople);
  }

  public onOkClicked() {
    this.dialogRef.close(this.personDataArraySubj$.getValue().map(({ person, role }) => ({ person, role })));
  }

  public onRoleChanged(personIndex: number, role: Roles) {
    const persons = this.personDataArraySubj$.getValue();
    persons[personIndex].role = role;
    this.personDataArraySubj$.next(persons);
    this.isDirty = true;
  }

  public cancel(): void {
    this.dialogRef.close();
  }

  public trackByFn(index, item: PersonData) {
    return item.person.id || index;
  }
}

export class UpdateUsersRoleDialogConfiguration extends CelumDialogConfiguration {
  public color = ColorConstants.PRIMARY;
  public selectedPeople: PersonData[];

  constructor(public persons: PersonData[]) {
    super('main');
    this.selectedPeople = persons;
  }
}

export interface PersonData {
  person: Person;
  invitationStatus: InvitationStatus;
  role: Roles;
}
