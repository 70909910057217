import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';

import { ColorConstants, ICON_TEXT_TRANSFORM_NONE, IconConfiguration } from '@celum/common-components';
import { ReactiveComponent } from '@celum/ng2base';
import { DateFormats, DateUtil } from '@celum/work/app/shared/util';

export type DueDateParentComponent = 'TASK' | 'TASK_LIST' | 'WORKROOM' | 'TASK_INBOX';

@Component({
  selector: 'due-date',
  templateUrl: './due-date.component.html',
  styleUrls: ['./due-date.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: false
})
export class DueDate extends ReactiveComponent implements OnInit, OnChanges {
  @Input() public dueDate: number;
  @Input() public format = DateFormats.LONG;
  @Input() public hasPermission = true;

  @Input() public backgroundColor: string;
  @Input() public calendarColor: string = ColorConstants.BLUE_GRAY_900;
  @Input() public iconColor: string = ColorConstants.BLUE_GRAY_500;
  @Input() public iconHoverColor: string = ColorConstants.BLUE_GRAY_700;

  @Input() public displayLabelOnly: boolean;
  @Input() public displayCalendarIconOnly = false;

  @Input() public addDueDateTooltip: string;
  @Input() public dueDateTooltip: string;
  @Input() public dueDateParentComponent: DueDateParentComponent;

  @Output() public readonly dueDateChange: EventEmitter<number> = new EventEmitter();
  @HostBinding('attr.data-cy') public dataCyAttr = 'due-date';

  @ViewChild(MatDatepicker) public datePicker: MatDatepicker<Date>;

  public removeIcon: IconConfiguration;
  public calendarDueDateCtrl: UntypedFormControl;
  public minDate: Date = new Date();

  constructor(
    private dateUtil: DateUtil,
    private translateService: TranslateService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    super();
  }

  public get dueDateDisplayText(): string {
    if (this.format === DateFormats.LONG) {
      return this.dateUtil.getFormattedFullDate(this.calendarDueDateCtrl.value);
    }

    return this.dateUtil.getFormattedDate(this.calendarDueDateCtrl.value);
  }

  public get dueDateIconWithText(): IconConfiguration {
    return this.dueDateIcon
      .withText(this.dueDateDisplayText)
      .withTextTransformation(ICON_TEXT_TRANSFORM_NONE)
      .withTextColor(this.calendarColor);
  }

  public get dueDateIcon(): IconConfiguration {
    return IconConfiguration.medium('calendar-m').withColor(this.calendarColor);
  }

  public get addDueDateIcon(): IconConfiguration {
    return IconConfiguration.small('calendar-add')
      .withColor(this.getIconColor())
      .withIconSize(30)
      .withHoverColor(this.iconHoverColor);
  }

  public get noPermissionDueDateIcon(): IconConfiguration {
    return IconConfiguration.medium('calendar-strikethrough').withColor(this.getIconColor()).withIconSize(30);
  }

  public get dueDateControlValue(): Date | null {
    return this.calendarDueDateCtrl.value;
  }

  public get tooltipInfo(): string {
    if (this.dueDateControlValue && this.hasPermission) {
      return this.translateService.instant(this.dueDateTooltip || 'WORKROOM_TASK.DUE_DATE.SELECTED_DUE_DATE');
    }

    if (this.hasPermission) {
      return this.translateService.instant(this.addDueDateTooltip || 'WORKROOM_TASK.DUE_DATE.ADD');
    }

    return this.translateService.instant('WORKROOM_TASK.DUE_DATE.NO_PERMISSION_TO_EDIT');
  }

  public get isDueDateSet(): boolean {
    return !!this.dueDateControlValue;
  }

  public get isTooltipDisabled(): boolean {
    return this.dueDateParentComponent === 'TASK_INBOX';
  }

  public get backgroundColorByDueDateBucket(): string {
    if (DateUtil.isInPast(this.dueDate)) {
      return ColorConstants.ERROR;
    }

    return DateUtil.isToday(this.dueDate) ? ColorConstants.WARNING : ColorConstants.BLUE_GRAY_300;
  }

  public ngOnInit(): void {
    this.calendarDueDateCtrl = new UntypedFormControl(null, []);

    const localDateStartOfDay = DateUtil.toLocalDateStartOfDay(this.dueDate);
    this.setControlValue(this.dueDate > 0 ? localDateStartOfDay.getTime() : 0);

    this.translateService.onLangChange
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => this.changeDetectorRef.markForCheck());
  }

  public ngOnChanges({ dueDate }: SimpleChanges): void {
    this.removeIcon = IconConfiguration.small('cancel-m').withColor(this.calendarColor).withIconSize(16);

    if (dueDate) {
      const localDateStartOfDay = DateUtil.toLocalDateStartOfDay(dueDate.currentValue);
      this.setControlValue(dueDate.currentValue > 0 ? localDateStartOfDay.getTime() : 0);
    }
  }

  public getBackgroundColor(): string {
    if (!this.hasPermission) {
      return null;
    }
    return this.backgroundColor || this.backgroundColorByDueDateBucket;
  }

  public setNewDueDate(newDueDate: Date): void {
    const dueDate = newDueDate ? DateUtil.toUtcStartOfDay(newDueDate) : null;
    this.dueDateChange.emit(dueDate);
  }

  public addDueDate(): void {
    if (this.hasPermission) {
      this.datePicker.open();
    }
  }

  public removeDueDate(): void {
    this.calendarDueDateCtrl.setValue(null);
    this.dueDateChange.emit(null);
  }

  private setControlValue(dueDate: number | null): void {
    this.calendarDueDateCtrl?.setValue(dueDate ? new Date(dueDate) : null);
  }

  private getIconColor(): string {
    if (!this.hasPermission && this.dueDateParentComponent !== 'TASK_LIST') {
      return ColorConstants.BLUE_GRAY_200;
    }

    return this.iconColor;
  }
}
