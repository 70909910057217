<div
    class="custom-field_chip-name-container"
    [matTooltip]="'TEAMSPACE_MANAGEMENT.FIELDS.CUSTOM_FIELD_CARD.CHIPS_INPUT.ERRORS.LIMIT_REACHED_INFO' | translate"
    [matTooltipDisabled]="(controls.chips.value ?? []).length < CHIPS_LIMIT"
    [matTooltipClass]="'custom-field-card_content_chip-name-container_tooltip'">
  <div class="custom-field_chip-name-container_label">
    <span class="custom-field-label custom-field-chip_label">
        {{ 'TEAMSPACE_MANAGEMENT.FIELDS.CUSTOM_FIELD_CARD.CHIPS_INPUT.LABEL' | translate }}
    </span>
    <celum-icon
        [configuration]="infoIcon"
        [matTooltip]="'TEAMSPACE_MANAGEMENT.FIELDS.CUSTOM_FIELD_CARD.CHIPS_INPUT.INFO' | translate">
    </celum-icon>
  </div>
  <mat-form-field celum-form-field class="custom-field-chip_control" [reserveHintSpace]="true">
    <input
        [formControl]="controls.chipName"
        [errorStateMatcher]="chipNameCustomErrorStateMatcher"
        (keydown.enter)="chipNameSubmitted($any($event))"
        placeholder="{{ 'TEAMSPACE_MANAGEMENT.FIELDS.CUSTOM_FIELD_CARD.CHIPS_INPUT.PLACEHOLDER' | translate }}"
        maxlength="{{ TEXT_CONTROL_LIMIT }}"
        matInput
        data-cy="custom-field-chip-name-input"/>

    <mat-hint *ngIf="controls.chipName.value?.length >= TEXT_CONTROL_LIMIT - 10">
        {{ TEXT_CONTROL_LIMIT - (controls.chipName.value?.length ?? TEXT_CONTROL_LIMIT) }}
        {{ 'TEAMSPACE_MANAGEMENT.FIELDS.CUSTOM_FIELD_CARD.NAME_INPUT_HINT' | translate }}
    </mat-hint>

    <mat-error *ngIf="controls.chips.invalid">
        {{ 'TEAMSPACE_MANAGEMENT.FIELDS.CUSTOM_FIELD_CARD.ERRORS.REQUIRED' | translate }}
    </mat-error>
  </mat-form-field>
</div>

<custom-field-card-chips
    class="custom-field_chips-container"
    [formControl]="controls.chips"
    *ngIf="controls.chips.value && controls.chips.value.length > 0">
</custom-field-card-chips>
