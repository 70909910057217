import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { provideComponentStore } from '@ngrx/component-store';
import { Store } from '@ngrx/store';

import { CustomField } from '@celum/work/app/core/model/entities/custom-field/custom-field.model';
import { SharedModule } from '@celum/work/app/shared/shared.module';

import { SearchAndSelectCustomFieldsService } from './search-and-select-custom-fields.service';
import { SearchAndSelectCustomFieldsStore } from './search-and-select-custom-fields.store';
import { SearchAndSelectGenericService } from '../search-and-select-generic/search-and-select-generic.base';
import { SearchAndSelectGenericComponent } from '../search-and-select-generic/search-and-select-generic.component';

@Component({
  selector: 'search-and-select-custom-fields',
  imports: [CommonModule, SharedModule, SearchAndSelectGenericComponent],
  templateUrl: './search-and-select-custom-fields.component.html',
  styleUrls: ['./search-and-select-custom-fields.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  viewProviders: [
    provideComponentStore(SearchAndSelectCustomFieldsStore),
    {
      provide: SearchAndSelectGenericService,
      useFactory: (searchAndSelectCustomFieldsStore: SearchAndSelectCustomFieldsStore, store: Store<any>) =>
        new SearchAndSelectCustomFieldsService(searchAndSelectCustomFieldsStore, store),
      deps: [SearchAndSelectCustomFieldsStore, Store<any>]
    }
  ]
})
export class SearchAndSelectCustomFields implements OnInit {
  @HostBinding('class') public hostCls = 'search-and-select-custom-fields';

  @Input() public selectedFieldIds: number[];

  @Output() public elementSelected: EventEmitter<CustomField> = new EventEmitter<CustomField>();

  constructor(private searchAndSelectCustomFieldsStore: SearchAndSelectCustomFieldsStore) {}

  public ngOnInit(): void {
    this.searchAndSelectCustomFieldsStore.setSelectedFieldIds(this.selectedFieldIds);
  }
}
