import { ChangeDetectionStrategy, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep, isEmpty } from 'lodash';
import { BehaviorSubject, Observable, of } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  first,
  map,
  mergeMap,
  switchMap,
  take,
  takeUntil,
  tap,
  withLatestFrom
} from 'rxjs/operators';

import { CelumDialog, CelumDialogConfiguration, ColorConstants, IconConfiguration } from '@celum/common-components';
import { CelumSimpleList } from '@celum/internal-components';
import { ReactiveComponent } from '@celum/ng2base';
import { Permission } from '@celum/work/app/core/api/permission';
import { TenantGuard } from '@celum/work/app/core/auth/tenant.guard';
import { ConfigurationKey } from '@celum/work/app/core/model/configuration.model';
import { Teamspace } from '@celum/work/app/core/model/entities/teamspace';
import { Template } from '@celum/work/app/core/model/entities/template/template.model';
import { selectAllTemplatesForActiveCategory } from '@celum/work/app/core/model/entities/template/template.selectors';
import {
  CategoryValues,
  PredefinedCategoryValues,
  TemplateCategory
} from '@celum/work/app/core/model/entities/template-category/template-category.model';
import {
  selectActiveCategory,
  selectActiveCategoryId,
  selectAllTemplateCategories,
  selectHasPermissionForTeamspace,
  selectHasPermissionInAnyTeamspace,
  selectIsCelumTemplatesActiveCategory,
  selectTeamspaceByCategoryId
} from '@celum/work/app/core/model/entities/template-category/template-category.selectors';
import { DeleteQueryParam, SetQueryParam } from '@celum/work/app/core/router/router.actions';
import { selectRouterQueryParam } from '@celum/work/app/core/router/router.selectors';
import { CreateWorkroomFromTemplateStrategy } from '@celum/work/app/pages/workroom-creator/services/create-workroom-strategy.service';
import { FullscreenDialogComponent } from '@celum/work/app/shared/components/fullscreen-dialog/fullscreen-dialog.component';
import { tryToParseInt } from '@celum/work/app/shared/util/number-util';
import {
  WorkroomWizardComponent,
  WorkroomWizardData
} from '@celum/work/app/workroom-wizard/components/workroom-wizard.component';

import { CreateTemplateStrategy } from '../../services/create-template-strategy.service';
import * as templateChooserActions from '../../store/workroom-creator.actions';
import {
  selectDefaultConfig,
  selectIfWorkroomWasCreated,
  selectTotalTemplateCount,
  selectWorkroomCreatorHasTemplatesBottom,
  selectWorkroomCreatorInitiating
} from '../../store/workroom-creator.selectors';

enum IconSize {
  EMPTY_PAGE = 148,
  NEW = 30
}

@Component({
  selector: 'template-chooser-dialog',
  templateUrl: './template-chooser-dialog.component.html',
  styleUrls: ['./template-chooser-dialog.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: false
})
export class TemplateChooserDialogComponent
  extends ReactiveComponent
  implements OnInit, CelumDialog<TemplateChooserDialogConfiguration>
{
  public static readonly DIALOG_ID = 'template-chooser';
  @ViewChild(CelumSimpleList) public celumSimpleList: CelumSimpleList;

  public color: string;
  public readonly emptySectionIcon = IconConfiguration.xLarge(
    'no-results-template',
    'TEMPLATE_CHOOSER.EMPTY_PAGE.TITLE',
    IconSize.EMPTY_PAGE
  );

  public workroomCreatorInitiating$: Observable<boolean>;
  public templates$: Observable<Template[]>;
  public totalTemplateCount$: Observable<number>;
  public hasMoreBottom$: Observable<boolean>;
  public templateCategories$: Observable<TemplateCategory[]>;
  public selectedCategoryId$: Observable<CategoryValues>;
  public categoryIds$: Observable<string[]>;
  public isCategorySelected$: Observable<boolean>;

  public showCreateTemplateButton$: Observable<boolean>;
  public showCreateWorkroomButton$: Observable<boolean>;
  public activeTeamspace$: Observable<Teamspace>;
  public activeCategory$: Observable<TemplateCategory>;

  public readonly permissions = {
    DELETE: Permission.TEMPLATE_DELETE,
    UPDATE: Permission.TEMPLATE_UPDATE,
    CREATE_WORKROOM: Permission.WORKROOM_CREATE
  };
  public readonly predefinedCategoryKeys = PredefinedCategoryValues;

  public createNewTemplateIcon = IconConfiguration.small('create-new-template', '').withColor(
    ColorConstants.BLUE_GRAY_900
  );
  public createWorkroomIcon = IconConfiguration.xLarge('start-workroom', '', IconSize.NEW).withColor(
    ColorConstants.BLUE_GRAY_700
  );
  public iconInfoConfig = IconConfiguration.medium('info-s').withColor(ColorConstants.PRIMARY);

  private allCategoriesIds$ = new BehaviorSubject([]);
  private activeCategoryId: CategoryValues;

  constructor(
    private dialogRef: MatDialogRef<TemplateChooserDialogComponent>,
    private route: Router,
    private store: Store<any>,
    private translate: TranslateService,
    private matDialog: MatDialog
  ) {
    super();
  }

  public configure({ color }: TemplateChooserDialogConfiguration) {
    this.color = color;
  }

  public ngOnInit() {
    this.store.dispatch(templateChooserActions.WorkroomCreatorInit());

    this.showCreateTemplateButton$ = this.getShowButtonCondition(Permission.TEMPLATE_CREATE, true);
    this.showCreateWorkroomButton$ = this.getShowButtonCondition(Permission.WORKROOM_CREATE, false);
    this.activeTeamspace$ = this.store
      .select(selectActiveCategoryId)
      .pipe(switchMap(categoryId => this.store.select(selectTeamspaceByCategoryId(categoryId))));
    this.activeCategory$ = this.store.select(selectActiveCategory);

    this.workroomCreatorInitiating$ = this.store.select(selectWorkroomCreatorInitiating);
    this.hasMoreBottom$ = this.store.select(selectWorkroomCreatorHasTemplatesBottom);

    this.templateCategories$ = this.store.select(selectAllTemplateCategories).pipe(
      filter(categories => !isEmpty(categories)),
      tap(categories =>
        this.allCategoriesIds$.next([...categories.map(c => c.id), this.predefinedCategoryKeys.PERSONAL])
      ),
      takeUntil(this.unsubscribe$)
    );

    this.allCategoriesIds$
      .pipe(
        filter(categories => !isEmpty(categories)),
        take(1)
        // Start selecting categoryIds only after all are loaded
      )
      .subscribe(() => this.listenForCategories());

    this.templates$ = this.store.select(selectAllTemplatesForActiveCategory, { translateService: this.translate }).pipe(
      tap(() => {
        if (this.celumSimpleList?.scrollContainer) {
          this.celumSimpleList.scrollContainer.update();
        }
      })
    );

    this.totalTemplateCount$ = this.store.select(selectTotalTemplateCount);

    this.store
      .select(selectIfWorkroomWasCreated)
      .pipe(
        first(isCreated => isCreated),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(() => this.dialogRef.close());

    this.handleQueryParams();
  }

  public listenForCategories(): void {
    this.selectedCategoryId$ = this.store.select(selectActiveCategoryId);

    this.selectedCategoryId$
      .pipe(
        distinctUntilChanged(),
        tap(categoryId => this.refreshCategory(categoryId)),
        tap(categoryId => {
          this.activeCategoryId = categoryId;
          if (this.celumSimpleList) {
            this.celumSimpleList.scrollToTop();
          }
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();

    this.categoryIds$ = this.selectedCategoryId$.pipe(
      mergeMap(categoryId => {
        if (categoryId === this.predefinedCategoryKeys.ALL) {
          return this.allCategoriesIds$.pipe(filter(categoryIds => !!categoryIds.length));
        }

        return of([categoryId]);
      })
    );

    this.isCategorySelected$ = this.selectedCategoryId$.pipe(map(categoryId => tryToParseInt(categoryId) >= 0));
  }

  public async closeDialog() {
    this.resetCategoryFilter();
    this.dialogRef.close();
  }

  public setCategoryAsActive({ id }: Partial<TemplateCategory>) {
    this.navigateToWorkroomWizard(id);
  }

  public resetCategoryFilter() {
    this.navigateToWorkroomWizard(null);
  }

  public trackByFn(index: number, template: Template) {
    return template ? template.id : index;
  }

  public fetchPersonalTemplates() {
    this.navigateToWorkroomWizard(this.predefinedCategoryKeys.PERSONAL);
  }

  public createDefaultWorkroom() {
    this.store
      .select(selectDefaultConfig(ConfigurationKey.DEFAULT_WORKROOM))
      .pipe(
        tap(config => {
          if (!config) {
            this.store.dispatch(
              templateChooserActions.WorkroomCreatorLoadDefaultConfig({ configKey: ConfigurationKey.DEFAULT_WORKROOM })
            );
          }
        }),
        first(config => !!config),
        map(config => cloneDeep(config))
      )
      .subscribe(template => {
        template.taskLists.map(taskList => (taskList.name = this.translate.instant(taskList.name)));
        const data: WorkroomWizardData = {
          title: 'WORKROOM_WIZARD.DIALOG.WORKROOM.HEADER',
          template: cloneDeep(template),
          saveStrategyType: CreateWorkroomFromTemplateStrategy,
          type: {
            targetEntity: 'WORKROOM',
            editMode: false
          }
        };
        this.matDialog.open(WorkroomWizardComponent, FullscreenDialogComponent.dialogConfig(data));
      });
  }

  public onRequestNextPage(bottomScroll: boolean): void {
    if (!bottomScroll) {
      return;
    }

    this.getCategoryIdsForCategory(this.activeCategoryId)
      .pipe(take(1))
      .subscribe(categoryIds =>
        this.store.dispatch(
          templateChooserActions.WorkroomCreatorFetchNextTemplateBatch({
            categoryIds,
            includePersonal:
              this.activeCategoryId === this.predefinedCategoryKeys.PERSONAL ||
              this.activeCategoryId === this.predefinedCategoryKeys.ALL
          })
        )
      );
  }

  public createBlankTemplate() {
    this.store
      .select(selectDefaultConfig(ConfigurationKey.DEFAULT_WORKROOM_TEMPLATE))
      .pipe(
        tap(config => {
          if (!config) {
            this.store.dispatch(
              templateChooserActions.WorkroomCreatorLoadDefaultConfig({
                configKey: ConfigurationKey.DEFAULT_WORKROOM_TEMPLATE
              })
            );
          }
        }),
        first(config => !!config),
        map(config => cloneDeep(config))
      )
      .subscribe(template => {
        let templateCategory = {};
        if (
          this.activeCategoryId !== this.predefinedCategoryKeys.ALL &&
          this.activeCategoryId !== this.predefinedCategoryKeys.PERSONAL
        ) {
          templateCategory = { categoryId: this.activeCategoryId };
        }

        template.taskLists.map(taskList => (taskList.name = this.translate.instant(taskList.name)));

        const data: WorkroomWizardData = {
          title: 'WORKROOM_WIZARD.DIALOG.TEMPLATE.HEADER',
          template: cloneDeep({ ...template, ...templateCategory }),
          saveStrategyType: CreateTemplateStrategy,
          type: {
            targetEntity: 'TEMPLATE',
            editMode: false
          }
        };
        this.matDialog.open(WorkroomWizardComponent, FullscreenDialogComponent.dialogConfig(data));
      });
  }

  private navigateToWorkroomWizard(templateCategory: any) {
    this.store
      .select(selectRouterQueryParam(TenantGuard.IMPORT_INTENT_PARAM))
      .pipe(take(1))
      .subscribe(intent =>
        this.route.navigate([], {
          queryParams: {
            templateCategory,
            importIntent: intent
          },
          queryParamsHandling: 'merge'
        })
      );
  }

  private refreshCategory(categoryId: CategoryValues): void {
    this.getCategoryIdsForCategory(categoryId)
      .pipe(take(1))
      .subscribe(categoryIds =>
        this.store.dispatch(
          templateChooserActions.WorkroomCreatorRefreshTemplates({
            categoryIds,
            includePersonal:
              categoryId === this.predefinedCategoryKeys.PERSONAL || categoryId === this.predefinedCategoryKeys.ALL
          })
        )
      );
  }

  private getCategoryIdsForCategory(categoryValues: CategoryValues): Observable<number[]> {
    switch (categoryValues) {
      case this.predefinedCategoryKeys.ALL.toString():
        return this.allCategoriesIds$.pipe(
          filter(categoryIds => !isEmpty(categoryIds)),
          map(categoryIds => categoryIds.filter(categoryId => categoryId !== this.predefinedCategoryKeys.PERSONAL))
        );
      case this.predefinedCategoryKeys.PERSONAL.toString():
        return of([]);
      default:
        return of([categoryValues as any]);
    }
  }

  private getShowButtonCondition(permission: Permission, showButtonInPersonal: boolean) {
    return this.store.select(selectActiveCategoryId).pipe(
      withLatestFrom(this.store.select(selectIsCelumTemplatesActiveCategory)),
      switchMap(([categoryId, isCelumTemplateTabActive]) => {
        // In all tab, show buttons only if user has permission in at least 1 category
        if (categoryId === PredefinedCategoryValues.ALL) {
          return this.store.select(selectHasPermissionInAnyTeamspace(permission));
          // Dont show any button in celum tab
        } else if (isCelumTemplateTabActive) {
          return of(false);
          // In personal, we are showing just create-template button
        } else if (categoryId === PredefinedCategoryValues.PERSONAL) {
          return of(showButtonInPersonal);
        }

        // In category tabs, show buttons only if user has permission in the teamspace for specific operation
        return this.store.select(selectHasPermissionForTeamspace, {
          categoryId,
          permission
        });
      })
    );
  }

  private handleQueryParams(): void {
    this.store.dispatch(SetQueryParam({ key: 'templateChooser', value: true }));

    this.dialogRef.afterClosed().subscribe(() => {
      this.store.dispatch(DeleteQueryParam({ key: 'templateChooser' }));
    });
  }
}

export class TemplateChooserDialogConfiguration extends CelumDialogConfiguration {
  constructor(public color: string = ColorConstants.PRIMARY) {
    super('main');
  }
}
