import { Platform } from '@angular/cdk/platform';
import { Directive, HostListener } from '@angular/core';

import { DraggingService } from '@celum/work/app/shared/util';

@Directive({
  selector: '[documentDroppable]',
  standalone: false
})
export class DocumentDroppableDirective {
  private eventTarget: EventTarget;

  constructor(
    private platform: Platform,
    private draggingService: DraggingService
  ) {}

  @HostListener('document:dragenter', ['$event'])
  public onDragEnter(event: DragEvent) {
    this.eventTarget = event.currentTarget;
    event.preventDefault();
    if (!event.dataTransfer.types.includes('Files')) {
      return;
    }
    this.draggingService.changeDroppable(true);
  }

  @HostListener('document:dragover', ['$event'])
  public onDragOver(event: DragEvent) {
    event.preventDefault();
    if (!event.dataTransfer.types.includes('Files')) {
      return;
    }
    this.draggingService.changeDroppable(true);
  }

  @HostListener('document:dragleave', ['$event'])
  public onDragLeave(event: DragEvent) {
    // check for firefox since firefox does not propagate pageX and pageY on dragleave event
    if (this.platform.FIREFOX && this.eventTarget === event.currentTarget) {
      this.draggingService.changeDroppable(false);
      event.preventDefault();
    }
    // Chrome and Edge
    else if (event.pageX === 0 || event.pageY === 0) {
      this.draggingService.changeDroppable(false);
      event.preventDefault();
    } else {
      return false;
    }
  }

  @HostListener('document:dragend', ['$event'])
  public onDragEnd(event: DragEvent) {
    this.draggingService.changeDroppable(false);
    event.preventDefault();
  }

  @HostListener('document:drop', ['$event'])
  public onDrop(event: DragEvent) {
    this.draggingService.changeDroppable(false);
    event.preventDefault();
  }
}
