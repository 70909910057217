import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';

import { CelumEmptyPageModule } from '@celum/common-components';
import { ReactiveComponent } from '@celum/ng2base';
import { Teamspace } from '@celum/work/app/core/model/entities/teamspace';
import { ApplicationEventBus } from '@celum/work/app/shared/util/application-event-bus.service';
import { FieldsOverviewTabComponent } from '@celum/work/app/teamspace-management/components/fields-overview-tab/fields-overview-tab.component';
import { FormsOverviewTabComponent } from '@celum/work/app/teamspace-management/components/forms-overview-tab/forms-overview-tab.component';

import { CustomFieldsTabEvents } from '../fields-overview-tab/events/fields-overview-events';

@Component({
  selector: 'forms-management',
  imports: [CommonModule, MatTabsModule, CelumEmptyPageModule, FormsOverviewTabComponent, FieldsOverviewTabComponent],
  templateUrl: './forms-management.component.html',
  styleUrls: ['./forms-management.component.less'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormsManagementComponent extends ReactiveComponent implements OnInit {
  @Input() public teamspace: Teamspace;
  @Input() public hasWorkroomsFinishPermission: boolean;

  protected selectedTabIndex = 0;

  constructor(
    private eventBus: ApplicationEventBus,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  public ngOnInit(): void {
    this.eventBus.observeEvents(this.unsubscribe$, CustomFieldsTabEvents.FORM_CREATED).subscribe(() => {
      this.selectedTabIndex = 0;
      this.cdr.detectChanges();
    });
  }
}
