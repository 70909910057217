import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';

import {
  CelumDialog,
  CelumDialogConfiguration,
  ColorConstants,
  EmptyPage,
  EmptyPageConfig,
  IconConfiguration,
  SortDirection
} from '@celum/common-components';
import { ContentHubSort, ContentHubSortField } from '@celum/work/app/content-hub/model/content-hub.model';
import { ContentHubBuildNumberStrategyResolverService } from '@celum/work/app/content-hub/services/content-hub-build-number-strategy-resolver.service';
import { SortInfo } from '@celum/work/app/shared/components/filter-sort/sorter/sorter.component';
import { getEnumKeyByValue } from '@celum/work/app/shared/util/enum-util';
import { tryToParseInt } from '@celum/work/app/shared/util/number-util';

import { ContentItem } from '../../../core/model/entities/content-item/content-item.model';
import { File } from '../../../core/model/entities/file/file.model';
import { Folder } from '../../../core/model/entities/folder/folder.model';
import { selectUsedStoragePercentage } from '../../../core/model/entities/teamspace/teamspace-storage.selectors';
import { ContentItemListComponent } from '../../../files/components/content-item-list/content-item-list.component';
import {
  BrowsingDialogSubmitSubMenuConfiguration,
  BrowsingDialogSubmitSubMenuType
} from '../../../shared/components/browsing-dialog/browsing-dialog.component';
import { ContentHubBrowserService } from '../../services/content-hub-browser.service';
import { BaseContentHubDialog } from '../base-content-hub-dialog';

@Component({
  selector: 'select-assets-dialog',
  templateUrl: './select-assets-dialog.component.html',
  styleUrls: ['./select-assets-dialog.component.less'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ContentHubBrowserService],
  standalone: false
})
export class SelectAssetsDialog
  extends BaseContentHubDialog
  implements OnInit, CelumDialog<SelectAssetsDialogConfiguration>
{
  @ViewChild(ContentItemListComponent, { static: true }) public contentItemComponent: ContentItemListComponent;

  public color: string;
  public title: string;
  public submitButtonText: string;
  public importIcon: IconConfiguration;
  public isMultiSelection: boolean;
  public canImportEntireCollection: boolean;

  public usedStoragePercentage$: Observable<number>;
  public contentItems$: Observable<File[]>;
  public contentItemsLoading$: Observable<boolean>;
  public hasMoreContentItems$: Observable<boolean>;
  public selectedContentItems: ContentItem[] = [];
  public emptyPageConfig$: Observable<EmptyPageConfig>;

  public sortFields$: Observable<SortInfo[]>;
  public selectedSortField$: Observable<SortInfo>;
  public readonly hideChildrenIcon = IconConfiguration.medium('hide-children-collection');
  public readonly showChildrenIcon = IconConfiguration.medium('show-children-collection');
  public showChildren = false;
  public submitOptions: BrowsingDialogSubmitSubMenuConfiguration[] = [];

  public computeDialogSubmitOptionsFn?: (
    selectedContentItems: ContentItem[]
  ) => BrowsingDialogSubmitSubMenuConfiguration[];

  private emptyPageDefault: EmptyPageConfig = EmptyPage.noActionConfig(
    'no-file-uploaded',
    'no-file-uploaded',
    'CONTENT_HUB.COMMON.NO_ASSETS_UPLOADED',
    'normal',
    180
  );
  private emptyPageSearch: EmptyPageConfig = EmptyPage.noActionConfig(
    'no-file-found',
    'ch-empty-search',
    'CONTENT_HUB.COMMON.NO_ASSETS_FOUND',
    'normal',
    180
  );

  constructor(
    private dialogRef: MatDialogRef<SelectAssetsDialog, SelectAssetsDialogResult>,
    private store: Store<any>,
    protected browseService: ContentHubBrowserService,
    private contentHubBuildNumberStrategyResolver: ContentHubBuildNumberStrategyResolverService,
    private cdr: ChangeDetectorRef
  ) {
    super(browseService);
  }

  public ngOnInit(): void {
    this.paginationInformation$ = this.getPaginationInformation();
    this.children$ = this.browseService.allFolders$;
    this.usedStoragePercentage$ = this.store.select(selectUsedStoragePercentage);
    this.contentItems$ = this.browseService.files$.pipe(
      map(res => res?.results),
      tap(() => this.cdr.detectChanges())
    );
    this.contentItemsLoading$ = this.browseService.files$.pipe(map(res => res?.loading));
    this.hasMoreContentItems$ = this.browseService.files$.pipe(map(res => res?.paginationInformation?.elementsFollow));
    this.collections$ = this.browseService.collections$;
    this.emptyPageConfig$ = this.contentItems$.pipe(
      map(_ => this.browseService.isFilterActive()),
      map(isFilterActive => (isFilterActive ? this.emptyPageSearch : this.emptyPageDefault))
    );

    this.sortFields$ = this.getAllSortFields().pipe(
      map(sortFields =>
        sortFields.map(sortField => {
          const sortFieldKey = getEnumKeyByValue(ContentHubSortField, sortField);
          return new SortInfo(sortField, `CONTENT_HUB.SORT.FIELD.${sortFieldKey}`);
        })
      )
    );

    this.selectedSortField$ = this.sortFields$.pipe(
      map(sortFields => sortFields.find(sortField => sortField.value === ContentHubSortField.NAME))
    );
  }

  public selectedFolderChanged(folder: Folder): void {
    this.rootCollectionSelected = folder.id === (this.selectedCollection.id as any);
    this.activeFolder$.next(folder);
    this.browseService.loadMoreAssets(folder.id as any, true).subscribe();
    this.onContentItemSelected([]);
  }

  public onFetchNextAssetsBatch(resetAssets = false): void {
    const activeFolderId = this.activeFolder$.getValue()?.id;
    if (activeFolderId) {
      this.browseService.loadMoreAssets(activeFolderId as any, resetAssets).subscribe();
    }
  }

  public onContentItemSelected(contentItems: ContentItem[]): void {
    this.selectedContentItems = contentItems;
    if (this.computeDialogSubmitOptionsFn) {
      this.submitOptions = this.computeDialogSubmitOptionsFn(contentItems);
    }
  }

  public onContentItemListBatchSizeChanged(batchSize: number): void {
    if (!this.browseService.assetPageSize) {
      this.browseService.assetPageSize = batchSize;
      this.initData();
    }

    if (this.browseService.assetPageSize < batchSize) {
      this.browseService.assetPageSize = batchSize;
      this.onFetchNextAssetsBatch(true);
    }
  }

  public onSubmit(subMenuType?: BrowsingDialogSubmitSubMenuType): void {
    this.selectedContentItems.forEach(
      value => (value.parentCollectionId = tryToParseInt(this.activeFolder$.getValue().id))
    );
    this.dialogRef.close({
      selectedContentItems:
        this.selectedContentItems.length > 0 ? this.selectedContentItems : [this.activeFolder$.getValue()],
      subMenuType
    });
  }

  public closeDialog(): void {
    this.dialogRef.close(null);
  }

  public configure({ color, params }: SelectAssetsDialogConfiguration): void {
    this.color = color;
    this.title = params.title;
    this.submitButtonText = params.submitButtonText;
    this.importIcon = params.submitIcon.withColor('#FFF');
    this.isMultiSelection = params.isMultiSelection;
    this.canImportEntireCollection = params.canImportEntireCollection;
    this.computeDialogSubmitOptionsFn = params.computeDialogSubmitOptionsFn;
  }

  public onSearchChanged(value: string): void {
    this.browseService.assetsSearchTermChanged(this.activeFolder$.getValue()?.id as any, value);
  }

  public onSearchRecursiveClick(): void {
    this.showChildren = !this.showChildren;
    this.browseService.assetsSearchRecursiveChanged(this.activeFolder$.getValue()?.id as any, this.showChildren);
  }

  public onSortChanged(sortInfo: SortInfo): void {
    this.selectedSortField$ = of(sortInfo);
    const sort: ContentHubSort = {
      field: sortInfo.value,
      direction: sortInfo.direction === SortDirection.DESC ? 'DESC' : 'ASC'
    };
    this.browseService.assetsSortChanged(this.activeFolder$.getValue()?.id as any, sort);
  }

  public getAllSortFields(): Observable<ContentHubSortField[]> {
    return this.contentHubBuildNumberStrategyResolver.resolve().pipe(
      take(1),
      map(strategy => {
        return strategy.getSupportedContentHubSortFields();
      })
    );
  }

  public importCollectionDisabled(): Observable<boolean> {
    return this.contentHubBuildNumberStrategyResolver.resolve().pipe(
      take(1),
      map(strategy => {
        return (
          this.rootCollectionSelected ||
          (this.selectedContentItems.length < 1 &&
            (!this.canImportEntireCollection || !strategy.supportsImportCollection()))
        );
      })
    );
  }
}

export class SelectAssetsDialogConfiguration extends CelumDialogConfiguration {
  public color = ColorConstants.PRIMARY;

  constructor(public params: SelectAssetsDialogConfigurationParams) {
    super(SelectAssetsDialogConfiguration.name);
  }
}

export interface SelectAssetsDialogConfigurationParams {
  title: string;
  submitButtonText: string;
  submitIcon: IconConfiguration;
  isMultiSelection: boolean;
  canImportEntireCollection: boolean;
  computeDialogSubmitOptionsFn?: (selectedContentItems: ContentItem[]) => BrowsingDialogSubmitSubMenuConfiguration[];
}

export interface SelectAssetsDialogResult {
  selectedContentItems: ContentItem[];
  subMenuType: BrowsingDialogSubmitSubMenuType;
}
