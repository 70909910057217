import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { MatButtonModule as MatMenuModule } from '@angular/material/button';

import { CelumButtonModule, CelumIconModule, IconConfiguration } from '@celum/common-components';
import { CustomField } from '@celum/work/app/core/model/entities/custom-field/custom-field.model';
import { TextTruncatorComponent } from '@celum/work/app/shared/components/text-truncator/text-truncator.component';

@Component({
  selector: 'custom-fields-table-row',
  templateUrl: './custom-fields-table-row.component.html',
  styleUrls: ['./custom-fields-table-row.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports: [CommonModule, MatMenuModule, TextTruncatorComponent, CelumIconModule, CelumButtonModule]
})
export class CustomFieldsTableRowComponent {
  @HostBinding('class') public componentCls = 'custom-fields-row';

  @Input() public customField: CustomField;

  public readonly menuIcon = IconConfiguration.large('option-m');
}
