import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { filter, takeUntil } from 'rxjs/operators';

import { ColorConstants } from '@celum/common-components';
import { ExecutableAction, isTruthy, ReactiveService } from '@celum/core';
import { TaskCreatedFromImportNotification } from '@celum/work/app/core/client-notification/model/client-notification.model';
import { NotificationWebsocketListener } from '@celum/work/app/core/client-notification/notification-websocket-listener.service';
import { ProgressTaskActions } from '@celum/work/app/progress-task/store/progress-task.actions';
import { ProgressTaskStatus } from '@celum/work/app/progress-task/store/progress-task.model';
import { selectProgressTaskByPayloadId } from '@celum/work/app/progress-task/store/progress-task.selectors';
import { SnackbarGroupEvent } from '@celum/work/app/shared/components/snackbar-group/model/snackbar-group-events.model';
import { ApplicationEventBus } from '@celum/work/app/shared/util/application-event-bus.service';

@Injectable({
  providedIn: 'root'
})
export class TaskCreationNotificationListener extends ReactiveService {
  public static readonly SNACKBAR_ID = 'go-to-task-notifications';

  constructor(
    private store: Store<any>,
    private clientNotificationWebsocketListener: NotificationWebsocketListener<TaskCreatedFromImportNotification>,
    private router: Router,
    private eventBus: ApplicationEventBus
  ) {
    super();
    this.startListening();
  }

  public startListening(): void {
    // listening for when a task is created for each imported assets
    this.clientNotificationWebsocketListener.clientNotificationsSubj
      .pipe(
        filter(notification => notification.typeKey === 'task_created_from_import'),
        takeUntil(this.unsubscribe$),
        concatLatestFrom(notification =>
          this.store.select(selectProgressTaskByPayloadId(notification.importTicketId)).pipe(isTruthy())
        )
      )
      .subscribe(([notification, progressTask]) => {
        const viewButton = {
          action: new ExecutableAction(() => {
            this.router.navigate(['workroom', notification.workroomId, 'tasks', 'task', notification.taskId]);
            this.eventBus.publishEvent({ type: SnackbarGroupEvent.SNACKBAR_GROUP_MINIMIZE });
          }, 'ViewTask'),
          text: 'TASK_DETAIL.SNACKBAR_BUTTONS.VIEW_TASK',
          buttonColor: ColorConstants.BLUE_GRAY_800,
          identifier: 'ViewTask'
        };

        this.store.dispatch(
          ProgressTaskActions.UpdateOne({
            id: progressTask.id,
            changes: {
              snackbarActions: [
                {
                  button: viewButton,
                  applicableWhenState: [ProgressTaskStatus.FINISHED]
                }
              ]
            }
          })
        );
      });

    // listening for when one single task is created for multiple imported assets
    this.clientNotificationWebsocketListener.clientNotificationsSubj
      .pipe(
        filter(notification => notification.typeKey === 'single_task_created_from_import'),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(notification => {
        this.router.navigate(['workroom', notification.workroomId, 'tasks', 'task', notification.taskId]);
      });
  }
}
