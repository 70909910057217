import { Pipe, PipeTransform } from '@angular/core';

import { LocalizedDatePipe } from '@celum/work/app/shared/pipes/localized-date.pipe';

/**
 * @extends LocalizedDatePipe
 * @description An impure pipe for dynamic updates of localized dates.
 *
 * It re-evaluates with every change detection cycle even when the pipes input (dateValue) stays the same,
 * useful for language-dependent content and refreshing language where cdRef can not work.
 *
 * @warning Use this pipe sparingly, as it can impact performance due to frequent re-evaluations.
 *
 * @example
 * {{ dateValue | impureLocalizedDate: dateFormats.SHORT }}
 */
@Pipe({
  name: 'impureLocalizedDate',
  pure: false,
  standalone: false
})
export class ImpureLocalizedDatePipe extends LocalizedDatePipe implements PipeTransform {}
