import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Renderer2,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, shareReplay, switchMap, withLatestFrom } from 'rxjs/operators';

import { ColorConstants, IconConfiguration } from '@celum/common-components';
import { DataUtil } from '@celum/core';
import { CelumDialogOpener, CelumSimpleList } from '@celum/internal-components';
import { ContentHubBuildNumberStrategyResolverService } from '@celum/work/app/content-hub/services/content-hub-build-number-strategy-resolver.service';
import {
  selectCanImport,
  selectWorkroomConfiguredForContentHub
} from '@celum/work/app/content-hub/store/content-hub.selectors';
import { Permission } from '@celum/work/app/core/api/permission';
import { ZipService } from '@celum/work/app/core/api/sass/zip.service';
import { ExportAnnotatedPdfService } from '@celum/work/app/core/export-annotated-pdf/export-annotated-pdf.service';
import { Roles } from '@celum/work/app/core/model';
import { hasAnyActiveContentHubConnections } from '@celum/work/app/core/model/entities/content-item/content-item-util';
import { File, FileType } from '@celum/work/app/core/model/entities/file/file.model';
import { Task, TaskCreationContext } from '@celum/work/app/core/model/entities/task';
import { PrintService } from '@celum/work/app/core/print/print.service';
import { CreateTaskDialogActions } from '@celum/work/app/pages/workroom/pages/files/components/create-task-dialog/store/create-task-dialog.actions';
import {
  DeleteItemsDialog,
  DeleteItemsDialogConfiguration
} from '@celum/work/app/pages/workroom/pages/files/components/delete-items-dialog/delete-items-dialog.component';
import {
  ContentItemRenameDialog,
  ContentItemRenameDialogConfiguration
} from '@celum/work/app/pages/workroom/pages/files/components/rename-item/rename-dialog.component';
import { ContentItemPermanentlyDeleteDialogOpener } from '@celum/work/app/pages/workroom/pages/files/services/content-item-permanently-delete-dialog-opener';
import { selectLoggedInPersonHasTaskPermission } from '@celum/work/app/pages/workroom/pages/tasks/pages/task-detail/store/task-permission.selectors';
import { PreviewUrlPipeArgs } from '@celum/work/app/shared/pipes/preview-url.pipe';
import { DateUtil, PermissionUtil } from '@celum/work/app/shared/util';
import { FileUtilService } from '@celum/work/app/shared/util/file-util.service';
import { isNullOrUndefined } from '@celum/work/app/shared/util/typescript-util';

import { ShareService } from '../../../services/share.service';
import { ContentItemBase } from '../content-item';

@Component({
  selector: 'file-card',
  templateUrl: './file-card.component.html',
  styleUrls: ['../content-item.shared.less', './file-card.component.less'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class FileCardComponent extends ContentItemBase<File> implements OnInit, OnChanges {
  // TODO @ton task & withAlreadyLinked check: create css theme for the component and add class conditionally in parent component
  @Input() public task: Task;
  @Input() public withAlreadyLinkedCheck: boolean;
  @Input() public selectionDisabled: boolean;
  @Input() public canCreateTasksFromFiles: boolean;
  @Input() public disableContextMenu: boolean;
  @Input() public showToolbar: boolean;
  @Input() public movingDisabled: boolean;

  public readonly exportAnnotatedPdfIcon: IconConfiguration = IconConfiguration.small('export-annotated-pdf');
  public readonly deleteIcon = IconConfiguration.small('remove-m');
  public readonly removeFromFileIcon = IconConfiguration.small('remove-from-file');
  public readonly restoreIcon = IconConfiguration.small('icon-16-time-set-up');
  public readonly downloadIcon = IconConfiguration.small('download-m');
  public readonly infoIcon = IconConfiguration.small('info');
  public readonly uploadIconSmall = IconConfiguration.small('upload-l');
  public readonly renameIconSmall = IconConfiguration.small('edit-l');
  public readonly createSingleTaskIcon = IconConfiguration.small('icon-create-single-task');
  public readonly contentHubIcon = IconConfiguration.small('icon-16-content-hub');
  public readonly printIcon = IconConfiguration.small('print-black');
  public readonly fileType = FileType.TYPE_KEY;
  public readonly taskIcon = IconConfiguration.small('attachment');
  public readonly createShareIcon = IconConfiguration.small('share-l');
  public readonly experienceIcon = IconConfiguration.small('experience')
    .withColor(ColorConstants.SYSTEM_WHITE)
    .withIconSize(20);

  public isAlreadyLinked: boolean;
  public isForbiddenPortalAsset: boolean;
  public previewUrlPipeArgs$: Observable<PreviewUrlPipeArgs>;
  public fileVersionIconTooltip$: Observable<string>;
  public isVisitor$: Observable<boolean>;
  public canDeleteFile$: Observable<boolean>;
  public canRemoveFileFromTask$: Observable<boolean>;
  public hasPortalAssetsInSelection$: Observable<boolean>;
  public isDownloadable$: Observable<boolean>;
  public printingIsEnabled$: Observable<boolean>;
  public exportingAnnotatedPdfIsEnabled$: Observable<boolean>;
  public activeVersionNumber$: Observable<number>;
  public canExportToCH$: Observable<boolean>;
  public canImportFromCH$: Observable<boolean>;
  public canRenameFile$: Observable<boolean>;
  public bulkDownloadEnabled$: Observable<boolean>;
  public canUploadNewVersion$: Observable<boolean>;
  public canMoveFile$: Observable<boolean>;
  public hasAnyContentHubConnections: boolean;

  constructor(
    private renderer: Renderer2,
    private zipService: ZipService,
    private printService: PrintService,
    private fileUtilService: FileUtilService,
    private permissionUtil: PermissionUtil,
    private dateUtil: DateUtil,
    protected celumDialogOpener: CelumDialogOpener,
    public store: Store<any>,
    public celumSimpleList: CelumSimpleList<File>,
    public translateService: TranslateService,
    public contentItemPermanentlyDeleteDialogOpener: ContentItemPermanentlyDeleteDialogOpener,
    public chStrategyResolver: ContentHubBuildNumberStrategyResolverService,
    public shareService: ShareService,
    @Inject(DOCUMENT) public document: Document
  ) {
    super(
      celumDialogOpener,
      celumSimpleList,
      store,
      translateService,
      contentItemPermanentlyDeleteDialogOpener,
      chStrategyResolver,
      shareService,
      document
    );
  }

  public get bulkDownloadDisabled(): boolean {
    return this.celumSimpleList.selectionHandler.getCurrentSelection().length > 1000;
  }

  public get contentHubIconConfiguration(): IconConfiguration {
    return hasAnyActiveContentHubConnections(this.contentItem)
      ? this.contentHubIcon.withColor(ColorConstants.SYSTEM_BLACK)
      : this.contentHubIcon.withColor(ColorConstants.BLUE_GRAY_300);
  }

  public get contentHubIconTooltip(): string {
    const tooltipKey = hasAnyActiveContentHubConnections(this.contentItem)
      ? 'CONTENT_HUB.AVAILABLE_IN_CH'
      : 'CONTENT_HUB.NOT_AVAILABLE_IN_CH';
    return this.translateService.instant(tooltipKey);
  }

  public get numberOfTasksIconTooltip(): string {
    const numberOfTasks = this.contentItem?.numberOfTasks ?? 0;
    const isSingular: boolean = numberOfTasks === 1;
    return this.translateService.instant(`FILES.CARD.NUMBER_OF_TASKS.TOOLTIP.${isSingular ? 'SINGULAR' : 'PLURAL'}`, {
      numberOfTasks: numberOfTasks
    });
  }

  // Needed for e2e tests to detect which tooltip is shown
  public getContentHubConnectionsDataE2e(): string {
    return hasAnyActiveContentHubConnections(this.contentItem)
      ? 'file-card-content-hub-icon'
      : 'file-card-content-hub-icon-not-all-connections-active';
  }

  public ngOnInit(): void {
    this.computeContextMenuPermissions();

    this.isAlreadyLinked = this.withAlreadyLinkedCheck && this.task?.attachmentIds.includes(this.contentItem.id);
    this.hasAnyContentHubConnections = !DataUtil.isEmpty(this.contentItem?.contentHubConnections);
  }

  public ngOnChanges({ contentItem }: SimpleChanges): void {
    if (contentItem) {
      this.updateForbiddenPortalAsset();

      if (contentItem.currentValue?.activeVersionId !== contentItem.previousValue?.activeVersionId) {
        this.updateFooterToolbar();
      }
    }
  }

  public updateFooterToolbar(): void {
    this.previewUrlPipeArgs$ = this.contentItem.activeVersion(this.store).pipe(
      filter(activeVersion => !!activeVersion),
      switchMap(activeVersion =>
        activeVersion.renditions(this.store).pipe(
          map(renditions => ({
            renditions,
            fileName: this.contentItem.name,
            uploadedDate: activeVersion.uploadedDate
          }))
        )
      )
    );

    this.fileVersionIconTooltip$ = this.contentItem.activeVersion(this.store).pipe(
      filter(activeVersion => !!activeVersion),
      map(activeVersion =>
        this.translateService.instant('FILES.CARD.VERSION.TOOLTIP', {
          versionNumber: activeVersion.versionNumber,
          dateTime: this.dateUtil.format(activeVersion.uploadedDate, 'MMMM do, yyyy hh:mm a')
        })
      )
    );
  }

  public async handleContextmenu(event: MouseEvent, el: HTMLSpanElement, contentItem: File): Promise<void> {
    event.preventDefault();
    this.resolveClickPropagation(event);

    if (this.disableContextMenu) {
      return;
    }

    if (!this.checkCanRightClick(contentItem)) {
      return;
    }

    this.renderer.setStyle(el, 'left', `${event.offsetX}px`);
    this.renderer.setStyle(el, 'top', `${event.offsetY}px`);
    // Need to set openedBy explicitly, otherwise it will focus first menu item
    this.matMenuTrigger._openedBy = 'mouse';
    this.matMenuTrigger.openMenu();
  }

  public renameFile(file: File): void {
    this.celumDialogOpener.showDialog(
      'rename-content-item',
      ContentItemRenameDialog,
      new ContentItemRenameDialogConfiguration(file)
    );
  }

  public printFile(file: File): void {
    this.printService.printFile(file.activeVersion(this.store));
  }

  public createNewTask(file: File): void {
    let contentItems = [];
    if (super.getSelectionLength() === 0) {
      contentItems.push(file);
    } else {
      contentItems = super.getSelection();
    }
    this.store.dispatch(
      CreateTaskDialogActions.OpenTaskCreationDialogForFiles({
        createTaskDialogParams: {
          files: contentItems,
          taskCreationContext: TaskCreationContext.FILE_EXPLORER
        }
      })
    );
  }

  public deleteFile(file: File): void {
    this.celumDialogOpener.showDialog(
      DeleteItemsDialog.name,
      DeleteItemsDialog,
      new DeleteItemsDialogConfiguration(this.getSelection()?.length > 0 ? this.getSelection() : [file])
    );
  }

  public resolveClickPropagation(e: MouseEvent) {
    if (this.isAlreadyLinked || this.isForbiddenPortalAsset) {
      e.stopPropagation();
    }
  }

  public requestZipDownload(): void {
    if (this.bulkDownloadDisabled) {
      return;
    }

    this.zipService.requestZipDownload(
      this.contentItem.libraryId,
      this.celumSimpleList.selectionHandler.getCurrentSelection()
    );
  }

  public getSelection() {
    if (this.selectionDisabled && !!this.contentItem) {
      return [this.contentItem];
    }
    return super.getSelection();
  }

  public checkCanRightClick(item: File): boolean {
    return super.checkCanRightClick(item);
  }

  private updateForbiddenPortalAsset(): void {
    this.isForbiddenPortalAsset =
      this.withAlreadyLinkedCheck && this.contentItem.hasLinkedPortalAssets && !this.isAlreadyLinked;
  }

  private computeContextMenuPermissions(): void {
    this.isVisitor$ = this.permissionUtil.hasRoleForCurrentWorkroom(Roles.VISITOR).pipe(shareReplay(1));

    this.hasPortalAssetsInSelection$ = this.lastSelection$.pipe(
      map(selection => selection.some(item => item.hasLinkedPortalAssets))
    );

    this.canDeleteFile$ = this.lastSelection$.pipe(
      map(
        selection =>
          isNullOrUndefined(this.task) &&
          selection.every(item => !item.hasLinkedPortalAssets || item.numberOfTasks === 0)
      )
    );

    this.canRemoveFileFromTask$ = combineLatest([
      this.store.select(selectLoggedInPersonHasTaskPermission(Permission.TASK_UPDATE)),
      this.hasPortalAssetsInSelection$
    ]).pipe(
      map(([canUpdateTask, hasPortalAssetsInSelection]) => this.task && canUpdateTask && !hasPortalAssetsInSelection)
    );

    this.isDownloadable$ = this.contentItem
      ?.activeVersion(this.store)
      .pipe(map(activeVersion => activeVersion?.downloadUrl && super.getSelectionLength() <= 1));

    this.printingIsEnabled$ = this.fileUtilService
      .hasAppropriateFileFormat(this.contentItem.activeVersion(this.store), PrintService.appropriateFormats)
      .pipe(map(isPrintable => isPrintable && this.getSelectionLength() <= 1));

    this.exportingAnnotatedPdfIsEnabled$ = this.fileUtilService
      .hasAppropriateFileFormat(
        this.contentItem.activeVersion(this.store),
        ExportAnnotatedPdfService.appropriateFormats
      )
      .pipe(map(isExportable => isExportable && this.getSelectionLength() <= 1));

    this.activeVersionNumber$ = this.contentItem
      ?.activeVersion(this.store)
      .pipe(map(activeVersion => activeVersion?.versionNumber));

    this.canExportToCH$ = this.store.select(selectWorkroomConfiguredForContentHub).pipe(
      withLatestFrom(this.chStrategyResolver.resolve()),
      map(
        ([teamspaceConfiguredForCH, strategy]) =>
          teamspaceConfiguredForCH && super.allSelectionsOfType('File') && strategy.supportsRelayCalls()
      )
    );

    this.canRenameFile$ = this.isVisitor$.pipe(map(isVisitor => !isVisitor && this.getSelectionLength() <= 1));

    this.bulkDownloadEnabled$ = this.lastSelection$.pipe(
      map(selection => this.allSelectionsOfType('File') && selection.length > 1)
    );

    this.canUploadNewVersion$ = this.lastSelection$.pipe(
      map(selection => this.contentItem?.relationId && selection.length <= 1)
    );

    this.canImportFromCH$ = this.store.select(selectCanImport).pipe(
      withLatestFrom(this.chStrategyResolver.resolve()),
      map(([canImport, strategy]) => canImport && this.getSelectionLength() <= 1 && strategy.supportsRelayCalls())
    );

    this.canMoveFile$ = this.hasPortalAssetsInSelection$.pipe(
      withLatestFrom(this.isVisitor$),
      map(([portalAssetSelected, isVisitor]) => !isVisitor && !portalAssetSelected && !this.movingDisabled)
    );
  }
}
