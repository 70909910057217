import { Pipe, PipeTransform } from '@angular/core';

import { PreviewUtil } from '../util';

@Pipe({
  name: 'fileExtension',
  standalone: false
})
export class FileExtensionPipe implements PipeTransform {
  public transform(fileName: string): string {
    return PreviewUtil.getExtension(fileName);
  }
}
