import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

import { IconConfiguration } from '@celum/common-components';

@Component({
  selector: 'create-board-button',
  templateUrl: './create-board-button.component.html',
  styleUrls: ['./create-board-button.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class CreateBoardButtonComponent {
  @Input() public icon: IconConfiguration;
  @Input() public topText: string;
  @Input() public bottomText: string;
  @Input() public disabled: boolean;

  @Output() public readonly onHeaderClicked = new EventEmitter();

  @HostBinding('class') public hostClass = 'create-board';

  public headerClicked() {
    this.onHeaderClicked.emit();
  }
}
