import { createEntityAdapter, EntityAdapter, Update } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { produce } from 'immer';
import { groupBy, uniq, without } from 'lodash';

import { DataUtil } from '@celum/core';
import { SubscriptionCreated, SubscriptionDeleted } from '@celum/work/app/core/api/subscription/subscription.actions';
import {
  TaskListAddOwnerSuccess,
  TaskListRemoveOwnerSuccess
} from '@celum/work/app/core/api/task-list/task-list.actions';
import {
  WorkroomDeleteWorkroom,
  WorkroomDeleteWorkroomFailed,
  WorkroomsUpdateContentItemCount
} from '@celum/work/app/core/api/workroom/workroom.actions';
import {
  WorkroomContributorsInviteSuccessful,
  WorkroomContributorsRemoveSuccess
} from '@celum/work/app/core/api/workroom-contributor/workroom-contributor.actions';
import { EntityUtil } from '@celum/work/app/core/model';
import { Contributor } from '@celum/work/app/core/model/entities/contributor';
import { mergeEntities, mergeEntity } from '@celum/work/app/core/model/entities/entities-state-util';
import { AutomatorType, TaskAddAssigneesAction } from '@celum/work/app/core/model/entities/workroom/robot.model';
import { getTaskListAutomatorsOfType } from '@celum/work/app/robots/services/robots-util';

import {
  WorkroomsUpsertConfiguration,
  WorkroomsUpsertDriveSubscription,
  WorkroomsUpsertMany,
  WorkroomsUpsertOne,
  WorkroomsUpsertPermissions
} from './workroom.actions';
import { Workroom, WorkroomState } from './workroom.model';
import { ContributorsUpsertMany, ContributorsUpsertOne } from '../contributor/contributor.actions';

export const workroomAdapter: EntityAdapter<Workroom> = createEntityAdapter<Workroom>();

export const initialState: WorkroomState = workroomAdapter.getInitialState();

const properties = [
  'name',
  'description',
  'creationDate',
  'dueDate',
  'status',
  'teamspaceId',
  'configuration',
  'slibResourceToken',
  'driveSubscribed',
  'lastActivity',
  'numberOfFiles',
  'numberOfTasks'
];

export const workroomUpdatableProperties = [
  'name',
  'description',
  'creationDate',
  'dueDate',
  'status',
  'teamspaceId',
  'configuration',
  'contentHubRepositoryId',
  'contributorIds'
];

const reducer = createReducer(
  initialState,
  on(WorkroomsUpsertOne, (state: WorkroomState, { workroom, propertiesToUpdate }) => {
    const workrooms = EntityUtil.changedEntities(properties, [workroom], state.entities, isColorEqual);

    if (!DataUtil.isEmpty(workrooms)) {
      return workroomAdapter.upsertOne(mergeEntity(workrooms[0], state, propertiesToUpdate), state);
    }
    return state;
  }),

  on(WorkroomsUpsertMany, (state: WorkroomState, { workrooms, propertiesToUpdate }) => {
    const changedWorkrooms = EntityUtil.changedEntities(properties, workrooms, state.entities, isColorEqual);
    return workroomAdapter.upsertMany(mergeEntities(changedWorkrooms, state, propertiesToUpdate), state);
  }),

  on(WorkroomDeleteWorkroom, (state: WorkroomState, { workroom }) => {
    return workroomAdapter.removeOne(workroom.id, state);
  }),

  on(WorkroomDeleteWorkroomFailed, (state: WorkroomState, { workroom }) => {
    return workroomAdapter.upsertOne(workroom, state);
  }),

  on(WorkroomsUpsertPermissions, (state: WorkroomState, { workroomId, permissions }) => {
    if (state.entities[workroomId]) {
      return workroomAdapter.upsertOne(
        {
          ...state.entities[workroomId],
          permissions: permissions
        },
        state
      );
    }
    return state;
  }),

  on(WorkroomsUpsertConfiguration, (state: WorkroomState, { workroomId, configuration }) => {
    if (state.entities[workroomId]) {
      return workroomAdapter.upsertOne(
        {
          ...state.entities[workroomId],
          configuration: configuration
        },
        state
      );
    }
    return state;
  }),

  on(WorkroomsUpsertDriveSubscription, (state: WorkroomState, { workroomId, driveSubscribed }) => {
    if (state.entities[workroomId]) {
      return workroomAdapter.upsertOne(
        {
          ...state.entities[workroomId],
          driveSubscribed
        },
        state
      );
    }
    return state;
  }),

  on(ContributorsUpsertOne, (state: WorkroomState, { contributor }) => {
    const workroomUpdate: Update<Workroom> = {
      id: contributor.workroomId,
      changes: {
        contributorIds: uniq([...(state.entities[contributor.workroomId]?.contributorIds || []), contributor.id])
      }
    };
    return workroomAdapter.updateOne(workroomUpdate, state);
  }),

  on(ContributorsUpsertMany, (state: WorkroomState, data) => {
    const workroomUpdates: Update<Workroom>[] = Object.entries(groupBy(data.contributors, 'workroomId')).map(
      ([workroomId, contributors]: [string, Contributor[]]) => ({
        id: workroomId,
        changes: {
          contributorIds: uniq([
            ...(state.entities[workroomId]?.contributorIds || []),
            ...contributors.map(({ id }) => id)
          ])
        }
      })
    );
    return workroomAdapter.updateMany(workroomUpdates, state);
  }),

  on(WorkroomContributorsRemoveSuccess, (state: WorkroomState, { contributors }) => {
    const workroomId = contributors.map(contributor => contributor.workroomId)[0];
    const contributorIds = contributors.map(contributor => contributor.id);
    const workroomUpdate: Update<Workroom> = {
      id: workroomId,
      changes: {
        contributorIds: without(state.entities[workroomId]?.contributorIds, ...contributorIds)
      }
    };
    return workroomAdapter.updateOne(workroomUpdate, state);
  }),

  on(SubscriptionCreated, (state: WorkroomState, { workroomId }) => {
    const workroomUpdate: Update<Workroom> = {
      id: workroomId,
      changes: {
        driveSubscribed: true
      }
    };
    return workroomAdapter.updateOne(workroomUpdate, state);
  }),

  on(SubscriptionDeleted, (state: WorkroomState, { workroomId }) => {
    const workroomUpdate: Update<Workroom> = {
      id: workroomId,
      changes: {
        driveSubscribed: false
      }
    };

    return workroomAdapter.updateOne(workroomUpdate, state);
  }),

  on(WorkroomsUpdateContentItemCount, (state: WorkroomState, { workroomId, count }) => {
    const updatedWorkroom = { ...state.entities[workroomId], contentItemCount: count };
    return workroomAdapter.upsertOne(updatedWorkroom, state);
  }),

  on(TaskListAddOwnerSuccess, (state: WorkroomState, { taskList, personId }) =>
    produce(state, draft => {
      const workroom = draft.entities[taskList.workroomId];
      const automator = getTaskListAutomatorsOfType(
        workroom.configuration,
        taskList.id,
        AutomatorType.TASK_ASSIGNMENT
      ).pop();
      const automatorAction = automator?.action as TaskAddAssigneesAction;
      if (automatorAction?.allTaskListOwners) {
        automatorAction.personIds.push(+personId);
      }
    })
  ),

  on(TaskListRemoveOwnerSuccess, (state: WorkroomState, { taskList, personId }) =>
    produce(state, draft => {
      const workroom = draft.entities[taskList.workroomId];
      const automator = getTaskListAutomatorsOfType(
        workroom.configuration,
        taskList.id,
        AutomatorType.TASK_ASSIGNMENT
      ).pop();
      const automatorAction = automator?.action as TaskAddAssigneesAction;
      if (automatorAction?.allTaskListOwners) {
        automatorAction.personIds = automatorAction.personIds.filter(
          automatorPersonId => automatorPersonId !== +personId
        );
      }
    })
  ),
  on(WorkroomContributorsInviteSuccessful, (state: WorkroomState, { workroomId, contributors }) => {
    const workroomUpdate: Update<Workroom> = {
      id: workroomId,
      changes: {
        contributorIds: uniq([
          ...(state.entities[workroomId]?.contributorIds || []),
          ...contributors.map(({ id }) => id)
        ])
      }
    };
    return workroomAdapter.updateOne(workroomUpdate, state);
  })
);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export function workroomReducer(state: WorkroomState = initialState, action: Action): WorkroomState {
  return reducer(state, action);
}

function isColorEqual(newWr: Workroom, oldWr: Workroom): boolean {
  // if colors are not equal and one of them is null, they are not equal!
  // eslint-disable-next-line eqeqeq
  if (newWr.color != oldWr.color && (newWr.color == null || oldWr.color == null)) {
    return false;
  }

  // eslint-disable-next-line eqeqeq
  if (newWr.color == oldWr.color) {
    return true;
  }

  return (
    newWr.color.red === oldWr.color.red &&
    newWr.color.green === oldWr.color.green &&
    newWr.color.blue === oldWr.color.blue
  );
}
