import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  Host,
  HostBinding,
  OnInit,
  Output,
  SkipSelf,
  ViewEncapsulation
} from '@angular/core';
import { Observable } from 'rxjs';

import { ReactiveComponent } from '@celum/ng2base';
import { SharedModule } from '@celum/work/app/shared';

import { SearchAndSelectGenericItem, SearchAndSelectGenericService } from './search-and-select-generic.base';

@Component({
  selector: 'search-and-select-generic',
  templateUrl: './search-and-select-generic.component.html',
  styleUrls: ['./search-and-select-generic.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports: [CommonModule, SharedModule]
})
export class SearchAndSelectGenericComponent<T extends SearchAndSelectGenericItem>
  extends ReactiveComponent
  implements OnInit
{
  @HostBinding('class') public componentCls = 'search-and-select-generic';

  @ContentChild('itemTemplate', { static: true }) public itemTemplateRef: any;

  public readonly displayedValue: string;
  public readonly noResultsKey: string;
  public readonly hasMoreKey: string;
  public readonly hasMoreKeySingular: string;
  public readonly hasMoreKeyPlural: string;
  public readonly searchFieldLabelKey: string;
  public readonly placeholder: string;
  public readonly itemsToLoad: number;

  public readonly items$: Observable<T[]>;
  public readonly loading$: Observable<boolean>;
  public readonly hasMore$: Observable<boolean>;
  public readonly hasMoreAmount$: Observable<number>;

  @Output() public elementSelected = new EventEmitter<any>();

  constructor(@SkipSelf() @Host() private searchAndSelectGenericService: SearchAndSelectGenericService<T>) {
    super();

    this.displayedValue = this.searchAndSelectGenericService.displayedValue;
    this.noResultsKey = this.searchAndSelectGenericService.noResultsKey;
    this.hasMoreKey = this.searchAndSelectGenericService.hasMoreKey;
    this.hasMoreKeySingular = this.searchAndSelectGenericService.hasMoreKeySingular;
    this.hasMoreKeyPlural = this.searchAndSelectGenericService.hasMoreKeyPlural;
    this.searchFieldLabelKey = this.searchAndSelectGenericService.searchFieldLabelKey;
    this.placeholder = this.searchAndSelectGenericService.placeholder;
    this.itemsToLoad = this.searchAndSelectGenericService.itemsToLoad;

    this.items$ = this.searchAndSelectGenericService.items$;
    this.loading$ = this.searchAndSelectGenericService.loading$;
    this.hasMore$ = this.searchAndSelectGenericService.hasMore$;
    this.hasMoreAmount$ = this.searchAndSelectGenericService.hasMoreAmount$;
  }

  public ngOnInit(): void {
    this.searchEntities();
  }

  public searchEntities(query?: string): void {
    this.searchAndSelectGenericService.loadEntities(query);
  }
}
