import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, EMPTY, Observable, of } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';

import { IconConfiguration } from '@celum/common-components';
import { isTruthy } from '@celum/core';
import { Permission } from '@celum/work/app/core/api/permission';
import { MembershipStatus } from '@celum/work/app/core/model/entities/member';
import { selectAllMembers } from '@celum/work/app/core/model/entities/member/member.selectors';
import { Teamspace } from '@celum/work/app/core/model/entities/teamspace';
import { Workroom, WorkroomStatus } from '@celum/work/app/core/model/entities/workroom';
import { tryToParseInt } from '@celum/work/app/shared/util/number-util';

@Component({
  selector: 'workroom-context-menu',
  templateUrl: './workroom-context-menu.component.html',
  styleUrls: ['./workroom-context-menu.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class WorkroomContextMenuComponent implements OnChanges {
  @Input() public workroom: Workroom;
  @Input() public teamspace: Teamspace;

  @Output() public readonly executeActionForPermission: EventEmitter<{
    permission: WorkroomContextMenuPermissions;
  }> = new EventEmitter<{ permission: WorkroomContextMenuPermissions }>();

  public readonly menuItems$ = new BehaviorSubject<WorkroomContextMenuItem[]>([]);

  public readonly menuIcon = IconConfiguration.large('option-m');
  private readonly finishIcon = IconConfiguration.small('finish-l');
  private readonly deleteIcon = IconConfiguration.small('icon-16-delete');
  private readonly reopenIcon = IconConfiguration.small('key-reversed');

  constructor(private store: Store<any>) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.workroom || changes.teamspace) {
      this.menuItems$.next(this.getMenuItems());
    }
  }

  public trackByFn(index: number, item: WorkroomContextMenuItem) {
    return item.name || index;
  }

  public getReopenMenuItem(): WorkroomContextMenuItem {
    const menuItem: WorkroomContextMenuItem = {
      icon: this.reopenIcon,
      name: 'WORKROOM_CONTEXT_MENU.REOPEN',
      associatedPermission: Permission.WORKROOM_REOPEN,
      disabled$: EMPTY,
      disabledTooltip$: EMPTY,
      actionFn: () => this.reopenWorkroom()
    };

    if (this.teamspace.workroomsUsed >= this.teamspace.workroomsLimit) {
      return {
        ...menuItem,
        disabled$: of(true),
        disabledTooltip$: of('TEMPLATE_CHOOSER.LICENSE_LIMIT.INFO')
      };
    }

    const hasOnlyDisabledContributors$ = this.workroom.contributors(this.store).pipe(
      map(contributors => contributors.map(({ personId }) => personId)),
      withLatestFrom(this.store.select(selectAllMembers)),
      map(([contributingPersonIds, allMembers]) =>
        allMembers.filter(member => contributingPersonIds.includes(tryToParseInt(member.personId)))
      ),
      map(workroomMembers => !workroomMembers.some(member => member.status === MembershipStatus.ACTIVE))
    );
    menuItem.disabled$ = hasOnlyDisabledContributors$;
    menuItem.disabledTooltip$ = hasOnlyDisabledContributors$.pipe(
      isTruthy(),
      map(_ => 'WORKROOM.REOPEN_DISABLED')
    );
    return menuItem;
  }

  public getDeleteMenuItem(): WorkroomContextMenuItem {
    return {
      icon: this.deleteIcon,
      name: 'WORKROOM_CONTEXT_MENU.DELETE',
      associatedPermission: Permission.WORKROOM_DELETE,
      disabled$: EMPTY,
      disabledTooltip$: EMPTY,
      actionFn: () => this.deleteWorkroom()
    };
  }

  public getFinishMenuItem(): WorkroomContextMenuItem {
    return {
      icon: this.finishIcon,
      name: 'WORKROOM_CONTEXT_MENU.FINISH',
      associatedPermission: Permission.WORKROOM_FINISH,
      disabled$: EMPTY,
      disabledTooltip$: EMPTY,
      actionFn: () => this.finishWorkroom()
    };
  }

  private reopenWorkroom(): void {
    this.executeActionForPermission.emit({ permission: Permission.WORKROOM_REOPEN });
  }

  private finishWorkroom(): void {
    this.executeActionForPermission.emit({
      permission: Permission.WORKROOM_FINISH
    });
  }

  private deleteWorkroom(): void {
    this.executeActionForPermission.emit({ permission: Permission.WORKROOM_DELETE });
  }

  private getMenuItems(): WorkroomContextMenuItem[] {
    if (this.workroom?.status === WorkroomStatus.INACTIVE) {
      return [this.getDeleteMenuItem(), this.getReopenMenuItem()];
    }

    return [this.getFinishMenuItem()];
  }
}

export type WorkroomContextMenuPermissions =
  | Permission.WORKROOM_DELETE
  | Permission.WORKROOM_FINISH
  | Permission.WORKROOM_REOPEN;

export interface WorkroomContextMenuItem {
  icon: IconConfiguration;
  name: string;
  associatedPermission: WorkroomContextMenuPermissions;
  disabledTooltip$: Observable<string>;
  disabled$: Observable<boolean>;
  actionFn: () => void;
}
