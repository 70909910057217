import { ConnectionPositionPair, Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Injector,
  Input,
  OnDestroy,
  Output,
  Type
} from '@angular/core';

@Directive({
  selector: '[celumTooltip]',
  standalone: false
})
export class CelumTooltipDirective implements OnDestroy {
  @Input() public componentType: Type<any>;
  @Input() public componentInjector: Injector;
  @Input() public tooltipClass: string;
  @Output() public readonly configure: EventEmitter<any> = new EventEmitter<any>();

  private overlay: OverlayRef;
  private dialogContent: ComponentPortal<any>;

  constructor(
    private elementRef: ElementRef,
    private overlayService: Overlay
  ) {}

  public ngOnDestroy(): void {
    this.removeTooltip();
  }

  @HostListener('click')
  public onClick(): void {
    this.removeTooltip();
  }

  @HostListener('mouseenter')
  public createTooltip(): void {
    if (!this.componentType) {
      return;
    }

    if (!this.overlay) {
      this.dialogContent = new ComponentPortal(this.componentType, null, this.componentInjector);
      const positions = [
        new ConnectionPositionPair(
          {
            originX: 'start',
            originY: 'bottom'
          },
          {
            overlayX: 'start',
            overlayY: 'top'
          }
        ),
        new ConnectionPositionPair(
          {
            originX: 'start',
            originY: 'top'
          },
          {
            overlayX: 'start',
            overlayY: 'bottom'
          }
        )
      ];

      this.overlay = this.overlayService.create({
        panelClass: this.tooltipClass,
        positionStrategy: this.overlayService
          .position()
          .flexibleConnectedTo(this.elementRef.nativeElement)
          .withPositions(positions),
        hasBackdrop: false
      });
    }
    if (!this.overlay.hasAttached()) {
      const attachResult = this.overlay.attach(this.dialogContent);
      this.configure.emit(attachResult.instance);
    }
  }

  @HostListener('mouseleave')
  public removeTooltip() {
    this.overlay?.detach();
  }
}
