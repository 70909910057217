import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { ProgressTaskType } from '@celum/work/app/progress-task/store/progress-task.model';
import { selectProgressTaskTypes } from '@celum/work/app/progress-task/store/progress-task.selectors';

@Component({
  selector: 'progress-task-snackbar-group-wrapper',
  templateUrl: './progress-task-snackbar-group-wrapper.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class ProgressTaskSnackbarGroupWrapperComponent implements OnInit {
  public progressTasksTypes$: Observable<ProgressTaskType[]>;

  constructor(private store: Store<any>) {}

  public ngOnInit(): void {
    this.progressTasksTypes$ = this.store.select(selectProgressTaskTypes);
  }

  public trackByFn(index, item: ProgressTaskType) {
    return item || index;
  }
}
