import { ChangeDetectionStrategy, Component, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'task-list-column-setting',
  templateUrl: './task-list-column-setting.component.html',
  styleUrls: ['./task-list-column-setting.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class TaskListColumnSetting implements OnChanges {
  @Input() public columns: number;
  @Input() public active: boolean;

  public entries: any[] = [];

  @HostBinding('attr.data-cy') public get attrDataCy(): string {
    return `task-list-visible-columns-setting-${this.columns}`;
  }

  public ngOnChanges({ columns }: SimpleChanges) {
    if (columns) {
      this.entries = Array(columns.currentValue);
    }
  }

  public trackByFn(idx: number): number {
    return idx;
  }
}
