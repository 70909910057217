import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { Observable } from 'rxjs';

import { ColorConstants, IconConfiguration } from '@celum/common-components';
import { RoleName } from '@celum/work/app/core/api/permission';
import { PredefinedCategoryValues } from '@celum/work/app/core/model/entities/template-category/template-category.model';
import { PermissionUtil } from '@celum/work/app/shared/util';

import { Color } from '../../../../core/model';
import { TaskList } from '../../../../core/model/entities/task';
import { Template } from '../../../../core/model/entities/template/template.model';
import { ColorService } from '../../../../shared/util/color.service';

@Component({
  selector: 'template-chooser-template',
  templateUrl: './template-chooser-template.component.html',
  styleUrls: ['./template-chooser-template.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: false
})
export class TemplateChooserTemplateComponent implements OnInit {
  @Input() public template: Template;
  @Input() public isAllowedToUpdate = false;
  @Input() public isAllowedToDelete = false;
  @Input() public isAllowedToCreate = false;

  @Output() public readonly onCopy = new EventEmitter();
  @Output() public readonly onMove = new EventEmitter();
  @Output() public readonly onEdit = new EventEmitter();
  @Output() public readonly onDelete = new EventEmitter();
  @Output() public readonly onCreate = new EventEmitter();

  public canCreateTemplate$: Observable<boolean>;
  public PredefinedCategoryValues = PredefinedCategoryValues;

  public readonly menuActionsIcon = {
    EDIT: IconConfiguration.medium('edit-x').withColor(ColorConstants.BLUE_GRAY_900),
    COPY: IconConfiguration.medium('duplicate-x').withColor(ColorConstants.BLUE_GRAY_900),
    MOVE: IconConfiguration.medium('move-x').withColor(ColorConstants.BLUE_GRAY_900),
    DELETE: IconConfiguration.medium('remove-x').withColor(ColorConstants.BLUE_GRAY_900)
  };

  constructor(private permissionUtil: PermissionUtil) {}

  public ngOnInit(): void {
    this.canCreateTemplate$ = this.permissionUtil.hasTeamspaceRole(RoleName.TEMPLATE_MAINTAINER);
  }

  public getRGBHex(color: Color) {
    return ColorService.getColorAsRgbString(color);
  }

  public trackByFn(index: number, taskList: TaskList) {
    return taskList ? taskList.id : index;
  }

  public copyTemplate(e: MouseEvent) {
    e.stopPropagation();
    this.onCopy.emit(this.template);
  }

  public moveTemplate(e: MouseEvent) {
    e.stopPropagation();
    this.onMove.emit(this.template);
  }

  public editTemplate(e: MouseEvent) {
    e.stopPropagation();
    this.onEdit.emit(this.template);
  }

  public deleteTemplate(e: MouseEvent) {
    e.stopPropagation();
    this.onDelete.emit(this.template);
  }

  public createWorkroomFromTemplate(e: MouseEvent) {
    e.stopPropagation();

    if (this.isAllowedToCreate) {
      this.onCreate.emit();
    }
  }
}
