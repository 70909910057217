import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  HostListener,
  Input,
  OnInit,
  Optional,
  ViewEncapsulation
} from '@angular/core';
import { isEqual } from 'lodash';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';

import { CelumIconModule } from '@celum/common-components';
import { AbstractList, CelumList, CelumSimpleList, SelectionTriggerComponent } from '@celum/internal-components';
import { ReactiveComponent } from '@celum/ng2base';
import { isNullOrUndefined } from '@celum/work/app/shared/util/typescript-util';

@Component({
  selector: 'selection-trigger-wrapper',
  templateUrl: './selection-trigger-wrapper.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports: [CommonModule, CelumIconModule, SelectionTriggerComponent]
})
export class SelectionTriggerWrapperComponent extends ReactiveComponent implements OnInit {
  @Input() public size: 'medium' | 'large' = 'large';
  @Input() public item: any;

  @HostBinding('attr.data-cy') public attrDataCy = 'selection-trigger-wrapper';

  public isMultiSelectionMode$: Observable<boolean>;
  public isItemSelected$: Observable<boolean>;
  public enabled = true;

  constructor(
    @Optional() private simpleList: CelumSimpleList,
    @Optional() private celumList: CelumList<any>
  ) {
    super();
  }

  public get list(): AbstractList<any> {
    return this.simpleList ?? this.celumList;
  }

  public ngOnInit(): void {
    if (isNullOrUndefined(this.list.selectionHandler)) {
      this.enabled = false;
      return;
    }

    const selectionChanged$ = this.list.selectionHandler.selectionChanged$.pipe(
      takeUntil(this.unsubscribe$),
      distinctUntilChanged((prev, curr) => isEqual(prev, curr))
    );

    this.isMultiSelectionMode$ = selectionChanged$.pipe(map(selection => selection.length > 0));

    this.isItemSelected$ = selectionChanged$.pipe(map(() => this.list.selectionHandler.isInSelection(this.item)));
  }

  @HostListener('click', ['$event'])
  public click(e: MouseEvent) {
    e.stopPropagation();
    this.list.selectionHandler.handleItemClicked(e, this.item);
  }
}
