import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';

import { TaskList } from '../../../../core/model/entities/task';
import { ColorService } from '../../../util/color.service';

@Component({
  selector: 'search-and-select-task-list',
  templateUrl: './search-and-select-task-list.component.html',
  styleUrls: ['./search-and-select-task-list.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: false
})
export class SearchAndSelectTaskListComponent {
  @HostBinding('class') public hostCls = `search-and-select-task-lists mat-elevation-z4`;

  @Input() public taskLists: TaskList[];
  @Input() public selectedTaskLists: TaskList[] = [];

  @Output() public readonly elementSelected = new EventEmitter<TaskList>();
  @Output() public readonly elementDeselected = new EventEmitter<TaskList>();

  public getTaskListColor(taskList: TaskList): string {
    return ColorService.getColorAsRgbString(taskList.color);
  }
}
