import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Injector,
  Input,
  OnInit,
  Output,
  Type,
  ViewEncapsulation
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  NgControl,
  ReactiveFormsModule,
  Validator
} from '@angular/forms';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { map, Observable, takeUntil } from 'rxjs';

import { IconConfiguration } from '@celum/common-components';
import { CelumValidators, ReactiveComponent } from '@celum/ng2base';
import {
  CustomFieldDropdownOption,
  CustomFieldTypes
} from '@celum/work/app/core/model/entities/custom-field/custom-field.model';
import { SharedModule } from '@celum/work/app/shared';
import { StringUtil } from '@celum/work/app/shared/util/string.util';
import { CustomFieldCardReadOnlyViewComponent } from '@celum/work/app/teamspace-management/components/fields-overview-tab/components/custom-field-card/custom-field-card-read-only-view/custom-field-card-read-only-view.component';
import { CustomFieldCardChipsComponent } from '@celum/work/app/teamspace-management/components/fields-overview-tab/components/custom-field-card/custom-field-chips-input/custom-field-card-chips/custom-field-card-chips.component';

import { customFieldCardValidator } from './config/custom-field-card.validator';
import { CustomFieldCardValidationErrors, CustomFieldCardValidationErrorsEnum } from './config/validation-errors.enum';
import { CustomFieldChipsInputComponent } from './custom-field-chips-input/custom-field-chips-input.component';

export interface CustomFieldCardControlValue {
  name: string | null;
  type: CustomFieldTypes | null;
  chips?: CustomFieldDropdownOption[];
}

export type CustomFieldCardControlStructure = {
  name: FormControl<string | null>;
  type: FormControl<CustomFieldTypes | null>;
  chips?: FormControl<CustomFieldDropdownOption[]>;
};

@Component({
  selector: 'custom-field-card',
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatChipsModule,
    ReactiveFormsModule,
    SharedModule,
    CustomFieldChipsInputComponent,
    CustomFieldCardChipsComponent,
    CustomFieldCardReadOnlyViewComponent
  ],
  templateUrl: './custom-field-card.component.html',
  styleUrls: ['./custom-field-card.component.less'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomFieldCardComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CustomFieldCardComponent),
      multi: true
    }
  ]
})
export class CustomFieldCardComponent extends ReactiveComponent implements OnInit, ControlValueAccessor, Validator {
  @Input() public readonly isReadOnly: boolean = false;
  @Input() public readonly isRemovable: boolean = false;
  @Input() public readonly isTypeChangingAllowed: boolean = true;

  @Output() public readonly removeClicked: EventEmitter<void> = new EventEmitter<void>();

  public readonly TEXT_CONTROL_LIMIT = 30;

  public customFieldGroup = new FormGroup<CustomFieldCardControlStructure>(
    {
      name: new FormControl<string | null>(null, [CelumValidators.required]),
      type: new FormControl<CustomFieldTypes | null>(null),
      chips: new FormControl<CustomFieldDropdownOption[]>({ value: null, disabled: true })
    },
    { validators: customFieldCardValidator() }
  );
  public controls: CustomFieldCardControlStructure = {
    name: this.customFieldGroup.controls.name,
    type: this.customFieldGroup.controls.type,
    chips: this.customFieldGroup.controls.chips
  };
  public ngControl: FormControl | null;

  public readonly cancelIcon: IconConfiguration = IconConfiguration.medium('cancel-m');

  public isExpanded = true;
  public customFieldType: typeof CustomFieldTypes = CustomFieldTypes;
  public validationEnum: typeof CustomFieldCardValidationErrorsEnum = CustomFieldCardValidationErrorsEnum;
  public onChangeFn: (value: CustomFieldCardControlValue) => void;
  public onTouchFn: () => void;

  constructor(
    private injector: Injector,
    private cdr: ChangeDetectorRef,
    private stringUtil: StringUtil
  ) {
    super();
  }

  public ngOnInit(): void {
    // getting access to ControlContainer, so I can intercept if I have any errors given me by outside
    this.ngControl = this.getNgControl();
    this.listenToNgControlErrors$(this.ngControl).subscribe(errors => this.setIncomingValidationErrors(errors));

    this.customFieldGroup.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        map(() => this.customFieldGroup.getRawValue())
      )
      .subscribe(({ name, type, chips }: CustomFieldCardControlValue) => {
        this.updateDropdownControlDisabledState(type, true);
        this.onChangeFn({ name, type, ...(chips && type === CustomFieldTypes.DROPDOWN && { chips }) });
      });
  }

  public writeValue(value: CustomFieldCardControlValue | null): void {
    if (value) {
      this.customFieldGroup.setValue(
        {
          name: value.name,
          type: value.type,
          chips: value.chips || []
        },
        { emitEvent: false }
      );

      this.updateDropdownControlDisabledState(value.type);
    }
  }

  public registerOnChange(fn: (value: CustomFieldCardControlValue) => void): void {
    this.onChangeFn = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouchFn = fn;
  }

  public validate(): CustomFieldCardValidationErrors | null {
    return customFieldCardValidator()(this.customFieldGroup);
  }

  public setDisabledState(isDisabled: boolean): void {
    isDisabled
      ? this.customFieldGroup.disable({ emitEvent: false })
      : this.customFieldGroup.enable({ emitEvent: false });

    if (this.isTypeChangingAllowed === false) {
      this.controls.type.disable({ emitEvent: false });
    }
  }

  public customFieldTypeCompare(type1: CustomFieldTypes, type2: CustomFieldTypes): boolean {
    return type1 === type2;
  }

  public toggleExpansion(explicitValue: boolean | null = null): void {
    this.isExpanded = explicitValue === null ? !this.isExpanded : explicitValue;
    this.cdr.detectChanges();
  }

  private updateDropdownControlDisabledState(type: CustomFieldTypes, triggerChangeDetection = false): void {
    if (type === CustomFieldTypes.DROPDOWN) {
      this.controls.chips.enable({ emitEvent: false });
    } else {
      this.controls.chips.disable({ emitEvent: false });
    }
    if (triggerChangeDetection) {
      this.cdr.detectChanges();
    }
  }
  private getNgControl(): FormControl | null {
    const ngControl = this.injector.get<NgControl>(NgControl as Type<NgControl>);

    return ngControl ? (ngControl.control as FormControl) : null;
  }

  private listenToNgControlErrors$(ngControl: FormControl): Observable<CustomFieldCardValidationErrors | null> {
    return ngControl.statusChanges.pipe(
      takeUntil(this.unsubscribe$),
      map(() => ngControl.errors)
    );
  }

  private setIncomingValidationErrors(errors: CustomFieldCardValidationErrors | null): void {
    if (errors?.NAME_NOT_UNIQUE) {
      this.controls.name.setValue(this.stringUtil.collapseWhitespacesBetweenWords(this.controls.name.value.trim()));
      this.controls.name.setErrors({ [CustomFieldCardValidationErrorsEnum.NAME_NOT_UNIQUE]: true });
      this.cdr.detectChanges();
    }
  }
}
