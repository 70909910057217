import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isSelected',
  standalone: false
})
export class IsSelectedPipe implements PipeTransform {
  public transform(value: any, ...args: any[]): boolean {
    return args[0].map(({ id }) => id).includes(value);
  }
}
