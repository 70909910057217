import { ChangeDetectionStrategy, Component, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { take, withLatestFrom } from 'rxjs/operators';

import { CelumDialogOpener } from '@celum/internal-components';

import { StorageBannerTranslationsPipeArgs } from './storage-banner-translations.pipe';
import { selectCurrentAccountAccess, selectIsAccountOwner } from '../../../../core/ui-state/ui-state.selectors';
import {
  ContactDialog,
  ContactDialogConfiguration,
  QuotaType
} from '../../../../pages/workroom-creator/components/contact-dialog/contact-dialog.component';

@Component({
  selector: 'content-item-list-banner',
  templateUrl: './storage-limit-banner.html',
  styleUrls: ['./storage-limit-banner.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class StorageLimitBannerComponent implements OnChanges {
  @HostBinding('class') public hostClass = 'content-item-list-banner';

  @Input() public usedStoragePercentage: number;
  @Input() public used3DPercentage: number;
  @Input() public isOwner: boolean;

  public headlineAndTextPipeArgs: StorageBannerTranslationsPipeArgs;

  constructor(
    private store: Store<any>,
    private dialogOpener: CelumDialogOpener
  ) {}

  public ngOnChanges({ usedStoragePercentage, used3DPercentage }: SimpleChanges) {
    this.headlineAndTextPipeArgs = {
      usedStoragePercentage: usedStoragePercentage?.currentValue ?? this.usedStoragePercentage,
      used3DConversionsPercentage: used3DPercentage?.currentValue ?? this.used3DPercentage
    };
  }

  public onStorageActionInvoked(): void {
    this.store
      .select(selectIsAccountOwner)
      .pipe(withLatestFrom(this.store.select(selectCurrentAccountAccess)), take(1))
      .subscribe(([isOwner, accountAccess]) => {
        if (isOwner) {
          window.open(
            `${(window as any).Celum.properties.saccHttpBaseAddress}/myaccount/${accountAccess.accountId}`,
            '_blank'
          );
        } else {
          const dialogConfig = new ContactDialogConfiguration(
            accountAccess,
            this.getQuotaTypes(this.usedStoragePercentage, this.used3DPercentage)
          );
          this.dialogOpener.showDialog(ContactDialog.name, ContactDialog, dialogConfig);
        }
      });
  }

  private getQuotaTypes(usedStoragePercentage: number, used3DConversionsPercentage: number): QuotaType[] {
    const quotaTypes: QuotaType[] = [];

    if (usedStoragePercentage >= 90) {
      quotaTypes.push(QuotaType.STORAGE);
    }
    if (used3DConversionsPercentage >= 90) {
      quotaTypes.push(QuotaType.X3D_CONVERSION);
    }

    return quotaTypes;
  }
}
