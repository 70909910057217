import { Directive, EventEmitter, HostBinding, HostListener, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { isEqual } from 'lodash';

import { ShowSnackbar, SimpleSnackbar, SnackbarConfiguration } from '@celum/common-components';

@Directive({
  selector: '[droppable]',
  standalone: false
})
export class DroppableDirective {
  private static readonly OPACITY_06: string = '0.6';
  private static readonly OPACITY_1: string = '1';

  @Output() public readonly files: EventEmitter<File[]> = new EventEmitter();

  @HostBinding('style.opacity') protected opacity: string = DroppableDirective.OPACITY_06;

  private readonly snackbarConfiguration: SnackbarConfiguration;
  private eventTarget: EventTarget;

  constructor(
    private translateService: TranslateService,
    private store: Store<any>
  ) {
    this.snackbarConfiguration = SnackbarConfiguration.error(
      this.translateService.instant('UPLOAD.SNACKBAR.RESTRICTED.HEADLINE')
    )
      .withDescription(this.translateService.instant('UPLOAD.SNACKBAR.RESTRICTED.TEXT'))
      .withAutoVanish(false);
  }

  @HostListener('dragenter', ['$event'])
  public onDragEnter(event: any) {
    this.eventTarget = event.currentTarget;
    this.opacity = DroppableDirective.OPACITY_1;
    event.preventDefault();
  }

  @HostListener('dragover', ['$event'])
  public onDragOver(event: any) {
    this.opacity = DroppableDirective.OPACITY_1;
    event.preventDefault();
  }

  @HostListener('dragleave', ['$event'])
  public onDragLeave(event: any) {
    // check for browser compatibility not needed here since child elements have pointer-events none
    if (isEqual(event.currentTarget, this.eventTarget)) {
      this.opacity = DroppableDirective.OPACITY_06;
      event.preventDefault();
    }
  }

  @HostListener('drop', ['$event'])
  public onDrop(event: any) {
    this.opacity = DroppableDirective.OPACITY_06;
    event.preventDefault();

    const droppedItems = Object.values(event.dataTransfer.items) as DataTransferItem[];
    const files = droppedItems.filter(item => item.webkitGetAsEntry()?.isFile).map(item => item.getAsFile());

    if (files.length > 0) {
      this.files.emit(files);
    }

    if (droppedItems.length !== files.length) {
      this.store.dispatch(new ShowSnackbar('droppable-directive', SimpleSnackbar, this.snackbarConfiguration));
    }
  }

  @HostListener('dragend', ['$event'])
  public onDragEnd(event: any) {
    this.opacity = DroppableDirective.OPACITY_06;
    event.preventDefault();
  }
}
