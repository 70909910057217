import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, OnInit, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';

import { ReactiveComponent } from '@celum/ng2base';
import { CustomField } from '@celum/work/app/core/model/entities/custom-field/custom-field.model';
import { VirtualScrollTableService } from '@celum/work/app/shared/components/virtual-scroll-table/virtual-scroll-table.base';
import { VirtualScrollTableComponent } from '@celum/work/app/shared/components/virtual-scroll-table/virtual-scroll-table.component';

import { CustomFieldsTableRowComponent } from './custom-fields-table-row/custom-fields-table-row.component';
import { CustomFieldsVirtualScrollTableService } from './custom-fields-virtual-scroll-table.service';
import { CustomFieldsStore } from '../../fields-overview-tab.store';

@Component({
  selector: 'custom-fields-virtual-scroll-table',
  imports: [CommonModule, VirtualScrollTableComponent, CustomFieldsTableRowComponent],
  templateUrl: './custom-fields-virtual-scroll-table.component.html',
  styleUrls: ['./custom-fields-virtual-scroll-table.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  viewProviders: [
    {
      provide: VirtualScrollTableService,
      useFactory: (customFieldStore: CustomFieldsStore, store: Store<any>) =>
        new CustomFieldsVirtualScrollTableService(customFieldStore, store),
      deps: [CustomFieldsStore, Store<any>]
    }
  ]
})
export class CustomFieldsVirtualScrollTableComponent extends ReactiveComponent implements OnInit {
  @HostBinding('class.custom-fields-virtual-scroll-table') public hostCls = true;

  constructor(
    private customFieldsStore: CustomFieldsStore,
    private virtualScrollTableService: VirtualScrollTableService<CustomField>
  ) {
    super();
  }

  public ngOnInit(): void {
    // TODO: think about something more generic
    this.customFieldsStore.selectionCleared$
      .pipe(
        distinctUntilChanged(),
        filter(cleared => cleared),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(() => this.virtualScrollTableService.clearSelection());
  }

  public openEditFieldDialog(field: CustomField): void {
    this.customFieldsStore.openEditCustomFieldDialog$(field);
  }
}
