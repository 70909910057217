import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, take, takeUntil } from 'rxjs/operators';

import { EmptyPageConfig } from '@celum/common-components';
import { CelumSimpleList, ListSelectionHandler, SelectionBehavior } from '@celum/internal-components';
import { DomHelper, ReactiveComponent } from '@celum/ng2base';
import { Workroom } from '@celum/work/app/core/model/entities/workroom';
import { ContentItemRestrictedDialogOpener } from '@celum/work/app/pages/workroom/pages/files/services/content-item-restricted-dialog-opener';
import { selectCurrentWorkroom } from '@celum/work/app/pages/workroom/store/workroom-wrapper.selectors';
import { RobotScopeResolverService } from '@celum/work/app/robots/services/robot-scope-resolver.service';
import { selectAutomators } from '@celum/work/app/robots/store/robot.selectors';

import { Roles } from '../../../core/model';
import {
  ContentItem,
  ContentItemStatus,
  ContentItemTypes
} from '../../../core/model/entities/content-item/content-item.model';
import { File, FileType } from '../../../core/model/entities/file/file.model';
import { Folder, FolderType } from '../../../core/model/entities/folder/folder.model';
import { selectCurrentWorkroomHasTaskListsWithTaskCreationEnabled, Task } from '../../../core/model/entities/task';
import { UiViewContext } from '../../../core/ui-state/ui-state.model';
import { selectCurrentViewContext, selectIsAccountOwner } from '../../../core/ui-state/ui-state.selectors';
import { PermissionUtil, WindowResizeService } from '../../../shared/util';

@Component({
  selector: 'content-item-list',
  templateUrl: './content-item-list.component.html',
  styleUrls: ['./content-item-list.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: false
})
export class ContentItemListComponent extends ReactiveComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChild(CelumSimpleList, { static: true }) public celumSimpleList: CelumSimpleList;
  @HostBinding('class.content-item-list') public hostCls = true;

  @Input() public allowedContentItemTypesClickAction: ContentItemTypes[] = [];
  @Input() public task: Task;
  @Input() public withMagicButtonUpdates: boolean;
  @Input() public usedStoragePercentage: number;
  @Input() public used3DPercentage: number;
  @Input() public contentItems: (Folder | File)[];
  @Input() public selectedContentItems: ContentItem[];
  @Input() public isMultiSelection = true;
  @Input() public disableContextMenu: boolean;
  @Input() public hasMoreBottom: boolean;
  @Input() public loading: boolean;
  @Input() public emptyPageConfig: EmptyPageConfig;
  @Input() public showCardsToolbar: boolean;

  @Output() public readonly fetchNextBatch: EventEmitter<any> = new EventEmitter<any>();
  @Output() public readonly onContentItemSelected: EventEmitter<ContentItem[]> = new EventEmitter<ContentItem[]>();
  @Output() public readonly onBatchSizeChanged: EventEmitter<number> = new EventEmitter<number>();

  public isOwner$: Observable<boolean>;
  public isModerator$: Observable<boolean>;
  public canCreateTasksFromFiles$: Observable<boolean>;
  public currentViewContext$: Observable<UiViewContext>;
  public currentWorkroom$: Observable<Workroom>;
  public uiViewContext = UiViewContext;
  public listSelectionHandler: ListSelectionHandler<Folder | File> = new ListSelectionHandler<Folder | File>();

  public selectionContainsFoldersWithRobotsApplied$: Observable<boolean>;

  private readonly cardWrapperClass: string = 'content-item-card_list-wrapper';

  constructor(
    private store: Store<any>,
    private el: ElementRef,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private windowResizeService: WindowResizeService,
    private contentItemRestrictedDialogOpener: ContentItemRestrictedDialogOpener,
    private permissionUtil: PermissionUtil,
    private robotScopeResolver: RobotScopeResolverService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.canCreateTasksFromFiles$ = this.store.select(selectCurrentWorkroomHasTaskListsWithTaskCreationEnabled);
    this.isOwner$ = this.store.select(selectIsAccountOwner);
    this.isModerator$ = this.permissionUtil.hasRoleForCurrentWorkroom(Roles.MODERATOR);
    this.currentWorkroom$ = this.store.select(selectCurrentWorkroom);
    this.currentViewContext$ = this.store.select(selectCurrentViewContext);
    this.listSelectionHandler.setSelectionBehavior(
      this.isMultiSelection ? SelectionBehavior.MULTI_TOGGLE : SelectionBehavior.SINGLE
    );
    this.listSelectionHandler.setCompareFn((a, b) => a.id === b.id);
    this.listSelectionHandler.selectionChanged$.pipe(takeUntil(this.unsubscribe$)).subscribe(selection => {
      this.contentItemSelected(selection);
    });
  }

  public ngOnChanges({ selectedContentItems }: SimpleChanges): void {
    if (selectedContentItems?.previousValue?.length > 0 && selectedContentItems?.currentValue?.length === 0) {
      this.listSelectionHandler.clearSelection(true);
    }

    if (selectedContentItems?.currentValue?.length !== selectedContentItems?.previousValue?.length) {
      this.selectionContainsFoldersWithRobotsApplied$ = this.selectionContainsFoldersWithRobots();
    }
  }

  public ngAfterViewInit(): void {
    // Opening a detail url directly breaks the file search if we use the resize observer
    if (this.router.url.includes('assets/detail?')) {
      this.setupBatchChangeListener();
    } else {
      DomHelper.registerResizeObserver(this.el.nativeElement, this.unsubscribe$)
        .pipe(
          takeUntil(this.unsubscribe$),
          filter(resizeEvent => resizeEvent.contentRect.height > 0),
          take(1)
        )
        .subscribe(() => this.setupBatchChangeListener());
    }
  }

  public trackByFn(index, item: ContentItem) {
    return item.id || index;
  }

  public handleFileClicked(file: File, event: MouseEvent): void {
    if (file.status === ContentItemStatus.SOFT_DELETED) {
      this.contentItemRestrictedDialogOpener.showDialog(FileType.TYPE_KEY);
      event.stopPropagation();
      return;
    }

    if (this.allowedContentItemTypesClickAction.includes(ContentItemTypes.FILE)) {
      this.contentItemSelected([]);
      event.stopPropagation();

      this.router.navigate(['./detail'], {
        relativeTo: this.activatedRoute,
        queryParamsHandling: 'merge',
        queryParams: { assetId: file.id }
      });
    }
  }

  public handleFolderClicked(folder: Folder, event: MouseEvent): void {
    if (folder.status === ContentItemStatus.SOFT_DELETED) {
      this.contentItemRestrictedDialogOpener.showDialog(FolderType.TYPE_KEY);
      event.stopPropagation();
      return;
    }

    if (this.allowedContentItemTypesClickAction.includes(ContentItemTypes.FOLDER)) {
      this.contentItemSelected([]);
      event.stopPropagation();

      this.router.navigate(['.'], {
        relativeTo: this.activatedRoute,
        queryParams: { selectedFolder: folder.id }
      });
    }
  }

  public contentItemSelected(contentItems: ContentItem[]): void {
    this.onContentItemSelected.emit(contentItems);
  }

  public onFetchNextBatch(): void {
    if (this.hasMoreBottom) {
      this.fetchNextBatch.emit();
    }
  }

  public getCardWrapperClasses(): string {
    return this.cardWrapperClass + (this.showCardsToolbar ? ` ${this.cardWrapperClass}--has-toolbar` : '');
  }

  public selectionContainsFoldersWithRobots(): Observable<boolean> {
    const folders = this.selectedContentItems.filter(ci => ci.entityType.id === FolderType.TYPE_KEY);
    const value = this.store.select(selectAutomators).pipe(
      take(1),
      map(automators =>
        folders.some(
          folder => this.robotScopeResolver.filterRobotsBelongingToFolder(automators, folder as Folder).length > 0
        )
      )
    );
    return value;
  }

  private setupBatchChangeListener() {
    this.windowResizeService
      .batchSizeChanges({
        unsubscribe$: this.unsubscribe$,
        elementSize: 196 * 168,
        el: this.el
      })
      .subscribe(newBatchSize => this.onBatchSizeChanged.emit(newBatchSize));
  }
}
