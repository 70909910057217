import { ChangeDetectionStrategy, Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';

import { IconConfiguration } from '@celum/common-components';

import { Collection } from '../../../model/content-hub.model';

@Component({
  selector: 'collection-item',
  templateUrl: './collection-item.component.html',
  styleUrls: ['./collection-item.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: false
})
export class CollectionItemComponent {
  @HostBinding('class.collection-item') public hostCls = true;

  @Input() public collection: Collection;

  @HostBinding('class.collection-item--selected')
  @Input()
  public selected: boolean;

  public folderIcon = IconConfiguration.small('folder');
}
