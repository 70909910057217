<celum-dialog (onCloseAll)="closeDialog()">
    <celum-dialog-header>
      <ng-container *ngIf="!readonlyMode; else readonlyHeading">
        {{ 'TEAMSPACE_MANAGEMENT.FORMS.CREATE_FORM_DIALOG.HEADING' | translate }}
      </ng-container>
      <ng-template #readonlyHeading>
        {{ 'TEAMSPACE_MANAGEMENT.FORMS.CREATE_FORM_DIALOG.HEADING_READONLY' | translate }}
      </ng-template>
    </celum-dialog-header>

    <celum-dialog-content>
      <div class="custom-form-dialog_content">
        <div class="custom-form-dialog_content_input-container">
          <div
              class="custom-form-dialog_content_name-container form_container"
              [class.custom-form-dialog_content_name-container--readonly]="readonlyMode"
              [formGroup]="customFormGroup">
            <span class="custom-form-dialog-label custom-form-dialog-name_label input-label">
              {{ 'TEAMSPACE_MANAGEMENT.FORMS.CREATE_FORM_DIALOG.NAME_INPUT_LABEL' | translate }}
            </span>
            <mat-form-field
                celum-form-field
                class="custom-form-dialog-name_control custom-error-form"
                [class.custom-form-dialog-name_control--readonly]="readonlyMode"
                [reserveHintSpace]="true">
              <input
                  [formControl]="controls.name"
                  [placeholder]="readonlyMode ? null : 'TEAMSPACE_MANAGEMENT.FORMS.CREATE_FORM_DIALOG.NAME_INPUT_PLACEHOLDER' | translate"
                  [autoFocus]="true"
                  maxlength="50"
                  matInput
                  data-cy="custom-form-dialog-name-input"/>

              <mat-hint
                  *ngIf="!readonlyMode && controls.name.value?.length >= NAME_CONTROL_LIMIT - 10"
                  align="end"
                  data-cy="custom-form-dialog-name-input-remaining-hint">
                  {{ NAME_CONTROL_LIMIT - (controls.name.value?.length ?? NAME_CONTROL_LIMIT) }}
                  {{ 'TEAMSPACE_MANAGEMENT.FORMS.CREATE_FORM_DIALOG.NAME_INPUT_HINT' | translate }}
              </mat-hint>

              <mat-error
                  *ngIf="controls.name.hasError('required')"
                  data-cy="custom-form-dialog-name-input-required-error">
                  {{ 'TEAMSPACE_MANAGEMENT.FORMS.CREATE_FORM_DIALOG.ERRORS.REQUIRED' | translate }}
              </mat-error>
              <mat-error *ngIf="controls.name.hasError(validationEnum.NAME_NOT_UNIQUE)">
                {{ 'TEAMSPACE_MANAGEMENT.FORMS.CREATE_FORM_DIALOG.ERRORS.NOT_UNIQUE' | translate }}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="custom-form-dialog_actions">
            <div *ngIf='!readonlyMode' class="custom-form-dialog_button-wrapper">
              <span class="custom-form-dialog-label custom-form-dialog-fields_label">
                {{ 'TEAMSPACE_MANAGEMENT.FORMS.CREATE_FORM_DIALOG.FIELD_BUTTON.LABEL' | translate }}
              </span>
              <ng-container *ngIf="controls.fields.value.length === fieldLimit">
                <div class="custom-form-dialog_add-field">
                  <celum-icon
                      class="custom-form-dialog_add-field-disabled-icon"
                      [matTooltip]="readonlyMode ? null : 'TEAMSPACE_MANAGEMENT.FORMS.CREATE_FORM_DIALOG.FIELD_BUTTON.DISABLED_TOOLTIP' | translate: { fieldLimit }"
                      [configuration]="fieldAddDisabledIcon"
                      [clickable]="false"
                      data-cy="custom-form-dialog-add-field-button-disabled">
                  </celum-icon>
                </div>
              </ng-container>

              <ng-container *ngIf="controls.fields.controls.length < fieldLimit && !readonlyMode">
                <div
                    class="custom-form-dialog_add-field"
                    matRipple
                    cdkOverlayOrigin
                    #originOverlay="cdkOverlayOrigin"
                    (click)="openSearchAndSelectOverlay()">
                  <celum-icon
                      class="custom-form-dialog_add-field-icon"
                      [matTooltip]="'TEAMSPACE_MANAGEMENT.FORMS.CREATE_FORM_DIALOG.FIELD_BUTTON.TOOLTIP' | translate"
                      [configuration]="fieldAddIcon"
                      [clickable]="false"
                      data-cy="custom-form-dialog-add-field-button">
                  </celum-icon>

                  <ng-template cdkConnectedOverlay
                      [cdkConnectedOverlayOffsetY]="1"
                      cdkConnectedOverlayPanelClass="mat-elevation-z3"
                      [cdkConnectedOverlayBackdropClass]="'custom-form-dialog_add-field-backdrop'"
                      [cdkConnectedOverlayHasBackdrop]="true"
                      [cdkConnectedOverlayOrigin]="originOverlay"
                      [cdkConnectedOverlayOpen]="isSearchAndSelectVisible"
                      [cdkConnectedOverlayPositions]="[{ originX: 'start', originY: 'bottom', overlayX: 'start', overlayY: 'top' }]"
                      (backdropClick)="closeSearchAndSelectOverlay()">
                    <search-and-select-custom-fields
                        (elementSelected)="customFieldSelected($event)"
                        [selectedFieldIds]="selectedFieldIds"
                        data-cy="create-custom-form-dialog-field-dropdown">
                    </search-and-select-custom-fields>
                  </ng-template>
                </div>
              </ng-container>
            </div>

            <div *ngIf="controls.fields.value.length > 0;" class="custom-form-dialog_expansion-options">
              <a class="custom-form-dialog_expansion-button" data-cy="expand-custom-fields" (click)="setCustomFieldsExpansionState(true)">
                {{'TEAMSPACE_MANAGEMENT.FIELDS.CUSTOM_FIELD_CARD.EXPANSION.EXPAND_ALL' | translate }}
              </a>
              <mat-divider  class="custom-form-dialog_expansion-divider" [vertical]="true"></mat-divider>
              <a class="custom-form-dialog_expansion-button" data-cy="collapse-custom-fields" (click)="setCustomFieldsExpansionState(false)">
                {{'TEAMSPACE_MANAGEMENT.FIELDS.CUSTOM_FIELD_CARD.EXPANSION.COLLAPSE_ALL' | translate }}
              </a>
            </div>
          </div>
        </div>

        <div
            *ngIf="controls.fields.value.length > 0; else emptyPage"
            cdkDropList
            (cdkDropListDropped)="fieldDropped($event)"
            class="custom-form-dialog_field-card-container">
          <custom-field-card
              *ngFor="let field of customFormGroup.controls.fields.controls; let i = index; trackBy: trackByFn"
              class="custom-form-dialog_field-card"
              [formControl]="field"
              [isReadOnly]="true"
              [isRemovable]="!readonlyMode"
              [attr.data-cy]="'custom-field-card-' + i"
              (removeClicked)="removeSelectedField(i)"
              cdkDrag
              cdkDragBoundary=".custom-form-dialog_field-card-container"
              [cdkDragDisabled]="readonlyMode">
          </custom-field-card>
        </div>

        <ng-template #emptyPage>
          <empty-page class="custom-form-dialog_placeholder" [config]="emptyPageConfig"></empty-page>
        </ng-template>
      </div>
    </celum-dialog-content>

    <celum-dialog-footer
        [confirmBtnText]="readonlyMode ? 'COMMON.CLOSE' : 'COMMON.CREATE'"
        cancelBtnText="COMMON.CANCEL"
        [buttons]="readonlyMode ? ['ok'] : ['ok', 'cancel']"
        [valid]="!controls.name.invalid && controls.fields.value.length > 0"
        [loading]="loading"
        (onCancel)="closeDialog()"
        (onConfirm)="readonlyMode ? closeDialog() : onSubmit()">
    </celum-dialog-footer>
  </celum-dialog>
