import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { Subject, timer } from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';

import { IconConfiguration } from '@celum/common-components';
import { ReactiveComponent } from '@celum/ng2base';
import { SnackbarGroupEvent } from '@celum/work/app/shared/components/snackbar-group/model/snackbar-group-events.model';
import { ApplicationEventBus } from '@celum/work/app/shared/util/application-event-bus.service';

export interface SnackbarGroupConfig {
  title: string;
  titleIcon: IconConfiguration;
  titleArgs?: object;
  progress?: number;
  failed?: number;
}

@Component({
  selector: 'snackbar-group',
  templateUrl: './snackbar-group.component.html',
  styleUrls: ['./snackbar-group.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: false
})
export class SnackbarGroupComponent extends ReactiveComponent implements OnInit, OnChanges {
  @Input() public config: SnackbarGroupConfig;
  @Input() public items: object[];
  @Input() public itemTemplate: TemplateRef<any>;
  @Input() public snackbarSize: 'small' | 'large' = 'small';

  @Output() public readonly closeAll: EventEmitter<void> = new EventEmitter<void>();
  @Output() public readonly dismiss: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild(MatExpansionPanel) public pannel?: MatExpansionPanel;

  public panelHeaderHeight = '4.4rem';
  public cancelIcon: IconConfiguration = IconConfiguration.medium('cancel-l').withColor('#ffffff');
  public arrowIcon: IconConfiguration = IconConfiguration.medium('arrow-down');
  public dismissSubj: Subject<void> = new Subject();

  private readonly dismissTime: number = 7000;

  constructor(private eventBus: ApplicationEventBus) {
    super();
    this.listenForSnackBarEvent();
  }

  public ngOnInit(): void {
    this.initAutoDismiss();
  }

  public ngOnChanges({ config, items }: SimpleChanges): void {
    if (items || config) {
      this.dismissSubj.next();
    }
  }

  public trackByFn(idx: number): number {
    return idx;
  }

  public initAutoDismiss(): void {
    this.dismissSubj
      .pipe(
        filter(() => !this.isProgressSnackbar() || this.isProgressSnackbarFinished()),
        switchMap(() => timer(this.dismissTime)),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(() => this.dismiss.emit());
  }

  public isProgressSnackbar(): boolean {
    return this.config.progress !== undefined;
  }

  private isProgressSnackbarFinished(): boolean {
    return this.config.progress >= 100 && !this.config.failed;
  }

  private listenForSnackBarEvent() {
    this.eventBus
      .observeEvents(this.unsubscribe$, SnackbarGroupEvent.SNACKBAR_GROUP_MINIMIZE)
      .subscribe(() => this.pannel?.close());
  }
}
