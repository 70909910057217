import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { Store } from '@ngrx/store';
import { forkJoin, Observable, of } from 'rxjs';
import { switchMap, take, tap } from 'rxjs/operators';

import { AVATAR_SIZE, ColorConstants, IconConfiguration } from '@celum/common-components';
import { Person } from '@celum/work/app/core/model/entities/person';
import { AvatarUtil } from '@celum/work/app/shared/util';

import { WorkroomAvatarConfiguration } from '../workroom-avatar/workroom-avatar-configuration';

@Component({
  selector: 'workroom-avatar-list',
  templateUrl: './workroom-avatar-list.component.html',
  styleUrls: ['./workroom-avatar-list.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: false
})
export class WorkroomAvatarList {
  @HostBinding('class.workroom-avatar-list') public readonly hostCls: boolean = true;

  @Input() public people: Person[] = [];
  @Input() public deletable = true;
  @Output() public readonly personDeleted = new EventEmitter<Person>();

  private deleteIconConfig = IconConfiguration.medium('cancel-m').withColor('#ffffff');

  public constructor(
    private store: Store<any>,
    private avatarUtil: AvatarUtil
  ) {}

  public get peopleAvatars$(): Observable<WorkroomAvatarConfiguration[]> {
    return forkJoin(this.people.map(person => this.createAvatarConfiguration(person)));
  }

  @Input() public avatarDecorator: (avatar: WorkroomAvatarConfiguration) => Observable<WorkroomAvatarConfiguration> =
    avatar => of(avatar);

  public avatarClicked(avatar: WorkroomAvatarConfiguration) {
    if (this.deletable) {
      this.personDeleted.emit(avatar.person);
    }
  }

  public trackByFn(_, item: WorkroomAvatarConfiguration) {
    return item?.person.id;
  }

  private createAvatarConfiguration(person: Person): Observable<WorkroomAvatarConfiguration> {
    const avatarConfigArgs = { person, size: AVATAR_SIZE.m, store: this.store, showWithName: true };

    return this.avatarUtil.getAvatarConfigWithImageForCurrentTeamspace(avatarConfigArgs).pipe(
      switchMap(avatar => this.avatarDecorator(avatar)),
      tap(config => {
        if (this.deletable) {
          config.addOverlayIcon(this.deleteIconConfig, ColorConstants.SYSTEM_BLACK_56);
        }
      }),
      take(1)
    );
  }
}
