import { ConnectionPositionPair, Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewContainerRef,
  ViewEncapsulation
} from '@angular/core';
import { isEqual } from 'lodash';
import { EMPTY, forkJoin, Observable, of } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';

import { AVATAR_SIZE, CelumAvatar, ColorConstants, IconConfiguration } from '@celum/common-components';
import { ICON_SIZE } from '@celum/core';
import { ReactiveComponent } from '@celum/ng2base';
import { Person } from '@celum/work/app/core/model/entities/person';
import { InteractiveAvatarsEvents } from '@celum/work/app/shared/components/interactive-avatar/interactive-avatars.events';
import { AvatarDecoratorFn, AvatarUtil, SearchAndSelectService } from '@celum/work/app/shared/util';
import { ApplicationEventBus } from '@celum/work/app/shared/util/application-event-bus.service';

import { SearchAndSelectContributors } from '../search-and-select/search-and-select-contributors/search-and-select-contributors.component';
import {
  WorkroomAvatarConfigBuilder,
  WorkroomAvatarConfiguration
} from '../workroom-avatar/workroom-avatar-configuration';

@Component({
  selector: 'interactive-avatars',
  templateUrl: './interactive-avatars.component.html',
  styleUrls: ['./interactive-avatars.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: false
})
export class InteractiveAvatars extends ReactiveComponent implements OnInit, OnChanges, OnDestroy {
  @HostBinding('class') public componentCls = 'interactive-avatars';

  @Input() public peopleToExcludeInAvatarList: Person[] = [];
  @Input() public allPeople: Person[];
  @Input() public selectedPeople: Person[] = [];
  @Input() public maxNumberOfAvatars = 3;
  @Input() public darkOverflowBackground = false;

  @Input() public startingIcon: IconConfiguration;
  @Input() public iconSize = ICON_SIZE.m;
  @Input() public avatarSize = AVATAR_SIZE.m;

  @Input() public noPermissionColor: string = ColorConstants.BLUE_GRAY_200;
  @Input() public hasAddPermissions = true;
  @Input() public hasRemovePermissions = true;
  @Input() public showUnableToAddListOwner = true;
  @Input() public areTaskListOwners: boolean;
  @Input() public addContributorTooltip: string;
  @Input() public noPermissionTooltip: string;
  @Input() public addButtonRippleDisabled = true;
  @Input() public searchAndSelectClass = '';

  @Output() public readonly avatarRemoved = new EventEmitter<Person>();
  @Output() public readonly personSelected = new EventEmitter<Person>();
  @Output() public readonly personDeselected = new EventEmitter<Person>();
  @Output() public readonly searchValueChanged = new EventEmitter<string>();
  @Output() public readonly searchAndSelectVisibilityChanged = new EventEmitter<boolean>();

  public isSearchAndSelectVisible: boolean;

  public noAddPersonPermission: IconConfiguration;

  public peopleAvatars$: Observable<WorkroomAvatarConfiguration[]> = EMPTY;
  public overflow: number;

  public overflowAvatar: WorkroomAvatarConfiguration;

  private deleteIconConfig = new IconConfiguration('cancel-m').withIconSize(this.iconSize).withColor('#ffffff');
  private searchAndSelectOverlayRef: OverlayRef;
  private searchAndSelectComponentRef: ComponentRef<SearchAndSelectContributors>;

  constructor(
    public cdRef: ChangeDetectorRef,
    private overlayService: Overlay,
    private viewContainerRef: ViewContainerRef,
    private eventbus: ApplicationEventBus,
    private avatarUtil: AvatarUtil
  ) {
    super();
    this.listernForCloseEvents();
  }

  @Input() public avatarDecorator: AvatarDecoratorFn = avatar => of(avatar);

  public getSelectedPeople(): Person[] {
    const peopleIdsToExcludeInAvatarList = this.peopleToExcludeInAvatarList.map(({ id }) => id);
    return this.selectedPeople.filter(({ id }) => !peopleIdsToExcludeInAvatarList.includes(id));
  }

  public ngOnInit(): void {
    this.noAddPersonPermission = new IconConfiguration('no-permission-assign-person')
      .withIconSize(this.iconSize)
      .withColor(this.noPermissionColor)
      .withIconSize(30)
      .withHoverColor(this.noPermissionColor);
  }

  public ngOnChanges({ selectedPeople, maxNumberOfAvatars, hasRemovePermissions }: SimpleChanges) {
    if (
      (selectedPeople?.currentValue && !isEqual(selectedPeople?.currentValue, selectedPeople?.previousValue)) ||
      maxNumberOfAvatars ||
      hasRemovePermissions
    ) {
      this.overflow = Math.max(
        this.selectedPeople.length - this.peopleToExcludeInAvatarList.length - this.maxNumberOfAvatars,
        0
      );
      this.overflowAvatar = this.buildOverflowAvatar();
      this.peopleAvatars$ = this.updatePeopleAvatars();
    }

    if (hasRemovePermissions?.previousValue && hasRemovePermissions?.currentValue === false) {
      this.closeSearchAndSelectOverlay();
    }
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    SearchAndSelectService.closeOverlayIfOpen(this.searchAndSelectOverlayRef, this.searchAndSelectComponentRef);
  }

  public openSearchAndSelectOverlay(): void {
    if (!this.hasAddPermissions) {
      return;
    }

    this.searchValueChanged.emit('');
    this.isSearchAndSelectVisible = true;
    this.searchAndSelectVisibilityChanged.emit(true);
  }

  public closeSearchAndSelectOverlay(): void {
    this.isSearchAndSelectVisible = false;
    this.searchAndSelectVisibilityChanged.emit(false);
    this.cdRef.detectChanges();
  }

  public onSearchValueChanged(searchQuery: string): void {
    this.searchValueChanged.emit(searchQuery);
  }

  public onAvatarClicked(avatar: WorkroomAvatarConfiguration): void {
    if (this.hasRemovePermissions) {
      this.avatarRemoved.emit(avatar.person);
    }
  }

  public openPeopleListOverlay(
    overlayTrigger: CelumAvatar,
    overlayTemplate: TemplateRef<any>,
    mouseEvent: MouseEvent
  ): void {
    mouseEvent.stopPropagation();
    const positions = [
      new ConnectionPositionPair({ originX: 'start', originY: 'bottom' }, { overlayX: 'start', overlayY: 'top' }),
      new ConnectionPositionPair({ originX: 'start', originY: 'top' }, { overlayX: 'start', overlayY: 'bottom' })
    ];
    const strategy = this.overlayService
      .position()
      .flexibleConnectedTo(overlayTrigger.getElement())
      .withPositions(positions);
    const overlayRef = this.overlayService.create(
      new OverlayConfig({
        hasBackdrop: true,
        backdropClass: 'interactive-avatars_backdrop',
        positionStrategy: strategy
      })
    );
    overlayRef.attach(new TemplatePortal(overlayTemplate, this.viewContainerRef));
    overlayRef
      .backdropClick()
      .pipe(take(1))
      .subscribe(() => overlayRef.dispose());
  }

  public trackByFn(index, item: WorkroomAvatarConfiguration) {
    if (!item) {
      return index;
    }
    return item.person.id;
  }

  public updatePeopleAvatars(): Observable<WorkroomAvatarConfiguration[]> {
    const filteredPeople = this.selectedPeople.filter(
      ({ id }) => !this.peopleToExcludeInAvatarList.map(person => person.id).includes(id)
    );
    return forkJoin(filteredPeople.map(assignee => this.createAvatarConfiguration(assignee))).pipe(
      map(avatars => avatars.sort((a, b) => WorkroomAvatarConfiguration.compare(a, b))),
      map(sortedAvatars => sortedAvatars.slice(0, sortedAvatars.length - Math.max(this.overflow, 0)))
    );
  }

  private listernForCloseEvents() {
    this.eventbus
      .observeEvents(this.unsubscribe$, InteractiveAvatarsEvents.SHOULD_CLOSE_SEARCH)
      .subscribe(() => this.closeSearchAndSelectOverlay());
  }

  private buildOverflowAvatar(): WorkroomAvatarConfiguration {
    return new WorkroomAvatarConfigBuilder()
      .withInteractive(true)
      .withSize(this.avatarSize)
      .withTitleLength(4)
      .withTitle('+' + this.overflow)
      .withBackgroundColor(ColorConstants.BLUE_GRAY_400)
      .withClass('--dark')
      .build();
  }

  private createAvatarConfiguration(person: Person): Observable<WorkroomAvatarConfiguration> {
    let config$ = this.avatarUtil.getAvatarConfigWithImageForCurrentTeamspace({ person, size: this.avatarSize });

    if (this.hasRemovePermissions) {
      config$ = config$.pipe(
        tap(config => {
          config.addOverlayIcon(this.deleteIconConfig, ColorConstants.SYSTEM_BLACK_56);
          config.interactive = true;
        })
      );
    }

    return config$.pipe(
      switchMap(avatar => this.avatarDecorator(avatar)),
      take(1)
    );
  }
}
