import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ColorConstants, IconConfiguration } from '@celum/common-components';
import { CelumDialogOpener } from '@celum/internal-components';
import { Permission } from '@celum/work/app/core/api/permission';
import { Teamspace } from '@celum/work/app/core/model/entities/teamspace';
import { Template } from '@celum/work/app/core/model/entities/template/template.model';
import { selectTemplateListByCategoryId } from '@celum/work/app/core/model/entities/template/template.selectors';
import { TemplateCategory } from '@celum/work/app/core/model/entities/template-category/template-category.model';
import {
  selectCategoryById,
  selectTeamspaceByCategoryId
} from '@celum/work/app/core/model/entities/template-category/template-category.selectors';
import { FeatureType } from '@celum/work/app/core/model/entities/workroom';
import {
  ConfirmationDialog,
  ConfirmationDialogConfiguration
} from '@celum/work/app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { FeatureService } from '@celum/work/app/shared/components/features/feature.service';
import { FullscreenDialogComponent } from '@celum/work/app/shared/components/fullscreen-dialog/fullscreen-dialog.component';
import { WorkroomWizardData } from '@celum/work/app/workroom-wizard/components/workroom-wizard.component';

import { CreateWorkroomFromTemplateStrategy } from '../../services/create-workroom-strategy.service';
import { SaveEditTemplateStrategy } from '../../services/save-edit-template-strategy.service';
import * as templateChooserActions from '../../store/workroom-creator.actions';
import {
  CopyTemplateDialogComponent,
  CopyTemplateDialogConfiguration
} from '../template-maintenance/copy-template-dialog/copy-template-dialog.component';
import {
  MoveTemplateDialogComponent,
  MoveTemplateDialogConfiguration
} from '../template-maintenance/move-template-dialog/move-template-dialog.component';

@Component({
  selector: 'template-chooser-template-category',
  templateUrl: './template-chooser-template-category.component.html',
  styleUrls: ['./template-chooser-template-category.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: false
})
export class TemplateChooserTemplateCategoryComponent implements OnInit {
  @Input() public categoryId: number;
  @Input() public allTemplatesCategory: boolean;
  public templates$: Observable<Template[]>;
  public category$: Observable<TemplateCategory>;
  public teamspace$: Observable<Teamspace>;
  public iconInfoConfig = IconConfiguration.medium('info-s').withColor(ColorConstants.PRIMARY);

  public readonly permissions = {
    DELETE: Permission.TEMPLATE_DELETE,
    UPDATE: Permission.TEMPLATE_UPDATE,
    CREATE_WORKROOM: Permission.WORKROOM_CREATE
  };

  constructor(
    private store: Store<any>,
    private dialogOpener: CelumDialogOpener,
    private translateService: TranslateService,
    private featureService: FeatureService,
    private router: Router
  ) {}

  public get oneMoreLicenseAvailable(): Observable<boolean> {
    return this.store.select(selectTeamspaceByCategoryId(this.categoryId)).pipe(
      map(teamspace => {
        if (teamspace) {
          return teamspace.workroomsUsed === teamspace.workroomsLimit - 1;
        }
        return false;
      })
    );
  }

  public ngOnInit(): void {
    this.templates$ = this.store.select(selectTemplateListByCategoryId, {
      categoryId: this.categoryId,
      translateService: this.translateService
    });
    this.category$ = this.store.select(selectCategoryById(this.categoryId));
    this.teamspace$ = this.store.select(selectTeamspaceByCategoryId(this.categoryId));
  }

  public createWorkroomFromTemplate(template: Template) {
    const data: WorkroomWizardData = {
      title: 'WORKROOM_WIZARD.DIALOG.WORKROOM.HEADER',
      template: null,
      saveStrategyType: CreateWorkroomFromTemplateStrategy,
      type: {
        targetEntity: 'WORKROOM',
        editMode: false
      }
    };

    this.store.dispatch(
      templateChooserActions.WorkroomCreatorOpenDialog({
        templateId: template.id,
        dialogConfig: FullscreenDialogComponent.dialogConfig(data)
      })
    );
  }

  public trackByFn(index: number, template: Template) {
    return template ? template.id : index;
  }

  public triggerCopyTemplateDialog(template: Template) {
    this.dialogOpener.showDialog(
      'template-action-copy',
      CopyTemplateDialogComponent,
      new CopyTemplateDialogConfiguration(template, this.linkToCategory.bind(this))
    );
  }

  public triggerEditTemplateDialog(template: Template) {
    const data: WorkroomWizardData = {
      title: 'TEMPLATE_CHOOSER.ACTION_DIALOG.EDIT_DIALOG.HEADLINE',
      template: null,
      saveStrategyType: SaveEditTemplateStrategy,
      type: {
        targetEntity: 'TEMPLATE',
        editMode: true
      }
    };

    this.store.dispatch(
      templateChooserActions.WorkroomCreatorOpenDialog({
        templateId: template.id,
        dialogConfig: FullscreenDialogComponent.dialogConfig(data)
      })
    );
  }

  public triggerMoveTemplateDialog(template: Template) {
    this.dialogOpener.showDialog(
      'template-action-move',
      MoveTemplateDialogComponent,
      new MoveTemplateDialogConfiguration(template, this.linkToCategory.bind(this))
    );
  }

  public triggerDeleteTemplateDialog({ id }: Template) {
    const dialogConfig = new ConfirmationDialogConfiguration(
      this.translateService.instant('TEMPLATE_CHOOSER.ACTION_DIALOG.DELETE_DIALOG.HEADLINE'),
      this.translateService.instant('TEMPLATE_CHOOSER.ACTION_DIALOG.DELETE_DIALOG.QUESTION'),
      ColorConstants.WARNING,
      this.translateService.instant('TEMPLATE_CHOOSER.ACTION_DIALOG.DELETE_DIALOG.CONFIRM')
    );

    this.dialogOpener.showDialog('delete-template', ConfirmationDialog, dialogConfig).then(isConfirmed => {
      if (isConfirmed) {
        this.store.dispatch(templateChooserActions.WorkroomCreatorTemplateDelete({ id }));
      }
    });
  }

  public setCategoryAsActive({ id }: Partial<TemplateCategory>) {
    if (this.categoryId === id) {
      const isPersonal = String(id) === 'personal';
      this.store.dispatch(
        templateChooserActions.WorkroomCreatorRefreshTemplates({
          categoryIds: isPersonal ? [] : [id],
          includePersonal: isPersonal
        })
      );
    } else {
      this.router.navigate([], { queryParams: { templateCategory: id }, queryParamsHandling: 'merge' });
    }
  }

  public linkToCategory(selectedCategory: any) {
    this.setCategoryAsActive({ id: selectedCategory });
  }

  public hasFeature(template: Template, feature: FeatureType): boolean {
    return this.featureService.hasFeatureInWorkroomConfig(template.workroomConfiguration, feature);
  }
}
